import React, { Component } from 'react';
import "video-react/dist/video-react.css";
import { Player } from 'video-react';


class HomeSlider extends Component {
  render() {
          return (
              {/*
                  <Player
                      playsInline
                      controls="false"
                      poster="./imgs/video_poster.jpg"
                      src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
                  />*/

              }
          );
      }
}


export default HomeSlider;