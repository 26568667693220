import React from 'react';
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Redirect} from 'react-router';

import {resetIt} from "../../helpers/functions";
import {useStoreActions} from "easy-peasy";
const InnerPageSection = (props) => {
    const {addOffresAero,addOffresAuto,addOffresNaval,addOffresTextile,addOffresAgro,addOffshoring,addIndustriesDiverses} = useStoreActions(actions => actions);
    const {offres, secteur_children} = props
    const handleClick = e => {

        offres.map((offre) => {
            offre.offre_allowed = false;
            offre.items.map((item) => {
                item.item_amount = 0;
            });
            if(offre.slug === "fdii_industries"){
                offre.items = [];
            }
        });
        // initialisation  des tables offres
        if(secteur_children === 2){
            addOffresAero(offres);
        }
        if(secteur_children === 3){
            addOffresAuto(offres);
        }
        if(secteur_children === 4){
            addOffresNaval(offres);
        }
        if(secteur_children === 5){
            addOffresTextile(offres);
        }
        if(secteur_children === 6){
            addOffresAgro(offres);
        }
        if(secteur_children === 7){
            addOffshoring(offres);
        }
        if(secteur_children === 8){
            addIndustriesDiverses(offres);
        }
        return  <Redirect from="/result" to="/" />
    }
    return (
        <section  className={props.sectionClass}>

            <div  className={props.sectionContainer}>
                <Row>
                    <Col>
                        <div className="page_header">
                            <div className="page_section_title">
                                <h3>
                                    {props.pageTitle}
                                </h3>
                            </div>
                        </div>
                        <div className="bottomTitle">
                            <h3>
                                <Link onClick={handleClick} to="/">{props.bottomTitle}</Link>

                            </h3>

                        </div>

                    </Col>
                </Row>
                <Row>
                    {props.children}
                </Row>

            </div>
        </section>
    );
}
export default InnerPageSection;
