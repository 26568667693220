import React from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {useTranslation} from "react-i18next";
import i18n from '../helpers/i18n';
export default class LangDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.state = {
      dropdownOpen: false,
      value : "Français",
        lang:'fr'
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  select(event) {

    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      value: event.target.innerText
    });

  }
  render(){

      const changeLanguage = async (lng) => {
          await i18n.changeLanguage(lng);
      };
    return (
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>
            {this.state.value}
        </DropdownToggle>
        <DropdownMenu>
                <DropdownItem value="en" onClick={(event) => {this.select(event);changeLanguage('en')}}>English</DropdownItem>
                <DropdownItem value="ar"  onClick={(event) => {this.select(event);changeLanguage('ar')}}>العربية</DropdownItem>
                <DropdownItem value="fr"  onClick={(event) => {this.select(event);changeLanguage('fr')}}>Français</DropdownItem>
                </DropdownMenu>
      </ButtonDropdown>
    );
  }
}