import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import i18n from '../../helpers/i18n';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Row, Col } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from 'formik';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from 'easy-peasy';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function ForgotPasswordForm() {
  const [loader, setLoader] = useState(false);

  const { isLogged } = useStoreState(state => state.user);
  const { user } = useStoreState(state => state.loggedInUser);
  const { setLogged, setLoggedInUser } = useStoreActions(actions => actions);
  const { setSnackbarState } = useStoreActions(actions => actions);
  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values, { setSubmitting }) => {
        setLoader(true);
        setTimeout(() => {
          const formData = new FormData();
          formData.set('email', values.email);

          axios({
            method: 'post',
            url:
              process.env.REACT_APP_BACKEND_HOSTING_URL +
              'password_changed_front',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then(function(response) {
                const returnedData = response.data;

                if(returnedData.result === true){
                    setSnackbarState({state:true,message:i18n.t('login.password_changed')});
                    window.location.reload();
                }
            })
            .catch(function(error) {
              console.log(error);
              setLoader(false);
              // setSnackbarState({state:true,message:i18n.t('login.passwordchanged')})
            });
          setSubmitting(false);
        }, 500);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(i18n.t('login.valid_email'))
          .required(i18n.t('login.email_required'))
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;
        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label={i18n.t('login.email')}
                  name='email'
                  autoComplete='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && 'error'}
                />
                {errors.email && touched.email && (
                  <div className='input-feedback'>{errors.email}</div>
                )}
              </Col>
            </Row>
            <div className='submit_btn'>
              <Button
                fullWidth
                disabled={loader}
                type='submit'
                variant='contained'
                color='primary'
              >
                {loader === false && i18n.t('login.reset')}
              </Button>
              {loader && (
                <div className='progress_loader'>
                  <CircularProgress size={24} />
                </div>
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
