import React, {useEffect} from 'react';
import {Col, Container, Table} from "reactstrap";

//Anapec
export default function AnapecForm(props) {

    const {sousSecteur,formationAnapec} = props
    var anapecSecteur;
    if(parseInt(sousSecteur) === 2){
         anapecSecteur = formationAnapec.aeronautique;
    }
    if(parseInt(sousSecteur) === 3){
        anapecSecteur = formationAnapec.automobile;
    }
    useEffect(() => {


    });
    return (
        <>
            <Col md={12}>
                <h4 className="left_align_it">
                    L'offre de la formation ANAPEC
                </h4>

            </Col>
            <Col md={12}>
                    <Table  className="table_result anapec_chart" striped>
                        <thead>
                        <tr>
                            {anapecSecteur.tableHead.map((th_value,index) => (
                                <th key={index}>{th_value.title}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {anapecSecteur.baremes.map((td_value,index) => (
                            <tr key={index}>
                                <th>{td_value.niveau}</th>
                                <td>{td_value.embauche}</td>
                                <td>{td_value.continue}</td>
                            </tr>
                        ))}


                        </tbody>

                        <tfoot>
                        <tr >
                            <td colSpan={3}>
                                <p className="footer_bottom">
                                    *Une personne a droit à un seul crédit formation ouvert au moment du dépôt de son premier
                                    dossier de formation. En cas de changement d'entreprise, le bénéficiaire a droit au répliquât
                                    du crédit formation initialement ouvert.
                                </p>
                            </td>

                        </tr>
                        </tfoot>
                    </Table>
            </Col>

        </>
    );
}
