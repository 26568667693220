import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useStoreActions} from "easy-peasy";

export default function ChildDropDown(props) {

    const {addValue} = useStoreActions(actions => actions);
    const [optionValue, setOptionValue] = useState(props.defaultValue);
    const useStyles = makeStyles(theme => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,

        },
    }));

    const handleChange =  (event) => {
        addValue({name: event.target.name, value: event.target.value});
        setOptionValue(event.target.value);

       props.onSelectSousSecteur(event.target.value);

    };
    const{title} = props
    const{selector} = props
    const {childSelect} = props
    return (
        <>

            <FormControl className={useStyles.formControl}>
                <InputLabel htmlFor={selector}>{title}</InputLabel>
                <Select  onChange={handleChange}
                         value={optionValue}
                         disabled={childSelect}
                         displayEmpty
                         inputProps={{
                             name: selector,
                             id: selector,
                         }}>
                    {props.children}
                </Select>
            </FormControl>
        </>
    );
}
