import React, {useState,useEffect} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../../helpers/i18n";
import {useStoreActions, useStoreState} from "easy-peasy";
import {FormGroup,Row,Button} from "reactstrap";
import {Link} from "react-router-dom";
import PageSection from "../../../components/PageSection";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {add_estimation, add_invister} from '../../../../helpers/functions';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import axios from "axios";

export default function ProfilForm(props) {
    const {setSnackbarState} = useStoreActions(actions => actions);
    const {addValue} = useStoreActions(actions => actions);
    const {user} = props

    const [nom,setNom] = useState(user.nom || '');
    const [prenom,setPrenom] = useState(user.prenom || '');
    const [email,setEmail] = useState(user.email || '');
    const [adresse,setAdresse] = useState(user.adresse || '');
    const [ville,setVille] = useState(user.ville || '');
    const [pays,setPays] = useState(user.pays || '');
    const [disabled,setDisabled] = useState(true);
    const [loader,setLoading] = useState(false);
    useEffect(()=>{
        console.log('current user');
        if(user.nom !== '' ){
            console.log(user);
        }

    },[]);
    const handleChange = (e) => {

        console.log(e.target.name);
        switch (e.target.name) {
            case 'nom':
                setNom(e.target.value);
                break;
            case 'prenom':
                setPrenom(e.target.value);
                break;
            case 'adresse':
                setAdresse(e.target.value);
                break;
            case 'ville':
                setVille(e.target.value);
                break;
            case 'pays':
                setPays(e.target.value);
                break;

        }
        setDisabled(false);
    }

    const handleClick = (e) => {
        setLoading(true);

        add_invister({nom:nom,prenom:prenom,ville:ville,pays:pays,adresse:adresse},process.env.REACT_APP_BACKEND_HOSTING_URL+'investisseur/api/'+user.userId+'/update')
            .then(res => {
                if(res.data.result){
                    setLoading(false);
                    setDisabled(true);
                    setSnackbarState({state:true,message:i18n.t('form.updated')})
                }else{
                    setLoading(false);
                    setSnackbarState({state:true,message:i18n.t('form.updated_error')})
                }
            });
    }
    return ( <React.Fragment>
        <Typography variant="h6" gutterBottom>
            Profil
        </Typography>
        <Grid container spacing={3}>
            <Grid item sm={12}>
                <h4 className="picked_user">{email || user.email || ''}</h4>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="nom"
                    name="nom"
                    value={nom || user.nom || ''}
                    onChange={handleChange}
                    label={ i18n.t('form.nom') }
                    fullWidth
                    autoFocus
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="prenom"
                    name="prenom"
                    value={prenom || user.prenom || ''}
                    onChange={handleChange}
                    label={ i18n.t('form.prenom') }
                    fullWidth


                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    id="adresse"
                    name="adresse"
                    label={ i18n.t('form.adresse') }
                    fullWidth
                    value={adresse || user.adresse || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="ville"
                    name="ville"
                    label={ i18n.t('form.ville') }
                    fullWidth
                    value={ ville || user.ville || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="pays"
                    name="pays"
                    label={ i18n.t('form.pays') }
                    fullWidth
                    value={pays || user.pays || ''}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Button className="updatebutton" onClick={handleClick} disabled={disabled}>
                    {loader ? <div className="progress_loader"><CircularProgress  size={24} /></div>:i18n.t('form.update') }
                </Button>

            </Grid>
        </Grid>
    </React.Fragment>);
}