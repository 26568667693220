import React, {useState} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../../helpers/i18n";
import {useStoreState} from "easy-peasy";
import {FormGroup,Row,Col} from "reactstrap";
import {Link} from "react-router-dom";
import PageSection from "../../../components/PageSection";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AccountForm from './AccountForm';
import PasswordForm from './PasswordForm';
export default function MyAccount(){

    return <div className="content_wrapper">
        <PageSection
            key={3}
            sectionClass="innerBlocboxSection"
            sectionContainer="innerBlockboxContainer"
            bigTitle="Mon"
            subTitle="Compte"/>
        <Row>
            <div className="col-md-8">
                    <AccountForm/>
            </div>
            <div  className="col-md-4">
                    <PasswordForm/>
            </div>
        </Row>

            </div>


}