import React, {useState} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../../helpers/i18n";
import {useStoreActions, useStoreState} from "easy-peasy";
import {FormGroup,Row,Button} from "reactstrap";
import {Link} from "react-router-dom";
import PageSection from "../../../components/PageSection";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {add_invister, update_pwd} from '../../../../helpers/functions';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export default function UserPassword(props) {
    const {setSnackbarState} = useStoreActions(actions => actions);
    const {user} = props

    const [usrpwd,setUsrpwd] = useState('');
    const [confirmusrpwd,setConfirmusrpwd] = useState('');
    const [disabled,setDisabled] = useState(true);
    const [loader,setLoading] = useState(false);
    const handleChange= (e) =>{

        if(e.target.name === "usrpwd"){
            setUsrpwd(e.target.value);
        }
        if(e.target.name === "confirmusrpwd"){
            setConfirmusrpwd(e.target.value);
        }
        if(usrpwd === confirmusrpwd && usrpwd.length > 0){
            setDisabled(false);
        }
    }
    const handleClickChangePwd = () => {
        setLoading(true);
        update_pwd({usrpwd:usrpwd,confirmusrpwd:confirmusrpwd},process.env.REACT_APP_BACKEND_HOSTING_URL+'api/'+user.userId+'/changepwd')
            .then(res => {
                if(res.data.result){
                    setLoading(false);
                    setDisabled(true);
                    setSnackbarState({state:true,message:i18n.t('form.updated')})
                }else{
                    setLoading(false);
                    setSnackbarState({state:true,message:i18n.t('form.updated_error')})
                }
            });
    }
    return ( <React.Fragment>
        <Typography variant="h6" gutterBottom>
            Mot de passe
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="usrpwd"
                    name="usrpwd"
                    label={ i18n.t('login.password') }
                    fullWidth
                    autoComplete="usrpwd"
                    onChange={handleChange}
                    type="password"
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="confirmusrpwd"
                    name="confirmusrpwd"
                    label={ i18n.t('login.confirmationpassword') }
                    fullWidth
                    autoComplete="confirmusrpwd"
                    type="password"
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Button className="updatebutton" onClick={handleClickChangePwd} disabled={disabled}>
                    {loader ? <div className="progress_loader"><CircularProgress  size={24} /></div>:i18n.t('login.changepwd') }
                </Button>
            </Grid>
        </Grid>
    </React.Fragment>);
}