import secteursData from './secteursData';
import {autoMobile} from './secteursFunctions/autoMobile';
import {aeronautique} from './secteursFunctions/aeronautique';
import {naval} from './secteursFunctions/naval';
import {textile} from './secteursFunctions/textile';
import {agroalimentaire} from './secteursFunctions/agroalimentaire';
import {offshoring} from "./secteursFunctions/offshoring";
import {industries_diverses} from "./secteursFunctions/industries_diverses";
import axios from 'axios';
export function sousSecteurOffre(secteur,sousSecteur,simulationForm,offres,ecosystems,metiers_selected){
    var offres_result;
    if(sousSecteur.id === secteursData.aeronautique){
        offres_result = aeronautique(secteur,sousSecteur,simulationForm,offres,ecosystems);
    }
    if(sousSecteur.id === secteursData.automobile){
        offres_result = autoMobile(secteur,sousSecteur,simulationForm,offres,ecosystems);
    }
    if(sousSecteur.id === secteursData.naval){
        offres_result = naval(secteur,sousSecteur,simulationForm,offres,ecosystems);
    }
    if(sousSecteur.id === secteursData.textile_cuir){
        offres_result = textile(secteur,sousSecteur,simulationForm,offres,ecosystems);
    }
    if(sousSecteur.id === secteursData.agroalimentaire){
        offres_result = agroalimentaire(secteur,sousSecteur,simulationForm,offres,ecosystems,metiers_selected);
    }
    if(sousSecteur.id === secteursData.offshoring){
        offres_result = offshoring(secteur,sousSecteur,simulationForm,offres,ecosystems,metiers_selected);
    }
    if(sousSecteur.id === secteursData.industries_diverses){
        offres_result = industries_diverses(secteur,sousSecteur,simulationForm,offres,ecosystems);
    }

    //Databaseinjection
    let userId = 0;
    if(localStorage.getItem('isLogged')){
        userId = localStorage.getItem('isLogged');
        add_estimation({secteur:sousSecteur.id,user:userId,ecosystem:simulationForm.ecosystem_items,details:simulationForm},process.env.REACT_APP_BACKEND_HOSTING_URL+'estimation/api/new')
    }else{
        add_user({nom:simulationForm.nom,prenom: simulationForm.prenom,email:simulationForm.email},process.env.REACT_APP_BACKEND_HOSTING_URL+'register')
            .then(res => {
                console.log("thenot logged user");
                console.log(res.data);
                localStorage.setItem('isLogged',res.data.user);
                add_estimation({secteur:sousSecteur.id,user:res.data.user,ecosystem:simulationForm.ecosystem_items,details:simulationForm},process.env.REACT_APP_BACKEND_HOSTING_URL+'estimation/api/new')
            });
    }


   /* const data_array = [];
    forEach(simulationForm,function (value,prop,obj) {
        console.log('porp '+prop);
        console.log('value'+value)
        data_array.push({obj:value});
    });
    console.log('data_array');
    console.log(data_array);*/
    // End database injection
    return  offres_result ;
}
// for password usr update
export async function update_pwd(personalInfo = {},api_endpoint){
    const formData = new FormData();
    formData.set('usrpwd',personalInfo.usrpwd);
    formData.set('confirmusrpwd',personalInfo.confirmusrpwd);

    return await axios({
        method: 'post',
        url: api_endpoint,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    });
}
// for update invister data
export async  function add_invister(personalInfo = {},api_endpoint){
    const formData = new FormData();

    formData.set('nom',personalInfo.nom);
    formData.set('prenom',personalInfo.prenom);
    formData.set('adresse',personalInfo.adresse);
    formData.set('ville',personalInfo.ville);
    formData.set('pays',personalInfo.pays);

    return await axios({
        method: 'post',
        url: api_endpoint,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    });
}
export async  function add_user(personalInfo = {},api_endpoint){
    const formData = new FormData();

        formData.set('email',personalInfo.email);
        formData.set('nom',personalInfo.nom);
        formData.set('prenom',personalInfo.prenom);

   return await axios({
        method: 'post',
        url: api_endpoint,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
        })

}
export async  function add_estimation(estimationInfo = {},api_endpoint){
    const formData = new FormData();

    formData.set('secteur',estimationInfo.secteur);
    formData.set('user',estimationInfo.user);
    formData.set('ecosystem',estimationInfo.ecosystem);
    formData.set('details',JSON.stringify(estimationInfo.details));


    return await axios({
        method: 'post',
        url: api_endpoint,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    })

}
export function offer_title_rename(typeoffre,lesoffres,offer_name){
    const offres =lesoffres;
    const row = offres.find(el => el.slug === typeoffre);
    const row_index = offres.findIndex(el => el.slug === typeoffre);
    row.offre = offer_name;
    offres[row_index]= row;
    return offres;
}
export function textile_ecosys_amount(item_name,typeoffre,lesoffres,deux_premiers,trois_derniers){
    const offres =lesoffres;
    const row = offres.find(el => el.slug === typeoffre);
    const row_index = offres.findIndex(el => el.slug === typeoffre);
    const index = row.items.findIndex(el => el.item_name === item_name);

    row.items[index]["deux_premiers_exercices"] = deux_premiers;
    row.items[index]["trois_derniers_exercices"] = trois_derniers;
    row.items[index]["description"] = "Deux premiers exercices: ("+deux_premiers+' M x 2) + Trois derniers exercices: ('+trois_derniers+' M x 3)';
    row.items[index]["item_amount"] = (deux_premiers*2)+(trois_derniers*3);
    offres[row_index]= row;

    return offres;
}
export function add_items(offres,offre,items){
    var item = offres.find(of => of.slug === offre);
    var index = offres.findIndex(of => of.slug === offre);
    item.items.push(items);
    offres[index] = item;


    return offres;
}
export function find_item_amount(item_name,typeoffre,lesoffres,new_val){
    const offres =lesoffres;
    const row = offres.find(el => el.slug === typeoffre);
    const row_index = offres.findIndex(el => el.slug === typeoffre);
    const index = row.items.findIndex(el => el.item_name === item_name);

    row.items[index]["item_amount"] = new_val;

        offres[row_index]= row;

    return offres;
 }
export function add_item_label(item_name,typeoffre,lesoffres,label){
    const offres =lesoffres;
    const row = offres.find(el => el.slug === typeoffre);
    const row_index = offres.findIndex(el => el.slug === typeoffre);
    const index = row.items.findIndex(el => el.item_name === item_name);

    row.items[index]["item_label"] = label;

    offres[row_index]= row;

    return offres;
}
 export function addTotal(offres,typeoffre,total){
    const row = offres.find(el => el.slug === typeoffre);
    const rowIndex = offres.findIndex(el => el.slug === typeoffre);
    row.total.total_amount = round(total,2);
    offres[rowIndex] = row;
    return offres;
 }
export function addPlafond(offres,typeoffre,message){
    const row = offres.find(el => el.slug === typeoffre);
    const rowIndex = offres.findIndex(el => el.slug === typeoffre);
    row.finalResult = message;
    offres[rowIndex] = row;
    return offres;
}
export function makeItAppears(offres,typeoffre) {
    const row = offres.find(el => el.slug === typeoffre);
    const rowIndex = offres.findIndex(el => el.slug === typeoffre);
    console.log('row');
        console.log(row);
        if(row.total !== undefined && typeoffre !== "fdii_industries"){
            if(row.total.total_amount > 0){
                row.offre_allowed = true;
            }

        }else if(typeoffre === "fdii_industries"){
            row.offre_allowed = true;
        }


    offres[rowIndex] = row;
    return offres;
}
export function resetIt(offres){
    const row = offres.find(el => el.offre_allowed === true);
    const rowIndex = offres.findIndex(el => el.offre_allowed === true);
    row.offre_allowed = false;
    if(row.slug === "fdii_industries"){
        row.items = [];
        console.log("reset it");
        console.log(row);
    }
    offres[rowIndex] = row;
    return offres;
}
export function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
export function idustriesDiveresesOffres(offres,sousSecteur,ecosystems,simulationForm){
    console.log('idustriesDiveresesOffres');
    console.log(offres);
}
export function bestCombinaison(offres,sousSecteur,ecosystems,simulationForm){

        var ecosystem_items = simulationForm.ecosystem_items;
        var ecosystem = ecosystems.items.find(el => el.id === parseInt(ecosystem_items));

        var items_names = [];
        if ((ecosystem.slug === "chaussures_en_cuir"
            || ecosystem.slug === "maroquinerie_et_vetements_en_cuir"
            || ecosystem.slug === "tannerie_et_megisserie") && offres.find(offre => offre.slug === "fdii_textile" && offre.offre_allowed === true)) {

            items_names = ['prime_retenue',
                'accompagnement',
                'equipement',
                'transfert_mobilisation',
                'divers',
                'formation',
                'infrastructure_externe',
                'reseau_distribution',
                'agregateurs_produit_finis_et_soustraitants', 'operateurs_amont_industriel'];
        } else {
            items_names = ['prime_retenue',
                'terrain_construction',
                'accompagnement',
                'equipement',
                'transfert_mobilisation',
                'divers',
                'formation',
                'infrastructure_externe',
                'reseau_distribution',
                'agregateurs_produit_finis_et_soustraitants',
                'operateurs_amont_industriel',
                'contribution_liee_ir', 'contribution_aux_frais_de_la_formation',
                'contribution_liee_is', 'contribution_ir_amelioree', 'prime_materiel_immateriel', 'prime_a_lacroissance'];
        }

        var collectedData = [];
        var hideIt = false;


            var isFdiiActive = offres.find(offre => offre.slug === "fdii" && offre.offre_allowed === true);
            var isPMEActive = offres.find(offre => offre.slug === "maroc_pme" && offre.offre_allowed === true);



        if (isFdiiActive !== undefined && isPMEActive !== undefined && sousSecteur.id === secteursData.agroalimentaire) {
            hideIt = true;
        }
        items_names.forEach(function (item) {

            offres.forEach(function (elm, i) {

                    if (elm.items.find(el => el.item_name === item) !== undefined) {
                        if (elm.slug !== bestOffre(offres)) {
                            collectedData.push(elm.items.find(el => el.item_name === item));
                            if (elm.slug === "maroc_pme" && hideIt === false && isFdiiActive !== undefined) {

                            } else {

                            }
                        }
                    }

            });
        });


        const grouped = groupBy(collectedData, 'item_name');
        var ordered_list = [];
        grouped.forEach(function (row) {
            var max = 0;
            var index = 0;
            row.forEach(function (el, j) {

                if (max < el.item_amount) {
                    max = el.item_amount;
                    index = j;
                }

            });
            ordered_list.push(row[index]);
        });
        var mylist = [];
        if (bestOffre(offres)) {
            var offre = offres.find(offre => offre.slug === bestOffre(offres));
            if (offre !== undefined) {
                if (sousSecteur.id === secteursData.textile_cuir && offre.slug === "fpi") {
                }
                else {
                    offre.items.forEach(function (item) {
                        if (item.item_amount !== null ) {
                            if(item.item_amount > 0){
                                mylist.push({
                                    order: offre.order,
                                    offreTitle: offre.offre,
                                    item_name: item.item_label,
                                    item_amount: item.item_amount
                                });
                            }

                        }

                    });
                }
            }

        }
        var offres_selected = offres.find(offre => offre.slug === bestOffre(offres));
        ordered_list.forEach(function (element) {
            offres.forEach(function (elm, i) {
                if (elm.items.find(el => el === element) !== undefined && elm.slug === "fpi" && elm.offre_allowed === true && sousSecteur.id !== secteursData.textile_cuir) {
                    if (offres_selected.items.find(item => item.item_name === element.item_name) === undefined) {
                        if(element.item_amount > 0){
                            mylist.push({
                                order: element.order,
                                offreTitle: elm.offre,
                                item_name: element.item_label,
                                item_amount: element.item_amount
                            });
                        }

                    }

                }
            });
        })

        return mylist;

}
export function groupBy(collection, property) {
    var i = 0, val, index,
        values = [], result = [];
    for (; i < collection.length; i++) {
        val = collection[i][property];
        index = values.indexOf(val);
        if (index > -1)
            result[index].push(collection[i]);
        else {
            values.push(val);
            result.push([collection[i]]);
        }
    }
    return result;
}
export function bestOffre(offres){
    const types = ['fdii','fdii_ecosystemes_textile','fit','maroc_tpe','fpi','fh2','maroc_pme','fdii_textile'];

    var max_type = 'fdii';
    var max_amount = 0;

    types.forEach(function(item) {

        offres.forEach(function (elm,i) {
            if(elm.slug === item){
                if(elm.total.total_amount > max_amount){
                    max_type = elm.slug;
                    max_amount = elm.total.total_amount;
                }

            }
        });

    });
    return max_type;
}

export function get_percent(amount,percent) {
    return amount*percent/100;
}
export function get_min(a,b){
    if(a > b){
        return b;
    }else{
        return a;
    }
}
export function get_max(a,b){
    if(a > b){
        return a;
    }else{
        return b;
    }
}
export function to_million(endirham){
    return endirham/1000000;
}
export function getRbiByEco(gradesrbi,getInputsOffshoring){
    var totalRbi = 0;
    var percent = 0;
    getInputsOffshoring.forEach(function (elm) {
        if(elm.type === 'operateurs'){
              percent = 0;
             if(elm.salary.from < gradesrbi.salaire_operateurs && elm.salary.to > gradesrbi.salaire_operateurs){
                 percent = elm.value;
             }else if(gradesrbi.salaire_operateurs < elm.salary.from){
                 percent = elm.value_less_than_from;
             }else if(gradesrbi.salaire_operateurs > elm.salary.to){
                 percent = elm.value_more_than_to;
             }
             totalRbi = totalRbi + (gradesrbi.salaire_operateurs*gradesrbi.nbre_operateurs*percent);
        }
        if(elm.type === 'ingenieurs'){
             percent = 0;
            if(elm.salary.from < gradesrbi.salaire_ingenieurs && elm.salary.to > gradesrbi.salaire_ingenieurs){
                percent = elm.value;
            }else if(gradesrbi.salaire_ingenieurs < elm.salary.from){
                percent = elm.value_less_than_from;
            }else if(gradesrbi.salaire_ingenieurs > elm.salary.to){
                percent = elm.value_more_than_to;
            }
            totalRbi = totalRbi + (gradesrbi.salaire_ingenieurs*gradesrbi.nbre_ingenieurs*percent);
        }
        if(elm.type === 'administrateurs'){
            percent = 0;
            if(elm.salary.from < gradesrbi.salaire_administrateurs && elm.salary.to > gradesrbi.salaire_administrateurs){
                percent = elm.value;
            }else if(gradesrbi.salaire_administrateurs < elm.salary.from){
                percent = elm.value_less_than_from;
            }else if(gradesrbi.salaire_administrateurs > elm.salary.to){
                percent = elm.value_more_than_to;
            }
            totalRbi = totalRbi + (gradesrbi.salaire_administrateurs*gradesrbi.nbre_administrateurs*percent);
        }
        if(elm.type === 'techniciens'){
            percent = 0;
            if(elm.salary.from < gradesrbi.salaire_techniciens && elm.salary.to > gradesrbi.salaire_techniciens){
                percent = elm.value;
            }else if(gradesrbi.salaire_techniciens < elm.salary.from){
                percent = elm.value_less_than_from;
            }else if(gradesrbi.salaire_techniciens > elm.salary.to){
                percent = elm.value_more_than_to;
            }
            totalRbi = totalRbi + (gradesrbi.salaire_techniciens*gradesrbi.nbre_techniciens*percent);
        }


    });


}
export function get_rbi(niveau,salaire,offshoring_rbi){
        var percent = 0;
        var item = offshoring_rbi.types.find((elm) => elm.niveau === niveau);
        if(item !== undefined){
            if(item.salaire.de < salaire && item.salaire.a > salaire){
                percent = item.percent;
            }else if(salaire < item.salaire.de){
                percent = item.percent_smaller_than_de;
            }else if(salaire > item.salaire.a){
                percent = item.percent_bigger_than_a;
            }
        }
        return percent;
}
export function getIsIr(ca,offshoring_is_ir){
    var item = offshoring_is_ir.ranges.find((elm) => elm.ca_from < ca && elm.ca_to > ca);
    var result = {ir:0,is:0}
    if(item !== undefined){
        result.is = item.is;
        result.ir = item.ir;
    }
    return result;
}
export function getInputsOffshoring(ecosystem){
    var types = [{
        type:"ingenieurs",
        inputs:[
        {
            label:"Nombre d'emploi des Ingénieurs Bac +5",
            name:"nbre_ingenieurs",
            unit:""
        },{
            label:"Salaire moyen des Ingénieurs Bac +5",
            name:"salaire_ingenieurs",
            unit:"DH"

        }],
        salary:{
            from: 8000,
            to:12000,
            value:24,
            value_less_than_from:21,
            value_more_than_to:27
        }
    },{
        type:"operateurs",
        inputs:[
            {
                label:"Nombre d'emploi des opérateurs",
                name:"nbre_operateurs",
                unit:""
            },{
            label:"Salaire moyen des opérateurs",
            name:"salaire_operateurs",
            unit:"DH"
        }],
        salary:{
            from: 3000,
            to:4500,
            value:14,
            value_less_than_from:10,
            value_more_than_to:18
        }
    },{
        type:"techniciens",
        inputs:[
            {
                label:"Nombre d'emploi des Techniciens Bac +2/3",
                name:"nbre_techniciens",
                unit:""
            },{
            label:"Salaire moyen des Techniciens Bac +2/3",
            name:"salaire_techniciens",
            unit:"DH"
        }],
        salary:{
            from: 4000,
            to:6500,
            value:18,
            value_less_than_from:15,
            value_more_than_to:20
        }
    },{
        type:"administrateurs1",
        inputs:[
            {
                label:"Nombre d'emploi Administratifs niveau 1 Bac-Bac +2",
                name:"nbre_administrateurs1",
                unit:""
            },{
            label:"Salaire moyen des Administratifs niveau 1 Bac-Bac +2",
            name:"salaire_administrateurs1",
            unit:"DH"
        }],
        salary:{
            from: 9000,
            to:14500,
            value:22,
            value_less_than_from:15,
            value_more_than_to:26
        }
    },{
        type:"administrateurs2",
        inputs:[
            {
                label:"Nombre d'emploi Administratifs niveau 2 Bac +2",
                name:"nbre_administrateurs2",
                unit:""
            },{
                label:"Salaire moyen des Administratifs niveau 2 Bac +2",
                name:"salaire_administrateurs2",
                unit:"DH"
            }],
        salary:{
            from: 9000,
            to:14500,
            value:22,
            value_less_than_from:15,
            value_more_than_to:26
        }
    },{
        type:"managers1",
        inputs:[
            {
                label:"Nombre d'emploi des Managers Bac +5",
                name:"nbre_managers1",
                unit:""
            },{
                label:"Salaire moyen des Managers Bac +5",
                name:"salaire_managers1",
                unit:"DH"
            }],
        salary:{
            from: 9000,
            to:14500,
            value:22,
            value_less_than_from:15,
            value_more_than_to:26
        }
    },{
        type:"managers2",
        inputs:[
            {
                label:"Nombre d'emploi des Managers Bac +3",
                name:"nbre_managers2",
                unit:""
            },{
                label:"Salaire moyen des Managers Bac +3",
                name:"salaire_managers2",
                unit:"DH"
            }],
        salary:{
            from: 9000,
            to:14500,
            value:22,
            value_less_than_from:15,
            value_more_than_to:26
        }
    }];
    var returned_value = [];
    switch (ecosystem) {
        case 'crm':
            returned_value.push(types.find(elm => elm.type === "administrateurs1"));
            returned_value.push(types.find(elm => elm.type === "administrateurs2"));
            break;
        case 'bpo':
            returned_value.push(types.find(elm => elm.type === "administrateurs1"));
            returned_value.push(types.find(elm => elm.type === "administrateurs2"));
            returned_value.push(types.find(elm => elm.type === "managers1"));
            break;
        case 'ito':
            returned_value.push(types.find(elm => elm.type === "techniciens"));
            returned_value.push(types.find(elm => elm.type === "ingenieurs"));
            break;
        case 'eso':
            returned_value.push(types.find(elm => elm.type === "techniciens"));
            returned_value.push(types.find(elm => elm.type === "ingenieurs"));
            break;
        case 'kpo':

            returned_value.push(types.find(elm => elm.type === "techniciens"));
            returned_value.push(types.find(elm => elm.type === "ingenieurs"));
            returned_value.push(types.find(elm => elm.type === "managers1"));
            returned_value.push(types.find(elm => elm.type === "managers2"));
            break;


    }
    return returned_value;
}
export function getNatureDeProjet(ecosystem,metier){
   var natures = [];
   switch(ecosystem){
       case secteursData.ecosys_industries_diverses.chimie_verte:
           if(metier === secteursData.metiers_industries_diverses.extraits_plantes_aromatiques){
               natures.push({
                   "title":"Valorisation des ressources",
                   "slug":"valorisation_des_ressources"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.valorisation_du_caroube){
               natures.push({
                   "title":"Valorisation des ressources",
                   "slug":"valorisation_des_ressources"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.colorants_naturels){
               natures.push({
                   "title":"Valorisation des ressources",
                   "slug":"valorisation_des_ressources"
               },{
                   "title":"Pionnier pour la mise en place de trois tours d’atomisation",
                   "slug":"pionnier"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.aromes){
               natures.push({
                   "title":"Locomotive",
                   "slug":"locomotive"
               });
           }
           break;
       case secteursData.ecosys_industries_diverses.chimie_organique :
           if(metier === secteursData.metiers_industries_diverses.resine || metier === secteursData.metiers_industries_diverses.peinture_technique){
               natures.push({
                   "title":"Sourcing",
                   "slug":"sourcing"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.detergence_liquide || metier === secteursData.metiers_industries_diverses.cosmetique_low_cost){
               natures.push({
                   "title":"Locomotive",
                   "slug":"locomotive"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.produit_efficacite_energitique){
               natures.push({
                   "title":"Pionnier",
                   "slug":"pionnier"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.phytosanitaire){
               natures.push({
                   "title":"Locomotive",
                   "slug":"locomotive"
               });
           }

           break;
       case secteursData.ecosys_industries_diverses.medicaments:
           if(metier === secteursData.metiers_industries_diverses.principes_actif_packagine || metier === secteursData.metiers_industries_diverses.biosimilaire ){
               natures.push({
                   "title":"Pionnier",
                   "slug":"pionnier"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.fabrication_local){
               natures.push({
                   "title":"Locomotive",
                   "slug":"locomotive"
               },{
                   "title":"Stratégiques et / ou Structurants",
                   "slug":"strategiques_structurants"
               });
           }
           break;
       case secteursData.ecosys_industries_diverses.dispositifs_medicaux:
           if(metier === secteursData.metiers_industries_diverses.fabrication_appariels_autodiagnostique){
               natures.push({
                   "title":"Pionnier",
                   "slug":"pionnier"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.fabrication_equipements_electrique){
               natures.push({
                   "title":"Locomotive",
                   "slug":"locomotive"
               },{
                   "title":"Pionnier",
                   "slug":"pionnier"
               });
           }
           break;
       case secteursData.ecosys_industries_diverses.plastergie:
           if(metier === secteursData.metiers_industries_diverses.btp ||
               metier === secteursData.metiers_industries_diverses.emballage ||
               metier === secteursData.metiers_industries_diverses.plasticulture){
               natures.push({
                   "title":"Locomotive",
                   "slug":"locomotive"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.autres_projets){
               natures.push({
                   "title":"Pionniers dans la fabrication des moules",
                   "slug":"pionnier"
               });
           }
           break;
       case secteursData.ecosys_industries_diverses.materieux_construction:
           if(metier === secteursData.metiers_industries_diverses.ceramique ||
               metier === secteursData.metiers_industries_diverses.acier||
               metier === secteursData.metiers_industries_diverses.ciment){
               natures.push({
                   "title":"Valorisation des ressources",
                   "slug":"valorisation_des_ressources"
               },{
                   "title":"Pionnier",
                   "slug":"pionnier"
               });
           }
           if(metier === secteursData.metiers_industries_diverses.marbre ||
               metier === secteursData.metiers_industries_diverses.prefabrique ){
               natures.push({
                   "title":"Locomotive",
                   "slug":"locomotive"
               });
           }
           break;
       case secteursData.ecosys_industries_diverses.valorisation_des_metaux:
           if(metier === secteursData.metiers_industries_diverses.valorisation_des_metaux||
               metier === secteursData.metiers_industries_diverses.nouveaux_metiers){
               natures.push({
                   "title":"Pionnier",
                   "slug":"pionnier"
               });
           }
           break;
       case secteursData.ecosys_industries_diverses.ocp:
           if(metier === secteursData.metiers_industries_diverses.equipements_mines_chimie ||
               metier === secteursData.metiers_industries_diverses.construction_industriels ||
               metier === secteursData.metiers_industries_diverses.chimie){
               natures.push({
                   "title":"Stratégiques et / ou Structurants",
                   "slug":"strategiques_structurants"
               });
           }
           break;

   }
   return natures;
}
export function baremeRevenuAnnuel(revenuAnnuel){
        var row = {};
        var baremes = [{ du:0,
                        au:30000,
                        taux_impot:0,
                        deduction:0},
                        { du:50001,
                        au:60000,
                        taux_impot:20,
                        deduction:8000},
                        { du:60001,
                        au:80000,
                        taux_impot:30,
                        deduction:14000},
                        { du:80001,
                            au:180000,
                            taux_impot:34,
                            deduction:17200},
                        { du:180001,
                            au:9999999,
                            taux_impot:38,
                            deduction:24400}];
        row = baremes.find(row => revenuAnnuel >= row.du && revenuAnnuel <= row.au);
        return row;
}
export function emploies_salares(ecosystem,reduxModel){
    const offShoringFields = getInputsOffshoring(ecosystem);
    var nbre = 0
    var salaire = 0;
    var prime = 0;
    offShoringFields.forEach(function (elm) {
        nbre = reduxModel['nbre_'+elm.type]!==undefined? reduxModel['nbre_'+elm.type]:0;
        salaire = reduxModel['salaire_'+elm.type]!==undefined? reduxModel['salaire_'+elm.type]:0;
        prime = prime + (parseFloat(nbre)*parseFloat(to_million(salaire)));

    });
    return prime;
}
export function contributionIr(ecosystem,reduxModel) {
    const offShoringFields = getInputsOffshoring(ecosystem);
    let employes = [];
    offShoringFields.forEach(function (elm) {
        employes.push({nbre:(reduxModel['nbre_'+elm.type]!==undefined? reduxModel['nbre_'+elm.type]:0),
                        salaire:(reduxModel['salaire_'+elm.type]!==undefined? reduxModel['salaire_'+elm.type]:0)});
    });
    let ir=0,rbi=0;
    employes.forEach(function (emp) {
        let salaire_annuel = emp.salaire*12;
        if(baremeRevenuAnnuel(salaire_annuel) !== undefined){
            ir+= get_percent(salaire_annuel,baremeRevenuAnnuel(salaire_annuel).taux_impot)-baremeRevenuAnnuel(salaire_annuel).deduction;
            rbi+= salaire_annuel*emp.nbre*1.2;
        }

          console.log('rbi'+rbi);
        console.log('ir'+ir);
    });
    //ca_export_services_premier_deuxieme
    //ca
    let chaiffreAffaire = parseFloat(reduxModel.ca);
    let caes = parseFloat(reduxModel.ca_export_services_premier_deuxieme);
    return to_million(ir-get_percent(rbi,20))*(get_percent(chaiffreAffaire,caes)/chaiffreAffaire);
}
const forEach = function (collection, callback, scope) {
    if (Object.prototype.toString.call(collection) === '[object Object]') {
        for (var prop in collection) {
            if (Object.prototype.hasOwnProperty.call(collection, prop)) {
                callback.call(scope, collection[prop], prop, collection);
            }
        }
    } else {
        for (var i = 0, len = collection.length; i < len; i++) {
            callback.call(scope, collection[i], i, collection);
        }
    }
};

/**
 * @return {boolean}
 */
export function validateEmail(email)
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
        return true;
    }else{
        return false;
    }
}
