import React,{ useContext }  from 'react';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { withTranslation } from 'react-i18next';
import LangDropdown from './LangDropdown';
import LoginBox from "./components/LoginBox";
import LogoutBox from "./components/LogoutBox";
import {Link} from "react-router-dom";
import {useStoreState} from "easy-peasy";
function Header(props){
    const {isLogged} = useStoreState(state => state.user);

        return (

        <header className="App-header">
          <Container>
            <Row>
                <Col md={2}>
                    {isLogged &&
                      <Link className="my_account" to="/dashboard">Mon compte</Link>
                    }



                </Col>
            <Col  md={{ size: 6, offset: 1 }} sm={6}>
                <a title="" className="header_logo" href="/"><img src="/imgs/logo.png" /></a>
                <h4 className="header_subtitle">Secteurs industriels</h4>

            </Col>
            <Col>
                <div className="language_selector">
                    { isLogged === true ? <LogoutBox/> : <LoginBox/> }



                <LangDropdown></LangDropdown>
                </div>
            </Col>
            </Row>
          </Container>
        </header>
        
      );
}

export default withTranslation()(Header);
