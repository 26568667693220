import React from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";
import VerticalMenu from "./components/VerticalMenu";
import MySnackbar from "./components/MySnackbar";
export default class Footer extends React.Component{
    constructor(props) {
        super(props);
        console.log(this.props)
        this.state = {
            menuFirst: {
                title: "LIENS RAPIDES",
                items: [
                    {
                        id: 1,
                        text: "Comment ça marche?",
                        link: "#csm"
                    }, {
                        id: 2,
                        text: "Pourquoi le Maroc?",
                        link: "#plm"
                    }, {
                        id: 3,
                        text: "Nos offres",
                        link: "#nosoffres"
                    }
                ]
            },
            menuSecond:{
                title:"",
                items:[
                    {
                        id: 1,
                        text: "FAQ",
                        link: "#faq"
                    }, {
                        id: 2,
                        text: "Démarrer votre projet",
                        link: "#demarrer"
                    }, {
                        id: 3,
                        text: "Où investir au Maroc?",
                        link: "#where"
                    },, {
                        id: 4,
                        text: "Contact",
                        link: "#contact"
                    }
                ]
            }
        }
    }
    render(){
        return(
            <div className="footer_wrapper">
                <footer className="app_footer">
                    <Container>
                        <Row>
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <img className="logoFooter" src="/imgs/logo_footer.png"/>
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={12}>
                                <VerticalMenu list={this.state.menuFirst.items} title={this.state.menuFirst.title} />
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={12}>
                                <VerticalMenu list={this.state.menuSecond.items} title={this.state.menuSecond.title} />
                            </Col>
                        </Row>
                    </Container>

                </footer>
                <footer className="footerBottom">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <p className="copyright">Tous les droits réservés © {(new Date().getFullYear())}</p>
                            </Col>
                        </Row>
                    </Container>
                </footer>
                <MySnackbar/>
            </div>


        );
    }
}