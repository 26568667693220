import React, { useState, useEffect  }  from 'react';
import {Card, CardBody, CardTitle, Col, Container, FormGroup, Row} from "reactstrap";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {Link} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl/FormControl";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { useStoreActions,useStoreState } from "easy-peasy";
export default function FieldElement(props){
    const {addValue} = useStoreActions(actions => actions);
    const [option, setOption] = React.useState('');
    const {field_label,
            field_type,
            field_option,
            selector_id,
            selector_classes,
            selector_placeholder,
            true_value,defaultValue } = props


    const useStyles = makeStyles(theme => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,

        },
    }));

    const handleSelectChange = async (event) => {
        await addValue({name: event.target.name,value:event.target.value});
        setOption(event.target.value)

    }
    useEffect(() => {
        setOption(defaultValue)
    });
    switch(field_type) {
        case 'select':
            return (
                <FormControl  className={useStyles.formControl}>
                    <InputLabel htmlFor="selector_extra">
                        {field_label}
                    </InputLabel>
                    <Select
                        value={option}
                        onChange={handleSelectChange}
                        inputProps={{
                            name: selector_id,
                            id: selector_id,
                        }}>
                        {field_option.split('|').map((opt) => (
                            <MenuItem  key={opt} value={opt}>{opt}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        default:
            return null;
    }
}