import {addPlafond, addTotal, find_item_amount, makeItAppears,get_percent,get_max,get_min,to_million,offer_title_rename} from "../functions";
import secteursData from "../secteursData";
export function autoMobile(secteur,sousSecteur,simulationForm,offres,ecosystems){
    const {
        eco_system_metier,
        montant_location,
        isrent,
        extra_field_value,
        taux_integration,
        montant_terain,
        montant_construction,
        mi,
        montant_equipements_neuf,
        montant_equipements_ocassion,
        nbre_demploi,
        transfert_technologique,
        zones_select,
        environnement,
        infrastructure_externe,
        formation_input,
        montant_divers,ca,ca1,ca2,ca3,
        ecosystem_items,
        type_foncier,
        superficier_total_batiment,
        cout_acquisition_batiment,
        cout_construction_batiment,
        cout_loyer_foncier,
        superficier_total_foncier,
        cout_loyer_batiment,
        frais_notaire,
        frais_enregistrement,
        frais_conservation,
    } = simulationForm
    const ecosystem_selected = parseInt(ecosystem_items);
    var automobile_offres = offres.industrie.automobile;
    // Offre Ecosystè FDII
    var percentage = 0;
    //Calculation of a and b
    var coutDachatOuLocation = 0;
    var coutEquipementNeuf = 0;


    var get20Mi=get_percent(parseFloat(mi),20);
    if(type_foncier === "Cas d'acquisition ou construction des bâtiments"){
           var cout_acquisition_foncier_batiment = parseFloat(cout_acquisition_batiment) + parseFloat(cout_construction_batiment);
            if(get20Mi >= parseFloat(cout_acquisition_foncier_batiment)){
                coutDachatOuLocation = parseFloat(cout_acquisition_foncier_batiment);
            }else{
                coutDachatOuLocation = get20Mi;
            }
    }else if(type_foncier === "Cas de location du foncier ou des bâtiments"){
        var coutLoyerBatiment = to_million(parseFloat(cout_loyer_batiment)*12*3*parseFloat(superficier_total_batiment));
        if(get20Mi >= coutLoyerBatiment){
            coutDachatOuLocation = coutLoyerBatiment;
        }else{
            coutDachatOuLocation = get20Mi;
        }

    }else if(type_foncier === "Cas de location du foncier et construction"){
        //TO SEE WITH MCINET

    }else if(type_foncier === "Cas de location du bâtiment"){
        // Cout loyer foncier 3 ans
         var coutLoyerFoncier = to_million(parseFloat(cout_loyer_foncier)*12*3*parseFloat(superficier_total_foncier));
         if(get20Mi >= coutLoyerFoncier){
             coutDachatOuLocation = coutLoyerFoncier;
         }else{
             coutDachatOuLocation = get20Mi;
         }

    }



    /*    get20Mi = parseInt(mi)*20/100;
        if(get20Mi>=parseInt(montant_location)){
            coutDachatOuLocation = parseInt(montant_location);
        }else{
            coutDachatOuLocation = get20Mi;
        }
    }else{
        get20Mi = parseInt(mi)*20/100;
        if(get20Mi>=parseInt(montant_terain)+parseInt(montant_construction)){
            coutDachatOuLocation = parseInt(montant_terain)+parseInt(montant_construction);
        }else{
            coutDachatOuLocation = get20Mi;
        }
    }*/
    // Calculation of B
    coutEquipementNeuf = parseInt(montant_equipements_neuf);
    // Prime à l’investissement retenue
    var prime_a = 0;
    var prime_b = 0;

        if(ecosystem_selected === secteursData.ecosys_auto.renault || ecosystem_selected === secteursData.ecosys_auto.psa){
            if(ecosystem_selected === secteursData.ecosys_auto.renault && taux_integration >= 20 && mi >= 100){
                // Need to know : •	Projet d’extension capacitaire : 30 000 000 DH HT pour une extension des investissements
                //Prime de 20% pour les investissements capacitaires fournisseurs (Sous réserve de l’acceptation de Renault)

                percentage = 20;
            }
            if(ecosystem_selected === secteursData.ecosys_auto.psa && taux_integration >= 60){
                percentage = 15;
            }
            prime_a = (coutDachatOuLocation + coutEquipementNeuf)*percentage/100;
        }else{
            if(mi >= 20 && nbre_demploi >= 50){
                console.log(ecosystems);
                if(ecosystem_selected === 5){
                    percentage = 20;

                }
                //interieurs-sieges/metals/powertrain
                if(ecosystem_selected === 6 || ecosystem_selected === 7 || ecosystem_selected === 8){
                    percentage = 30;

                }
                //batterie auto
                if(ecosystem_selected === 9){
                    if( parseInt(eco_system_metier) === 39) {
                        percentage = 20;
                    }
                    if( parseInt(eco_system_metier) === 40) {
                        percentage = 30;
                    }

                }
                prime_b = (coutDachatOuLocation + coutEquipementNeuf)*percentage/100;
            }
        }



    // Montant prime Fdii retenue:
    var primeRetenue = 0;
    if(prime_a === 0){
        primeRetenue = prime_b;
    }else if(prime_b === 0){
        primeRetenue = prime_a;
    }else if(prime_a > prime_b){
        primeRetenue = prime_a;
    }else{
        primeRetenue = prime_b;
    }
    if(primeRetenue !== 0){
        automobile_offres = find_item_amount('prime_retenue','fdii',automobile_offres,primeRetenue);
        automobile_offres =  addTotal(automobile_offres,'fdii',primeRetenue);
        automobile_offres = makeItAppears(automobile_offres,"fdii");
    }
    //Offre FPi
    if(zones_select !== "Franche" && nbre_demploi !== undefined && mi !== undefined && transfert_technologique !== undefined && environnement !== undefined ){
        var getOffre = false;
        if(parseInt(nbre_demploi) >= 250 ||
            parseInt(mi) >= 100 ||
            transfert_technologique.toLowerCase() === "oui" ||
            environnement.toLowerCase() === "oui"
        ){
            //# montant terrain
            const montant_terrain = parseFloat(montant_terain)+to_million(parseFloat(frais_notaire))+to_million(parseFloat(frais_conservation))+to_million(parseFloat(frais_enregistrement));


            var ter = montant_terrain*20/100;

            automobile_offres = find_item_amount('terrain_construction','fpi',automobile_offres,(montant_terrain*20/100))


            //# montant infra
            var infra = 0;
            if(parseFloat(infrastructure_externe) > 0){
                infra = get_min(parseFloat(infrastructure_externe),get_percent(parseFloat(mi),5));
                automobile_offres = find_item_amount('infrastructure_externe','fpi',automobile_offres,infra);
            }


            //# montant formationformation_input
            const montant_formation = parseInt(formation_input);
            var form = montant_formation*20/100;

            automobile_offres = find_item_amount('formation','fpi',automobile_offres,(montant_formation*20/100))


            //Total
            var total = ter + infra + form;
            //Plafond 5%
            var plafond = "Le plafond est de 5% MI";
            if(zones_select === "Rurale"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Suburbaine"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Urbaine"){

                if(total > parseInt(mi)*5/100){
                    total = parseInt(mi)*5/100;
                }
                plafond = "Le plafond est de 5% MI";
            }
            automobile_offres =  addTotal(automobile_offres,"fpi",total);
            automobile_offres =  addPlafond(automobile_offres,"fpi",plafond);
            if(total !== 0){
                automobile_offres = makeItAppears(automobile_offres,"fpi");
            }

        }
    }
    //Maroc PME
    const pme_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) < 200 && parseInt(el) > 10);
    if(pme_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*20)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        automobile_offres = find_item_amount('equipement','maroc_pme',automobile_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*20/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        automobile_offres = find_item_amount('terrain_construction','maroc_pme',automobile_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        if(the_total > 10){
            the_total = 10;
        }
        automobile_offres = find_item_amount('divers','maroc_pme',automobile_offres,montant_assigned);
        automobile_offres =  addTotal(automobile_offres,"maroc_pme",the_total);
        //Plafond 30M
        var plafond = "Le plafond est de: 10M";
        automobile_offres =  addPlafond(automobile_offres,"maroc_pme",plafond);
        automobile_offres = makeItAppears(automobile_offres,"maroc_pme");
    }
    const tpe_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) <= 10);
    if(tpe_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*30)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        automobile_offres = find_item_amount('equipement','maroc_tpe',automobile_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*30/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        automobile_offres = find_item_amount('terrain_construction','maroc_tpe',automobile_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        automobile_offres = find_item_amount('divers','maroc_tpe',automobile_offres,montant_assigned);
        //Plafond 2M
        if(the_total > 2){
            the_total = 2;
        }
        automobile_offres =  addTotal(automobile_offres,"maroc_tpe",the_total);
        //Plafond 2M
        var plafond = "Le plafond est de: 2M";
        automobile_offres =  addPlafond(automobile_offres,"maroc_tpe",plafond);
        automobile_offres = makeItAppears(automobile_offres,"maroc_tpe");
    }
    //#FH2
    //#fh2 start


    if(parseInt(mi)>10 && parseInt(montant_equipements_neuf) >= 5 ){
       var cout_batiment = 0;
       var the_total = 0;
       var plafond_total =0;
        if(type_foncier === "Cas d'acquisition ou construction des bâtiments"){
            // valeur en million
            cout_batiment = parseFloat(cout_acquisition_batiment)+parseFloat(cout_construction_batiment);
            cout_batiment = get_percent(cout_batiment,10);
            automobile_offres = find_item_amount('terrain_construction','fh2',automobile_offres,cout_batiment);
            var c =  get_percent(parseFloat(montant_equipements_neuf)+parseFloat(montant_equipements_ocassion),20);
            plafond_total = get_min(get_min((cout_batiment+c),get_percent(parseFloat(cout_acquisition_batiment)+parseFloat(cout_construction_batiment)+parseFloat(montant_equipements_neuf),15)),30);

            // plafond fh2


        }
        if(type_foncier === "Cas de location du bâtiment"){
            var contribution_location = get_min(30,parseFloat(cout_loyer_foncier))*parseFloat(superficier_total_foncier)*24;
                 automobile_offres = find_item_amount('terrain_construction','fh2',automobile_offres,to_million(contribution_location));
                 var plafond_location_a = get_percent(parseFloat(montant_equipements_neuf)+parseFloat(montant_equipements_ocassion),20)+to_million(contribution_location);
                var plafond_location_b = get_percent(parseFloat(montant_equipements_neuf)+to_million(parseFloat(cout_loyer_foncier)*parseFloat(superficier_total_foncier)*72),15);
                    plafond_total = get_min(get_min(plafond_location_a,plafond_location_b),30);


        }



        const fh2_equipement = get_percent(parseFloat(montant_equipements_neuf)+parseFloat(montant_equipements_ocassion),20);


        automobile_offres = find_item_amount('equipement','fh2',automobile_offres,fh2_equipement);

        automobile_offres =  addTotal(automobile_offres,"fh2",plafond_total);
        //Plafond 15% et 30M
        plafond = "Le plafond est le minimum entre 15% et 30M";
        automobile_offres =  addPlafond(automobile_offres,"fh2",plafond);
        automobile_offres = makeItAppears(automobile_offres,"fh2");

    }


    //#fh2 end
    return automobile_offres;
}