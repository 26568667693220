import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'reactstrap';
import Icon from "@material-ui/core/Icon/Icon";
import 'date-fns';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import i18n from "../../../../helpers/i18n";
import {Link} from "react-router-dom";
import LoadingContent from "./LoadingContent";
import PageSection from "../../../components/PageSection";
import { useHistory } from 'react-router-dom';
import {useStoreActions, useStoreState} from "easy-peasy";
export default function MyHistory(props){
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState( new Date('2014-08-18T21:11:54'));
    const {addValue} = useStoreActions(actions => actions);
    const [simulations, setSimulations] = useState([]);
    const [secteurs, setSecteurs] = useState([]);
    const [ecosystems, setEcosystems] = useState([]);
    const loggedInUser = localStorage.getItem('isLogged');
    const {user} = useStoreState(state => state.loggedInUser);
    const handleDateChange = (date) =>{
        setSelectedDate(date);
    }
    const returnRow =(arr,sect) => {
         const row = arr.find((elm)=> elm.id === parseInt(sect));
        return row;
    }
    const returnEco =(arr,eco) => {
        const row = arr.find((elm)=> elm.id === parseInt(eco));
        return row.text;
    }
    useEffect(()  =>  {
        async function fetchEcosystems(){
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'ecosystem/api/all')
        }
        async function fetchSecteurs(){
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'secteur/api/all')
        }
        async function fetchSimulation(){
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'estimation/api/by/'+loggedInUser)
        }
        fetchEcosystems().then(res => {
            //setSimulations(res.data.history);

            setEcosystems(res.data.ecosystems);
        });
        fetchSecteurs().then(res => {
            //setSimulations(res.data.history);

            setSecteurs(res.data.secteurs);
        });
        fetchSimulation().then(res => {
            //setSimulations(res.data.history);

            setSimulations(res.data.history);
        });


    },[]);
    const handleExploiter = async (id) => {
        console.log("exploiter "+id);
        await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'details/api/estimation/'+id).then(res => {
            if(res.data.details.length > 0){
                const details = res.data.details;
                details.map((item) => {
                    addValue({name: item.cle,value:item.valeur});
                });
            }
            return history.push('/');
        });


    }
    return <div className="content_wrapper">
        <PageSection
            key={3}
            sectionClass="innerBlocboxSection"
            sectionContainer="innerBlockboxContainer"
            bigTitle="Mes"
            subTitle="simulations"/>
        {simulations.length > 0 && <Row>
            <Col md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker

                        variant="inline"
                        format="dd/mm/yyyy"
                        margin="normal"
                        value={selectedDate}
                        onChange={handleDateChange}
                        id="date-picker-inline"
                        label="De"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    /></MuiPickersUtilsProvider>

            </Col>
            <Col md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker

                        variant="inline"
                        format="dd/mm/yyyy"
                        margin="normal"
                        value={selectedDate}
                        onChange={handleDateChange}
                        id="date-picker-to_inline"
                        label="Au"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    /></MuiPickersUtilsProvider>
            </Col>
        </Row>
        }
        <Row>
            <Col>
                {simulations.length > 0 ?
                <table className="table table-striped margintop40">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Sous Secteur</th>
                        <th scope="col">Ecosystème</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>

                    { simulations.map((si,key) =>(
                        <tr key={'the'+key}>
                            <th scope="row">{si.dateEstimation.split(' ')[0]}</th>
                            <td>{returnRow(secteurs,si.secteur).text}</td>
                            <td>{returnEco(ecosystems,si.ecosystem)}</td>
                            <td><span className="btn" onClick={() => handleExploiter(parseInt(si.id))}><Icon>edit</Icon> Exploiter</span></td>
                        </tr>
                        )
                    )}
                    </tbody>
                </table>
                    : <LoadingContent/>}
            </Col>
        </Row>
    </div>


}