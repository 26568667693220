import {addPlafond, addTotal, find_item_amount, get_min, get_percent, makeItAppears, to_million} from "../functions";

export function naval(secteur,sousSecteur,simulationForm,offres,ecosystems){
    const {
        montant_terain,
        montant_construction,
        mi,
        montant_equipements_neuf,
        nbre_demploi,
        transfert_technologique,
        zones_select,
        environnement,
        infrastructure_externe,
        formation_input,
        montant_divers,ca,ca1,ca2,ca3,
        ecosystem_items,
        frais_notaire,
        frais_enregistrement,
        frais_conservation,
    } = simulationForm
    const ecosystem_selected = parseInt(ecosystem_items);
    var naval_offres = offres.industrie.naval;
    //Offre FDii
    var primeRetenue = 0;
    if(parseInt(mi) >= 50 || parseInt(nbre_demploi) >= 200){
        //plafond 30M
        //30% MI
      /*  var mi30percent = parseInt(mi)*30/100;
        if(mi30percent>30){
            primeRetenue = 30;
        }else{
            primeRetenue = mi30percent;
        }
        plafond ="Plafond de la subvention est 30M";
        naval_offres =  addPlafond(naval_offres,"fdii",plafond);
        total = primeRetenue;
        naval_offres =  addTotal(naval_offres,"fdii",total);
        naval_offres = find_item_amount('prime_retenue','fdii',naval_offres,primeRetenue);
        naval_offres = makeItAppears(naval_offres,"fdii");*/
        const montant_invisted_ecosystem = parseInt(montant_terain) +
            parseInt(montant_construction) +
            parseInt(montant_equipements_neuf);
        var terrain_construction_new_val = parseInt(montant_terain) + parseInt(montant_construction);
        var mipercent = parseInt(montant_invisted_ecosystem) * 20 / 100;
        var terrain_construction_amount = 0;
        if (terrain_construction_new_val < mipercent) {
            terrain_construction_amount = (terrain_construction_new_val* 30) / 100;
            naval_offres = find_item_amount('terrain_construction', 'fdii', naval_offres, terrain_construction_amount );


        } else {
            terrain_construction_amount = (mipercent* 30) / 100;
            naval_offres = find_item_amount('terrain_construction', 'fdii', naval_offres, terrain_construction_amount )

        }
        //Equippement 30%
        const equipements = (parseInt(montant_equipements_neuf) * 30) / 100;
        naval_offres = find_item_amount('equipement', 'fdii', naval_offres, equipements);
        //Total
        var total_ecosystem = equipements + terrain_construction_amount;
        console.log('total eco '+total_ecosystem);
        if(total_ecosystem > 30){
            total_ecosystem = 30;
        }
        naval_offres = addTotal(naval_offres, "fdii", total_ecosystem);
        //Plafond 30M
        var plafond = "Le plafond est de 30M";
        naval_offres = addPlafond(naval_offres, "fdii", plafond);
        naval_offres = makeItAppears(naval_offres, "fdii");
    }
    //Offre FPi
    if(zones_select !== "Franche" && nbre_demploi !== undefined && mi !== undefined && transfert_technologique !== undefined && environnement !== undefined ){
        var getOffre = false;
        if(parseInt(nbre_demploi) >= 250 ||
            parseInt(mi) >= 100 ||
            transfert_technologique.toLowerCase() === "oui" ||
            environnement.toLowerCase() === "oui"
        ){
            //# montant terrain
            const montant_terrain = parseFloat(montant_terain)+to_million(parseFloat(frais_notaire))+to_million(parseFloat(frais_conservation))+to_million(parseFloat(frais_enregistrement));


            var ter = montant_terrain*20/100;

            naval_offres = find_item_amount('terrain_construction','fpi',naval_offres,(montant_terrain*20/100))


            //# montant infra
            var infra = 0;
            if(parseFloat(infrastructure_externe) > 0){
                infra = get_min(parseFloat(infrastructure_externe),get_percent(parseFloat(mi),5));
                naval_offres = find_item_amount('infrastructure_externe','fpi',naval_offres,infra);
            }
            //# montant formationformation_input
            const montant_formation = parseInt(formation_input);
            var form = montant_formation*20/100;

            naval_offres = find_item_amount('formation','fpi',naval_offres,(montant_formation*20/100))


            //Total
            var total = ter + infra + form;
            //Plafond 5%
            var plafond = "Le plafond est de 5% MI";
            if(zones_select === "Rurale"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Suburbaine"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Urbaine"){

                if(total > parseInt(mi)*5/100){
                    total = parseInt(mi)*5/100;
                }
                plafond = "Le plafond est de 5% MI";
            }
            naval_offres =  addTotal(naval_offres,"fpi",total);
            naval_offres =  addPlafond(naval_offres,"fpi",plafond);
            if(total !== 0){
                naval_offres = makeItAppears(naval_offres,"fpi");
            }

        }
    }
    //Maroc PME
    const pme_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) < 200 && parseInt(el) > 10);
    if(pme_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*20)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        naval_offres = find_item_amount('equipement','maroc_pme',naval_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*20/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        naval_offres = find_item_amount('terrain_construction','maroc_pme',naval_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        if(the_total > 10){
            the_total = 10;
        }
        naval_offres = find_item_amount('divers','maroc_pme',naval_offres,montant_assigned);
        naval_offres =  addTotal(naval_offres,"maroc_pme",the_total);
        //Plafond 30M
        var plafond = "Le plafond est de: 10M";
        naval_offres =  addPlafond(naval_offres,"maroc_pme",plafond);
        naval_offres = makeItAppears(naval_offres,"maroc_pme");
    }
    const tpe_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) <= 10);
    if(tpe_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*30)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        naval_offres = find_item_amount('equipement','maroc_tpe',naval_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*30/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        naval_offres = find_item_amount('terrain_construction','maroc_tpe',naval_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        naval_offres = find_item_amount('divers','maroc_tpe',naval_offres,montant_assigned);
        //Plafond 2M
        if(the_total > 2){
            the_total = 2;
        }
        naval_offres =  addTotal(naval_offres,"maroc_tpe",the_total);
        //Plafond 2M
        var plafond = "Le plafond est de: 2M";
        naval_offres =  addPlafond(naval_offres,"maroc_tpe",plafond);
        naval_offres = makeItAppears(naval_offres,"maroc_tpe");
    }

    return naval_offres;
}