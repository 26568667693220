import React, {useEffect, useState} from 'react';
import Navbar from "./Navbar";
import SidebarSubscriber  from "./SidebarSubscriber";
import Sidebar from "./Sidebar";
import MyAccount from "./bodyContents/MyAccount";
import MyHistory from "./bodyContents/MyHistory";
import SubscriberHome from "./bodyContents/SubscriberHome";
import UserProfil from "./bodyContents/UserProfil";
import SimulationTable from "./bodyContents/SimulationTable";
import UserList from "./bodyContents/UserList";
import PagesList from "./bodyContents/PagesList";
import ContactArchive from "./bodyContents/ContactArchive";
import {useStoreState} from "easy-peasy";
import axios from "axios";
import MySnackbar from "../../components/MySnackbar";
import SinglePage from "./bodyContents/SinglePage";
export default function Dashboard(props) {
    /**
     * Get subpage from url
     */
    const {subpage,id,slug} = props.match.params
    const [simulations, setSimulations] = useState([]);
    const {isLogged} = useStoreState(state => state.user);
    const {user} = useStoreState(state => state.loggedInUser);
    const loggedInUser = localStorage.getItem('isLogged');


    const renderPage = (page,id) => {

            switch (page) {
                case 'compte':
                    return <MyAccount/>
                case 'simulations':
                    return <MyHistory/>
                case 'utilisateurs':
                    return <UserList title="Utilisateurs"/>
                case 'edition':
                    return <SimulationTable/>
                case 'pages':
                    return slug !== undefined ? <SinglePage slug={slug}/> : <PagesList/>
                case 'profil':
                    if(id !== undefined){
                        return <UserProfil user={id}/>
                    }
                case 'contactarchive':
                    return <ContactArchive/>

            }

    }
    return (
        <div className="wrapper">
            <nav id="sidebar">
                <div className="sidebar-header">
                    <a title="" href="/"><img src="/imgs/logo.png" /></a>
                </div>

                <div className="searchbox">
                    <label className="user_avatar">{user.nom}</label>
                </div>
                <div className="sidebar_title">

                </div>
            {user.role === "ROLE_SUBSCRIBER"? <SidebarSubscriber current={subpage}/> : user.role === "ROLE_ADMIN" ? <Sidebar current={subpage}/> : null}
            </nav>
            <div id="content">
                <Navbar/>
                <div className="inner_content">

                    {renderPage(subpage,id)}
                </div>
            </div>
            <MySnackbar/>
        </div>
    );
}
