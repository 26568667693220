import React,{useEffect} from "react";
import './App.css';
import './stylesheets/responsive.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    HashRouter
} from "react-router-dom";
import {PrivateRoute} from './helpers/PrivateRoute';
import { createBrowserHistory } from 'history';
import Result from './layouts/pages/Result';
import Home from './layouts/pages/Home';
import Dashboard from './layouts/pages/dashboard/Dashboard';
import UserProfil from './layouts/pages/dashboard/bodyContents/UserProfil';
import SinglePage from './layouts/pages/SinglePage';
import { withTranslation } from 'react-i18next';
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import { TitleComponent } from './layouts/components/TitleComponent';
function App() {
    //Titling section
    const withTitle = ({ component: Component, title }) => {
        return class Title extends Component {
            render() {
                return (
                    <React.Fragment>
                        <TitleComponent title={title} />
                        <Component {...this.props} />
                    </React.Fragment>
                );
            }
        };
    };
    
    // Titling section
    // is already connected
    const isLoggedIn = localStorage.getItem('isLogged');
    const {isLogged} = useStoreState(state => state.user);
    const {setLogged,setLoggedInUser} = useStoreActions(actions => actions);

    useEffect(() => {
        if(isLoggedIn !== null){
            setLogged({isLogged: !isLogged,id:isLoggedIn});
            axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'investisseur/profile/'+isLoggedIn)
                .then(res => {
                    const loggedInUser = res.data;
                    setLoggedInUser(loggedInUser);
                })
        }
    },[]);
    //end already connected
    return (

                <Router  history={createBrowserHistory()}>
                    <div>
                        <Switch>
                            <HashRouter>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/result" component={Result} />
                                <PrivateRoute path="/dashboard" component={Dashboard} />
                                <PrivateRoute path="/investisseur/:subpage" component={Dashboard} />
                                <PrivateRoute path="/administrateur/:subpage/:slug?" component={Dashboard} />
                                <PrivateRoute path="/utilisateurs/:subpage/:id" component={Dashboard} />
                                <Route exact path="/industrie/:id" component={SinglePage} />
                                <Route exact path="/offres/:id" component={SinglePage} />
                            </HashRouter>
                        </Switch>
                    </div>
                </Router>


    );
}

export default withTranslation('common')(App);