import React,{ useState }  from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker
} from '@material-ui/pickers';


import { useStoreActions,useStoreState } from "easy-peasy";
export default function CdatePicker(props){

    const{id_selector,defaultValue} = props
    const{label_title} = props
    const [selectedDate, setSelectedDate] = useState();
    const {addValue} = useStoreActions(actions => actions);

    addValue({name: id_selector, value: selectedDate});

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    label={label_title}
                    variant="inline"
                    format="dd/MM/yyyy"
                    defaultValue={defaultValue}
                    value={selectedDate}
                    onChange={date => setSelectedDate(date)}
                />
            </MuiPickersUtilsProvider>
        </div>



    );
}