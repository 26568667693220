import React, {useState, useEffect } from 'react';
import {FormControl,FormHelperText,InputLabel,Select,makeStyles} from '@material-ui/core';
import {useStoreActions} from "easy-peasy";
export default function CdropDown(props) {
    const {addValue} = useStoreActions(actions => actions);
    const [optionValue, setOptionValue] = useState(props.defaultValue);
    const  useStyles = makeStyles(theme => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,

        },
    }));
    const [hasError, setHasError] = useState(true)
    const [loading, setLoading] = React.useState(false);
    const handleChange = async (event)  => {
        await addValue({name: event.target.name,value:event.target.value});
        setOptionValue(event.target.value);

        props.onSelectSecteur(event.target.value);

    };
    useEffect(() => {
            const loadData = () => {
                props.onSelectSecteur(props.defaultValue);
            };
            loadData();
        },
        []
    );
    const{title, selector,parentSelect} = props
    return (
        <FormControl className={useStyles.formControl}>
            <InputLabel htmlFor={selector}>{title}</InputLabel>
            <Select  onChange={handleChange}
                     value={optionValue}
                     disabled={parentSelect}
                     inputProps={{
                         name: selector,
                         id: selector,
                     }}>
                {props.children}
            </Select>

        </FormControl>

    );

}
