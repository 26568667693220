import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { action, createStore, StoreProvider, useStore, useActions } from 'easy-peasy';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import model from './helpers/model';

const store = createStore(model);
ReactDOM.render(
    <StoreProvider store={store}>
        <App/>
    </StoreProvider>
      ,
  document.getElementById('root')
);
