import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import i18n from "../../helpers/i18n";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from "formik";
import axios from 'axios';
import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import {useStoreActions, useStoreState} from "easy-peasy";


const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignInForm(){
    const [loader, setLoader] = useState(false);

    const {isLogged} = useStoreState(state => state.user);
    const {user} = useStoreState(state => state.loggedInUser);
    const {setLogged,setLoggedInUser} = useStoreActions(actions => actions);
    const {setSnackbarState} = useStoreActions(actions => actions);
    const {emailError} = useStoreState(state => state);
    const {setEmailError} = useStoreActions(actions => actions);
    const {setPopupLogin} = useStoreActions(actions => actions);
    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, { setSubmitting }) => {
                setLoader(true);
                setTimeout(() => {
                    const formData = new FormData();
                    formData.set('email',values.email);
                    formData.set('password',values.password);

                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_BACKEND_HOSTING_URL+'login',
                        data: formData,
                        headers: {'Content-Type': 'multipart/form-data' }
                    })
                        .then(function (response) {
                            const returnedData = response.data;
                            if(returnedData.result === true){

                                setLoader(false);
                                setLogged({isLogged: !isLogged,id:returnedData.user,role:returnedData.role});
                                //Save id on local storage as token id
                                localStorage.setItem('isLogged', returnedData.user);
                                setEmailError(!emailError);

                                /*
                                * Fetch connected user
                                * */
                                axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'investisseur/profile/'+returnedData.user)
                                    .then(res => {
                                        const loggedInUser = res.data;
                                        setLoggedInUser(loggedInUser);

                                    });

                            }
                            setSnackbarState({state:true,message:i18n.t('login.login_succeded')});
                            setPopupLogin(false);

                        })
                        .catch(function (error) {
                            console.log(error);
                            setLoader(false);
                            setSnackbarState({state:true,message:i18n.t('login.login_failed')})
                        });
                    setSubmitting(false);
                }, 500);

            }}


            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email(i18n.t('login.valid_email'))
                    .required(i18n.t('login.email_required')),
                password: Yup.string()
                    .required(i18n.t('login.password_provided') )
                    .min(8, i18n.t('login.short_password') )
            })}
        >

            {props => {

                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;
                return (

                    <form onSubmit={handleSubmit}>


                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={ i18n.t('login.email') }
                            name="email"
                            autoComplete="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.email && touched.email && "error"}
                        />
                        {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                        )}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={ i18n.t('login.password') }
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.password && touched.password && "error"}
                        />
                        {errors.password && touched.password && (
                            <div className="input-feedback">{errors.password}</div>
                        )}

                            <FormControlLabel
                                fullWidth
                                control={<Checkbox value="remember" color="primary" />}
                                label= { i18n.t('login.rememberme') }
                            />



                        <div className="submit_btn">
                            <Button
                                fullWidth
                                disabled={loader}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {loader === false && i18n.t('login.login') }
                            </Button>
                            {loader && <div className="progress_loader"><CircularProgress  size={24} /></div>}
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
}
