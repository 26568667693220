import {
    addPlafond,
    addTotal,
    find_item_amount,
    makeItAppears,
    textile_ecosys_amount,
    add_item_label,
    get_max,
    get_min,
    offer_title_rename, get_percent, to_million
} from "../functions";

export function textile(secteur,sousSecteur,simulationForm,offres,ecosystems) {
    const {
        montant_terain,
        montant_construction,
        mi,
        frais_assistance_technique,
        montant_equipements_neuf,
        montant_equipements_ocassion,
        nbre_demploi,
        transfert_technologique,
        zones_select,
        environnement,
        infrastructure_externe,
        transfert_mobilisation,
        formation_input,
        montant_divers, ca, ca1, ca2, ca3,
        ecosystem_items,
        frais_innovation_agregateurs_produit_finis,
        frais_dinnovation_pour_operateurs,
        frais_d_innovation_immateriel,
        nature_activite,
        frais_amenagements,
        frais_reseau_distribution,
        frais_notaire,
        frais_enregistrement,
        frais_conservation
    } = simulationForm
    const ecosystem_selected = parseInt(ecosystem_items);
    var textile_offres = offres.industrie.textile;

    if (ecosystem_selected !== undefined) {
        var ecosystem = ecosystems.items.find(el => el.id === ecosystem_selected);
        if (parseInt(nbre_demploi) >= 750 && ecosystem.slug.toLowerCase() === "chaussures_en_cuir" ) {
            var foncier = parseFloat(montant_terain);
            var construction = parseFloat(montant_construction);
            var equipements = parseFloat(montant_equipements_neuf);
            var amenagement = parseFloat(frais_amenagements);
            var mobilisation_expertise = parseFloat(frais_assistance_technique);
            var innovation_creativite = parseFloat(frais_d_innovation_immateriel);
            var reseau_distribution=parseFloat(frais_reseau_distribution);
            var total_invest_industriel = get_min(get_percent((foncier+construction+equipements+amenagement),20),(foncier+construction+amenagement))+equipements+mobilisation_expertise+innovation_creativite+reseau_distribution;

            var e4 = get_min(get_percent((foncier+construction+equipements+amenagement),20),(foncier+construction+amenagement))+equipements;

            var prime = 0;
            prime = get_min(get_percent(total_invest_industriel,30),(get_percent(e4,25) + get_percent(reseau_distribution,7) + get_percent(innovation_creativite,50)+ (get_min(2,get_percent(mobilisation_expertise,70)))));
            prime = get_min(prime,30);
            var plafond = "Le plafond est le minimum entre 30% et 30M";
            textile_offres = addPlafond(textile_offres, "fdii_textile", plafond);
            textile_offres = find_item_amount('prime_retenue', 'fdii_textile', textile_offres, prime);
            textile_offres = addTotal(textile_offres, "fdii_textile", prime);
            textile_offres = add_item_label('prime_retenue', 'fdii_textile', textile_offres, 'Prime globale à l’investissement');
            textile_offres = makeItAppears(textile_offres, "fdii_textile");

            // End Chaussures en cuir
        }
        //  MAROQUINERIE ET VETEMENTS EN CUIR
        if (parseInt(nbre_demploi) >= 500 && ecosystem.slug.toLowerCase() === "maroquinerie_et_vetements_en_cuir") {
            var foncier = parseFloat(montant_terain);
            var construction = parseFloat(montant_construction);
            var equipements = parseFloat(montant_equipements_neuf);
            var amenagement = parseFloat(frais_amenagements);
            var mobilisation_expertise = parseFloat(frais_assistance_technique);
            var innovation_creativite = parseFloat(frais_d_innovation_immateriel);
            var reseau_distribution=parseFloat(frais_reseau_distribution);
            var total_invest_industriel = get_min(get_percent((foncier+construction+equipements+amenagement),20),(foncier+construction+amenagement))+equipements+mobilisation_expertise+innovation_creativite+reseau_distribution;

            var e4 = get_min(get_percent((foncier+construction+equipements+amenagement),20),(foncier+construction+amenagement))+equipements;

            var prime = 0;
            prime = get_min(get_percent(total_invest_industriel,30),(get_percent(e4,25) + get_percent(reseau_distribution,7) + get_percent(innovation_creativite,50)+ (get_min(2,get_percent(mobilisation_expertise,70)))));
            prime = get_min(prime,30);
            var plafond = "Le plafond est le minimum entre 30% et 30M";
            textile_offres = addPlafond(textile_offres, "fdii_textile", plafond);
            textile_offres = find_item_amount('prime_retenue', 'fdii_textile', textile_offres, prime);
            textile_offres = addTotal(textile_offres, "fdii_textile", prime);
            textile_offres = add_item_label('prime_retenue', 'fdii_textile', textile_offres, 'Prime globale à l’investissement');
            textile_offres = makeItAppears(textile_offres, "fdii_textile");
            // End MAROQUINERIE ET VETEMENTS EN CUIR
        }

        //  TANNERIE ET MEGISSERIE
        if (parseInt(nbre_demploi) >= 250 && ecosystem.slug.toLowerCase() === "tannerie_et_megisserie") {
            var foncier = parseFloat(montant_terain);
            var construction = parseFloat(montant_construction);
            var equipements = parseFloat(montant_equipements_neuf);
            var amenagement = parseFloat(frais_amenagements);
            var mobilisation_expertise = parseFloat(frais_assistance_technique);
            var innovation_creativite = parseFloat(frais_d_innovation_immateriel);
            var reseau_distribution=parseFloat(frais_reseau_distribution);
            var total_invest_industriel = get_min(get_percent((foncier+construction+equipements+amenagement),20),(foncier+construction+amenagement))+equipements+mobilisation_expertise+innovation_creativite;

            var e4 = get_min(get_percent((foncier+construction+equipements+amenagement),20),(foncier+construction+amenagement))+equipements;

            var prime = 0;
            prime = get_min(get_percent(total_invest_industriel,30),(get_percent(e4,25) + get_percent(innovation_creativite,50)+ (get_min(2,get_percent(mobilisation_expertise,70)))));
            prime = get_min(prime,30);
            var plafond = "Le plafond est le minimum entre 30% et 30M";
            textile_offres = addPlafond(textile_offres, "fdii_textile", plafond);
            textile_offres = find_item_amount('prime_retenue', 'fdii_textile', textile_offres, prime);
            textile_offres = addTotal(textile_offres, "fdii_textile", prime);
            textile_offres = add_item_label('prime_retenue', 'fdii_textile', textile_offres, 'Prime globale à l’investissement');
            textile_offres = makeItAppears(textile_offres, "fdii_textile");
            // End TANNERIE ET MEGISSERIE
        }
    }
    //Condition FDII Ecosystem
    //===> Fast Fashion current_ecosys = );

    if (ecosystem_selected !== undefined) {
                var ca_isValid20 = [ca].every(el => parseInt(el) > 20);
                var ca_isValid50 = [ca].every(el => parseInt(el) > 50);
                var ecosystem = ecosystems.items.find(el => el.id === ecosystem_selected);
                // Fast Fashion
                if (( parseInt(nbre_demploi) >= 600 && ecosystem.ecosystem.toLowerCase() === "fast fashion") ||
                    ( parseInt(nbre_demploi) >= 450 && ecosystem.ecosystem.toLowerCase() === "denim") ||
                    ( parseInt(nbre_demploi) >= 300 && ecosystem.slug.toLowerCase() === "dimn") ||
                    ( parseInt(nbre_demploi) >= 300 && ecosystem.slug.toLowerCase() === "maille") ||
                    ( parseInt(nbre_demploi) >= 300 && ecosystem.slug.toLowerCase() === "tut") ||
                    ( parseInt(nbre_demploi) >= 300 && ecosystem.slug.toLowerCase() === "textile_de_maison")
                ) {
                          var prime_terrain_construction = 0;
                            var total_vierge = 0;
                            var prime_assistance_technique = 0;
                          var total_eq_terrain = parseFloat(montant_equipements_neuf)+ parseFloat(montant_terain)+parseFloat(montant_construction);
                          if(parseFloat(montant_terain)+parseFloat(montant_construction) > total_eq_terrain*20/100){
                              prime_terrain_construction = total_eq_terrain*20/100;
                              total_vierge = total_eq_terrain*20/100;
                          }else {
                              prime_terrain_construction = (parseFloat(montant_terain)+parseFloat(montant_construction))*20/100;
                              total_vierge = (parseFloat(montant_terain)+parseFloat(montant_construction))*20/100;

                          }
                          prime_terrain_construction = prime_terrain_construction*20/100;
                            var prime_equipement = parseFloat(montant_equipements_neuf)*20/100;
                            total_vierge = total_vierge+ parseFloat(montant_equipements_neuf);
                          //Prime material
                          var prime_material = prime_terrain_construction+prime_equipement;

                            textile_offres = find_item_amount('equipement', 'fdii_ecosystemes_textile', textile_offres, prime_material);
                          //Prime Immaterial
                          var prime_immaterial = (parseFloat(frais_innovation_agregateurs_produit_finis)*50/100)+(parseFloat(frais_dinnovation_pour_operateurs)*80/100);
                             textile_offres = find_item_amount('immaterial', 'fdii_ecosystemes_textile', textile_offres, prime_immaterial);
                            // Prime assistance technique
                            total_vierge = total_vierge + parseFloat(frais_assistance_technique);
                           var expertise_technique = get_percent(parseFloat(frais_assistance_technique),70);
                            if (expertise_technique > 2) {
                                expertise_technique = 2;
                            }
                            prime_assistance_technique = expertise_technique;
                            textile_offres = find_item_amount('transfert_mobilisation', 'fdii_ecosystemes_textile', textile_offres, prime_assistance_technique);

                            //Réseaux distribution
                                var r_distribution = 0;
                               console.log("frais dis out if");
                               console.log(frais_reseau_distribution);
                            if( ecosystem.slug.toLowerCase() === "dimn"){
                                console.log("frais resezu in if");
                                 console.log(frais_reseau_distribution);
                                r_distribution=get_percent(parseFloat(frais_reseau_distribution),7);
                                textile_offres = find_item_amount('reseau_distribution', 'fdii_ecosystemes_textile', textile_offres, r_distribution);
                                total_vierge = total_vierge + parseFloat(frais_reseau_distribution);
                            }
                            // TOTAL
                            var total_general = prime_material+prime_immaterial+prime_assistance_technique+r_distribution;
                            var cond = 0;
                            if(total_vierge > 30){
                                cond = 30;
                            }else{
                                cond = total_vierge;
                            }
                            if(total_general > cond){
                                total_general = cond;
                            }
                            textile_offres = addTotal(textile_offres, "fdii_ecosystemes_textile", total_general);
                            var plafond = "Le plafond est le minimum entre 30% et 30M";
                            textile_offres = addPlafond(textile_offres, "fdii_ecosystemes_textile", plafond);

                            textile_offres = makeItAppears(textile_offres, "fdii_ecosystemes_textile");
                            textile_offres = add_item_label('transfert_mobilisation','fdii_ecosystemes_textile',textile_offres,'Assistance technique');
                            textile_offres = add_item_label('equipement','fdii_ecosystemes_textile',textile_offres,'Prime Investissement matériel');
                            textile_offres = add_item_label('immaterial','fdii_ecosystemes_textile',textile_offres,'Prime Investissement immatériel');
                }
        }

        //Offre FPi
        if (zones_select !== "Franche" && nbre_demploi !== undefined && mi !== undefined && transfert_technologique !== undefined && environnement !== undefined) {
            var getOffre = false;
            var total = 0;
            if (parseInt(nbre_demploi) >= 250 ||
                parseInt(mi) >= 100 ||
                transfert_technologique.toLowerCase() === "oui" ||
                environnement.toLowerCase() === "oui"
            ) {
                //# montant terrain
                var ter = 0;
                if(parseInt(montant_terain) > 0){
                    const montant_terrain = parseFloat(montant_terain)+to_million(parseFloat(frais_notaire))+to_million(parseFloat(frais_conservation))+to_million(parseFloat(frais_enregistrement));

                    ter = montant_terrain * 20 / 100;

                    textile_offres = find_item_amount('terrain_construction', 'fpi', textile_offres, (montant_terrain * 20 / 100))
                }



                //# montant infra
                const montant_infra_externe = parseInt(infrastructure_externe);
                var infra = 0;
                if(parseInt(infrastructure_externe) > 0){
                    var mi5 = parseInt(mi) * 5 / 100;
                    if (montant_infra_externe > mi5) {
                        infra = mi5;
                        textile_offres = find_item_amount('infrastructure_externe', 'fpi', textile_offres, infra)
                    } else {
                        infra = montant_infra_externe;
                        textile_offres = find_item_amount('infrastructure_externe', 'fpi', textile_offres, infra)
                    }
                }


                //# montant formation

                var form = 0;
                if(parseInt(formation_input) > 0){
                    const montant_formation = parseInt(formation_input);
                    form = montant_formation * 20 / 100;

                    textile_offres = find_item_amount('formation', 'fpi', textile_offres, (montant_formation * 20 / 100));
                }



                //Total
                var total = ter + infra + form;
                //Plafond 5%
                var plafond = "Le plafond est de 5% MI";
                if (zones_select === "Rurale") {

                    if (total > parseInt(mi) * 10 / 100) {
                        total = parseInt(mi) * 10 / 100;
                    }
                    plafond = "Le plafond est de 10% MI";
                }
                if (zones_select === "Suburbaine") {

                    if (total > parseInt(mi) * 10 / 100) {
                        total = parseInt(mi) * 10 / 100;
                    }
                    plafond = "Le plafond est de 10% MI";
                }
                if (zones_select === "Urbaine") {

                    if (total > parseInt(mi) * 5 / 100) {
                        total = parseInt(mi) * 5 / 100;
                    }
                    plafond = "Le plafond est de 5% MI";
                }

                if(total > 0){
                    textile_offres = addTotal(textile_offres, "fpi", total);
                    textile_offres = addPlafond(textile_offres, "fpi", plafond);

                    textile_offres = makeItAppears(textile_offres, "fpi");
                }

            }
        }
        //Maroc PME
        const pme_valid = [ca, ca1, ca2, ca3].every(el => parseInt(el) < 200 && parseInt(el) > 10);
        if (pme_valid) {
            //# Equipement
            var the_total = 0
            const montant_equipements_pme = parseInt(montant_equipements_neuf);
            const mi75 = parseInt(mi) * 75 / 100;
            var montant_assigned = montant_equipements_pme;
            if (montant_equipements_pme > mi75) {
                montant_assigned = mi75;
            } else {
                montant_assigned = montant_equipements_pme;
            }
            montant_assigned = (montant_assigned * 20) / 100;
            console.log(montant_assigned);
            console.log(montant_equipements_neuf);
            console.log(mi75);
            the_total = the_total + montant_assigned;
            textile_offres = find_item_amount('equipement', 'maroc_pme', textile_offres, montant_assigned);
            //# terrain_construction
            const mi20 = parseInt(mi) * 20 / 100;
            const terrain_construction = parseInt(montant_terain) + parseInt(montant_construction);
            montant_assigned = terrain_construction;
            if (terrain_construction > mi20) {
                montant_assigned = mi20;
            } else {
                montant_assigned = terrain_construction;
            }
            montant_assigned = (montant_assigned * 20) / 100;
            the_total = the_total + montant_assigned;
            textile_offres = find_item_amount('terrain_construction', 'maroc_pme', textile_offres, montant_assigned);
            //# Divers montant_divers
            const mi5 = parseInt(mi) * 5 / 100;
            const montant_divers_added = parseInt(montant_divers);
            montant_assigned = montant_divers_added;
            if (montant_divers_added > mi5) {
                montant_assigned = mi5;
            } else {
                montant_assigned = montant_divers_added;
            }
            montant_assigned = (montant_assigned * 20) / 100;
            the_total = the_total + montant_assigned;
            if (the_total > 10) {
                the_total = 10;
            }
            textile_offres = find_item_amount('divers', 'maroc_pme', textile_offres, montant_assigned);
            textile_offres = addTotal(textile_offres, "maroc_pme", the_total);
            //Plafond 30M
            var plafond = "Le plafond est de: 10M";
            textile_offres = addPlafond(textile_offres, "maroc_pme", plafond);
            textile_offres = makeItAppears(textile_offres, "maroc_pme");
        }

        const tpe_valid = [ca, ca1, ca2, ca3].every(el => parseInt(el) <= 10);
        if (tpe_valid) {
            //# Equipement
            var the_total = 0
            const montant_equipements_pme = parseInt(montant_equipements_neuf);
            const mi75 = parseInt(mi) * 75 / 100;
            var montant_assigned = montant_equipements_pme;
            if (montant_equipements_pme > mi75) {
                montant_assigned = mi75;
            } else {
                montant_assigned = montant_equipements_pme;
            }
            montant_assigned = (montant_assigned * 30) / 100;
            console.log(montant_assigned);
            console.log(montant_equipements_neuf);
            console.log(mi75);
            the_total = the_total + montant_assigned;
            textile_offres = find_item_amount('equipement', 'maroc_tpe', textile_offres, montant_assigned);
            //# terrain_construction
            const mi20 = parseInt(mi) * 30 / 100;
            const terrain_construction = parseInt(montant_terain) + parseInt(montant_construction);
            montant_assigned = terrain_construction;
            if (terrain_construction > mi20) {
                montant_assigned = mi20;
            } else {
                montant_assigned = terrain_construction;
            }
            montant_assigned = (montant_assigned * 30) / 100;
            the_total = the_total + montant_assigned;
            textile_offres = find_item_amount('terrain_construction', 'maroc_tpe', textile_offres, montant_assigned);
            //# Divers montant_divers
            const mi5 = parseInt(mi) * 5 / 100;
            const montant_divers_added = parseInt(montant_divers);
            montant_assigned = montant_divers_added;
            if (montant_divers_added > mi5) {
                montant_assigned = mi5;
            } else {
                montant_assigned = montant_divers_added;
            }
            montant_assigned = (montant_assigned * 30) / 100;
            the_total = the_total + montant_assigned;
            textile_offres = find_item_amount('divers', 'maroc_tpe', textile_offres, montant_assigned);
            //Plafond 2M
            if (the_total > 2) {
                the_total = 2;
            }
            textile_offres = addTotal(textile_offres, "maroc_tpe", the_total);
            //Plafond 2M
            var plafond = "Le plafond est de: 2M";
            textile_offres = addPlafond(textile_offres, "maroc_tpe", plafond);
            textile_offres = makeItAppears(textile_offres, "maroc_tpe");
        }
        //#FH2
        //#fh2 start

      /*  if (parseInt(mi) > 10 && parseInt(montant_equipements_neuf) > 5) {

            var the_total = 0;
            //# acquisation_du_foncier
            const foncier = ((parseInt(montant_terain) + parseInt(montant_construction)) * 10) / 100;

            the_total = the_total + foncier;
            textile_offres = find_item_amount('terrain_construction', 'fh2', textile_offres, foncier);
            //# equipemnt
            const fh2_equipement = (parseInt(montant_equipements_neuf) * 20) / 100;

            the_total = the_total + fh2_equipement;
            textile_offres = find_item_amount('equipement', 'fh2', textile_offres, fh2_equipement);
            console.log('total fh2' + the_total);
            // plafond 15% ou 30μM
            if (the_total > (parseInt(mi) * 15 / 100)) {
                the_total = (parseInt(mi) * 15 / 100);
                if (the_total > 30) {
                    the_total = 30;
                }
            }


            textile_offres = addTotal(textile_offres, "fh2", the_total);
            //Plafond 15% et 30M
            var plafond = "Le plafond est le minimum entre 15% et 30M";
            textile_offres = addPlafond(textile_offres, "fh2", plafond);
            textile_offres = makeItAppears(textile_offres, "fh2");

        }*/
        //#fh2 end
        // Contributi on FIT
        //la nature du projet « en amont » l’offre FIT doit être affichée
        if(nature_activite !== undefined){
            var ecosystem = ecosystems.items.find(el => el.id === ecosystem_selected);

            if(nature_activite === 'En amont' && ( ecosystem.slug !== "tannerie_et_megisserie" && ecosystem.slug !== "maroquinerie_et_vetements_en_cuir" && ecosystem.slug !== "chaussures_en_cuir"  )){
                var fit_amount = parseInt(mi)*20/100;
                textile_offres = find_item_amount('prime_retenue', 'fit', textile_offres, fit_amount);
                textile_offres = addTotal(textile_offres, "fit", fit_amount);
                textile_offres = add_item_label('prime_retenue','fit',textile_offres,'Contribution FIT');
                textile_offres = makeItAppears(textile_offres, "fit");
            }
        }
        return textile_offres;

}