import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import common_fr from "../translations/fr/common";
import common_en from "../translations/en/common";
import common_ar from "../translations/ar/common";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: true, // pratique pour voir les événements dans la console pendant le développement
        lng: 'fr',
        defaultNS: 'common',
        fallbackLng: 'fr',         // language to use
        resources: {
            fr: {
                common: common_fr              // 'common' is our custom namespace
            },
            en: {
                common: common_en
            },
            ar: {
                common: common_ar
            },
        },
        react: {
            wait: true
        },
    });

export default i18n;
