import React, { Component } from 'react';
import {Container, Row, Col, Card, CardTitle, CardBody} from 'reactstrap';
const ContentPage = (props) => {
    return (
        <section  className={props.sectionClass}>

            <Container  className={props.sectionContainer}>
                <Row>
                    <Col>
                        <h3 className="pageTitle">{props.pageTitle}</h3>
                    </Col>
                </Row>
                <Row>
                    {props.children}
                </Row>

            </Container>
        </section>
    );
}
export default ContentPage;