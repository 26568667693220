import React from 'react';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    fabProgress: {
        color: green[500],
        position: 'absolute',
        bottom: 25,
        zIndex: 1,
    }
}));
export default function CircularLoading() {
    const classes = useStyles();


    return (
        <CircularProgress size={20} className={classes.fabProgress}/>
    );
}