import React, {useState, useEffect } from 'react';
import { useStoreActions,useStoreState } from "easy-peasy";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";
import {FormGroup} from "reactstrap";
import {Redirect} from 'react-router';

export default function GetResultBtn(props) {
    const {addOffresAero,addOffresAuto,addOffresNaval,addOffresTextile,addOffresAgro,addOffshoring,addIndustriesDiverses} = useStoreActions(actions => actions);
    const {offres, secteur_children} = props
    const handleClick = e => {
        if(offres !== undefined){
            offres.map((offre) => {
                offre.offre_allowed = false;
              //  offre.items.find(item => item.item_amount = 0 );
               offre.items.map((item) => {
                    item.item_amount = 0;
                });
               if(offre.slug === "fdii_industries"){
                   offre.items = [];
               }
            });
            // initialisation  des tables offres
            if(secteur_children === 2){
                addOffresAero(offres);
            }
            if(secteur_children === 3){
                addOffresAuto(offres);
            }
            if(secteur_children === 4){
                addOffresNaval(offres);
            }
            if(secteur_children === 6){
                addOffresAgro(offres);
            }
            if(secteur_children === 5){
                addOffresTextile(offres);
            }
            if(secteur_children === 7){
                addOffshoring(offres);
            }
            if(secteur_children === 8){
                addIndustriesDiverses(offres);
            }
            return  <Redirect from="/result" to="/" />
        }

    }
    return (
        <Button className="btn_navigation">
            <Link onClick={handleClick} to={props.linksTo}>{props.btnLabel}</Link>
        </Button>
    );
}
