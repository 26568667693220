import React from 'react';
import {Card, CardBody, CardTitle, Col, Container, FormGroup, Row} from "reactstrap";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {Link} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FieldElement from "./FieldElement";

const ExtraFields = (props) => {
    const {field_label,
            field_type,
            selector_id,
            field_option,
            selector_classes,
            selector_placeholder,
            true_value} = props.extraField
    const {defaultValue} = props
    return (
        <Col md={6}>
            <FormGroup>
                <FieldElement
                    field_label={field_label}
                    field_type={field_type}
                    field_option={field_option}
                    selector_id={selector_id}
                    selector_classes={selector_classes}
                    selector_placeholder={selector_placeholder}
                    true_value={true_value}
                    defaultValue={defaultValue}
                    />
            </FormGroup>
        </Col>
    );
}
export default ExtraFields;