import React, { Component } from 'react';
import { Card, CardTitle, CardBody, Row, Col } from 'reactstrap';
import Step1 from './Step1';
import  Step2  from './Step2';
import  Step3  from './Step3';
import StepZilla from "react-stepzilla";
import i18n from "../helpers/i18n";


class SimulationForm extends Component {
  constructor(props) {
    super(props);
    
  }


  render() {
    const steps =
    [
      {name: 'Step1',component: <Step1/>},
      {name: 'Step2',component: <Step2/>},
      {name: 'Step3',component: <Step3/>}
    ];
    return (
    <Row>
        <Col>

          <Card body>
            <CardTitle>
                <h4 className="head_title">{ i18n.t('steps.title') }</h4>
                <h5 className="sub_headtitle">{ i18n.t('steps.subtitle') }</h5>
            </CardTitle>
            <CardBody>
            <StepZilla
                nextButtonText = "Suivant"
                backButtonText = "Précédent"
                nextButtonCls = "btn_navigation btn btn-next"
                backButtonCls = "btn_navigation btn btn-prev"
                prevBtnOnLastStep={true}
                steps={steps}
                hocValidationAppliedTo={[3]}
                showSteps={false} />
            </CardBody>
          </Card>
        </Col>
    </Row>
    
    );
  }
}


export default SimulationForm;