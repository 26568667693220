import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingContent from './LoadingContent';
import ReactPaginate from 'react-paginate';
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";


export default function PagesList(props) {
  const [pages, setPages] = useState();
  const [countPage, setCountPage] = useState(0);
  const [loadingPages, setLoadingPages] = useState(false)
    const [page, setPage] = useState(1);
  const perPage = 6;
  useEffect(() => {
    async function fetchPages() {
      return await axios.get(
        process.env.REACT_APP_BACKEND_HOSTING_URL + 'page/allpage'
      );
    }
    fetchPages().then(res => {
      //setSimulations(res.data.history);

      setPages(res.data.details);
      setCountPage(res.data.count);

    });
  }, []);
  const handlePageClick = data => {
      setLoadingPages(true);
    console.log(data);
    const selected = parseInt(data.selected)+1;
    axios
      .get(
        process.env.REACT_APP_BACKEND_HOSTING_URL + 'page/allpage/' + selected
      )
      .then(res => {
        setPages(res.data.details);
        setPage(selected);
          setLoadingPages(false);
      });
  };
    const handleChange = (event, value) => {
        setPage(value);
    };
  return (
    <React.Fragment>
      {pages !== undefined && pages.length > 0  ? (
        <div>
          <table className='table table-striped margintop40'>
            <thead>
              <tr>
                <th scope='col'>Pages</th>
                <th scope='col'></th>
                <th scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {loadingPages === false ? pages.map(page => (
                <tr>
                  <td>{page.title}</td>
                  <td>
                    {' '}
                    <Link to={'/administrateur/pages/' + page.slug}>
                      Modifier
                    </Link>
                  </td>
                </tr>
              )):
                  <tr>
                    <td colSpan={3}>
                        <div className="progress_loader loader_bleu"><CircularProgress  size={24} /></div>
                    </td>
                  </tr>

              }
            </tbody>
          </table>
            <div className={'pagination_container'}>

                    <ReactPaginate
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(countPage/perPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'MuiPagination-ul'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />


            </div>

        </div>
      ) : (
        <LoadingContent />
      )}
    </React.Fragment>
  );
}
