import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import InnerPageSection from '../components/InnerPageSection';
import ContentPage from '../ContentPage';
import axios from 'axios';
import CircularLoading from '../components/CircularLoading';

export default function SinglePage(props) {
  const slug = props.match.params.id;
  const [page, setPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchPage(slug) {
      return await axios.get(
        process.env.REACT_APP_BACKEND_HOSTING_URL + 'page/single/' + slug
      );
    }
    fetchPage(slug).then(res => {
      if (res.data.result === true) {
        setIsLoading(false);
        setPage(res.data.page);
      }
    });
  }, []);

  return (
    <div className='App inner_page'>
      <Header></Header>
      <InnerPageSection
        sectionClass='PageResultSection header_page'
        sectionContainer='resultContainer fluid_container'
        pageTitle={page.title}
        bottomTitle=''
      ></InnerPageSection>
      <ContentPage sectionClass='pageinner' pageTitle={page.title}>
        {isLoading ? (
          <div className='loading_circle'>
            <CircularLoading />
            <h4>Chargement</h4>
          </div>
        ) : (
          <div
            className='col-md-12'
            dangerouslySetInnerHTML={{ __html: page.description }}
          />
        )}
      </ContentPage>
      <Footer />
    </div>
  );
}
