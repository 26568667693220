import React, {useState, useEffect} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../../helpers/i18n";
import {useStoreState} from "easy-peasy";
import {FormGroup,Row,Col} from "reactstrap";
import {Link} from "react-router-dom";
import PageSection from "../../../components/PageSection";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ProfilForm from './ProfilForm';
import PasswordForm from './PasswordForm';
import axios from "axios";
import AccountForm from "./AccountForm";
import UserPassword from "./UserPassword";
export default function UserProfil(props){
    const {user} = props;
    const [userData,setUserData] = useState({});
    const [userLoaded, setUserLoaded] = useState(false);
    useEffect(()=>{
        axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'investisseur/profile/'+user)
            .then(res => {
                console.log(res)

                setUserData(res.data.user);
                setUserLoaded(true);
            });
    },[]);
    return ( <React.Fragment>

        <Row>

            <div className="col-md-8">
                {userLoaded === true &&
                    <ProfilForm user={userData}/>
                }

            </div>
            <div  className="col-md-4">
                {userLoaded === true &&
                <UserPassword user={userData}/>
                }
            </div>
        </Row>
    </React.Fragment>);


}