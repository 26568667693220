import {
    addPlafond,
    addTotal,
    find_item_amount,
    makeItAppears,
    textile_ecosys_amount,
    add_item_label, get_percent,
    offer_title_rename, to_million, get_min
} from "../functions";

export function agroalimentaire(secteur,sousSecteur,simulationForm,offres,ecosystems,metiers_selected) {
    const {
        montant_terain,
        montant_construction,
        mi,
        montant_equipements_neuf,
        nbre_demploi,
        transfert_technologique,
        zones_select,
        environnement,
        infrastructure_externe,
        formation_input,
        montant_divers,ca,ca1,ca2,ca3,
        ecosystem_items,
        eco_system_metier,
        frais_notaire,
        frais_enregistrement,
        frais_conservation,
    } = simulationForm
    const ecosystem_selected = parseInt(ecosystem_items);
    var agroalimentaire_offres = offres.industrie.agroalimentaire;
    const metiers_list = metiers_selected.items;
    //Offre FPi
    if(zones_select !== "Franche" && nbre_demploi !== undefined && mi !== undefined && transfert_technologique !== undefined && environnement !== undefined ){
        var getOffre = false;
        if(parseInt(nbre_demploi) >= 250 ||
            parseInt(mi) >= 100 ||
            transfert_technologique.toLowerCase() === "oui" ||
            environnement.toLowerCase() === "oui"
        ){
            //# montant terrain
            const montant_terrain = parseFloat(montant_terain)+to_million(parseFloat(frais_notaire))+to_million(parseFloat(frais_conservation))+to_million(parseFloat(frais_enregistrement));


            var ter = montant_terrain*20/100;

            agroalimentaire_offres = find_item_amount('terrain_construction','fpi',agroalimentaire_offres,(montant_terrain*20/100))


            //# montant infra
            var infra = 0;
            if(parseFloat(infrastructure_externe) > 0){
                infra = get_min(parseFloat(infrastructure_externe),get_percent(parseFloat(mi),5));
                agroalimentaire_offres = find_item_amount('infrastructure_externe','fpi',agroalimentaire_offres,infra);
            }
            //# montant formationformation_input
            const montant_formation = parseInt(formation_input);
            var form = montant_formation*20/100;

            agroalimentaire_offres = find_item_amount('formation','fpi',agroalimentaire_offres,(montant_formation*20/100))


            //Total
            var total = ter + infra + form;
            //Plafond 5%
            var plafond = "Le plafond est de 5% MI";
            if(zones_select === "Rurale"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Suburbaine"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Urbaine"){

                if(total > parseInt(mi)*5/100){
                    total = parseInt(mi)*5/100;
                }
                plafond = "Le plafond est de 5% MI";
            }
            agroalimentaire_offres =  addTotal(agroalimentaire_offres,"fpi",total);
            agroalimentaire_offres =  addPlafond(agroalimentaire_offres,"fpi",plafond);
            if(total > 0){
                agroalimentaire_offres = makeItAppears(agroalimentaire_offres,"fpi");
            }


        }
    }
    //End offre FPI

    //Maroc PME
    const pme_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) < 200 && parseInt(el) > 10);
    if(pme_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*20)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        agroalimentaire_offres = find_item_amount('equipement','maroc_pme',agroalimentaire_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*20/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        agroalimentaire_offres = find_item_amount('terrain_construction','maroc_pme',agroalimentaire_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        if(the_total > 10){
            the_total = 10;
        }
        agroalimentaire_offres = find_item_amount('divers','maroc_pme',agroalimentaire_offres,montant_assigned);
        agroalimentaire_offres =  addTotal(agroalimentaire_offres,"maroc_pme",the_total);
        //Plafond 30M
        var plafond = "Le plafond est de: 10M";
        agroalimentaire_offres =  addPlafond(agroalimentaire_offres,"maroc_pme",plafond);
        agroalimentaire_offres = makeItAppears(agroalimentaire_offres,"maroc_pme");
    }
    //Maroc TPE
    const tpe_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) <= 10);
    if(tpe_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*30)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        agroalimentaire_offres = find_item_amount('equipement','maroc_tpe',agroalimentaire_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*30/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        agroalimentaire_offres = find_item_amount('terrain_construction','maroc_tpe',agroalimentaire_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        agroalimentaire_offres = find_item_amount('divers','maroc_tpe',agroalimentaire_offres,montant_assigned);
        //Plafond 2M
        if(the_total > 2){
            the_total = 2;
        }
        agroalimentaire_offres =  addTotal(agroalimentaire_offres,"maroc_tpe",the_total);
        //Plafond 2M
        var plafond = "Le plafond est de: 2M";
        agroalimentaire_offres =  addPlafond(agroalimentaire_offres,"maroc_tpe",plafond);
        agroalimentaire_offres = makeItAppears(agroalimentaire_offres,"maroc_tpe");
    }
    //FDII Industries Agroalimentaires
    // ca was 200
    if(parseInt(ca) >= 200) {
        var prime_retenue = 0;
        var plafond = "";
        var ecosystem = ecosystems.items.find(el => el.id === ecosystem_selected);
        if (ecosystem.slug === "industrie_des_fruits_et_legumes_transformees") {
            if (eco_system_metier !== undefined) {
                var metier = metiers_list.find(el => el.id === parseInt(eco_system_metier));
                if (metier.slug === "unite_de_transformation_de_produits_fort_potentiel" && parseFloat(mi) <= 10) {
                    if (parseFloat(mi) * 20 / 100 > 2) {
                        prime_retenue = 2;
                    } else {
                        prime_retenue = parseFloat(mi) * 20 / 100;
                    }
                    plafond = "plafond est 2M";
                }
                if (metier.slug === "unite_de_transformation_de_produits_fort_potentiel" && parseFloat(mi) <= 75) {
                    if (parseFloat(mi) * 20 / 100 > 15) {
                        prime_retenue = 15;
                    } else {
                        prime_retenue = parseFloat(mi) * 20 / 100;
                    }
                    plafond = "plafond est 15M";
                }
                if (metier.slug === "unite_de_transformation_de_produits_fort_potentiel" && parseFloat(mi) > 75) {
                    if (parseFloat(mi) * 20 / 100 > 20) {
                        prime_retenue = 20;
                    } else {
                        prime_retenue = parseFloat(mi) * 20 / 100;
                    }
                    plafond = "plafond est 20M";
                }
                if (metier.slug === "unite_decrasement_legumes") {
                    if (parseFloat(mi) * 30 / 100 > 30) {
                        prime_retenue = 30;
                    } else {
                        prime_retenue = parseFloat(mi) * 30 / 100;
                    }
                    plafond = "plafond est 30M";
                }
            }
        }
        if (ecosystem.slug === "industrie_de_huile_olive") {
            if (eco_system_metier !== undefined) {
                var metier = metiers_list.find(el => el.id === parseInt(eco_system_metier));
                if (metier.slug === "creation_et_modernisation_et_accroisement_des_capacites_unite_production_huile_olive") {
                    if (parseFloat(mi) * 10 / 100 > 2) {
                        prime_retenue = 2;
                    } else {
                        prime_retenue = parseFloat(mi) * 10 / 100;
                    }
                    plafond = "plafond est 2M";
                }
                if (metier.slug === "nouveau_projet_relevant_de_industrie_de_huile_olive" && parseFloat(mi) >= 75) {
                    if (parseFloat(mi) * 20 / 100 > 15) {
                        prime_retenue = 15;
                    } else {
                        prime_retenue = parseFloat(mi) * 20 / 100;
                    }
                    plafond = "plafond est 15M";
                }

            }
        }
        if (ecosystem.slug === "industrie_laitiere") {
            if (eco_system_metier !== undefined) {
                var metier = metiers_list.find(el => el.id ===  parseInt(eco_system_metier));
                if (metier.slug === "unite_de_production_des_produits_laitiers_forte_valeur_ajoutee") {

                    if (parseFloat(mi) >= 10) {
                        if (get_percent(parseFloat(mi), 30) > 3) {
                            prime_retenue = 3;
                        } else {
                            prime_retenue = get_percent(parseFloat(mi), 30);
                        }
                        plafond = "plafond est 3M";
                    }
                    if (parseFloat(mi) >= 50) {
                        if (get_percent(parseFloat(mi), 30) > 15) {
                            prime_retenue = 15;
                        } else {
                            prime_retenue = get_percent(parseFloat(mi), 30);
                        }
                        plafond = "plafond est 15M";
                    }
                }

            }

        }
        if (ecosystem.slug === "industrie_de_la_biscuiterie_chocolaterie_confiserie") {
            if (eco_system_metier !== undefined) {
                var metier = metiers_list.find(el => el.id ===  parseInt(eco_system_metier));
                if (metier.slug === "extension_de_creation_unites_de_biscuiterie_chocolaterie_confiserie") {
                    if (get_percent(parseFloat(mi), 20) > 15) {
                        prime_retenue = 15;
                    } else {
                        prime_retenue = get_percent(parseFloat(mi), 20);
                    }
                    plafond = "plafond est 15M";
                }
            }
        }
        if (ecosystem.slug === "industrie_des_pates_et_couscous") {
            if (eco_system_metier !== undefined) {
                var metier = metiers_list.find(el => el.id ===  parseInt(eco_system_metier));
                if (metier.slug === "extension_de_creation_unites_de_production_des_pates_et_couscous") {
                    if (parseFloat(mi) * 20 / 100 > 15) {
                        prime_retenue = 15;
                    } else {
                        prime_retenue = parseFloat(mi) * 20 / 100;
                    }
                    plafond = "plafond est 15M";
                }
                if (metier.slug === "acquisition_de_lignes_de_conditionnement") {
                    if (parseFloat(mi) * 30 / 100 > 1.35) {
                        prime_retenue = 1.35;
                    } else {
                        prime_retenue = parseFloat(mi) * 30 / 100;
                    }
                    plafond = "plafond est 1.35M";
                }
            }
        }
        if (ecosystem.slug === "industrie_des_viandes") {
            //ProgrammeIMTIAZdel’ NPME ???????
        }
        if (prime_retenue > 0) {
            agroalimentaire_offres = find_item_amount('prime_retenue', 'fdii', agroalimentaire_offres, prime_retenue);
            agroalimentaire_offres = makeItAppears(agroalimentaire_offres, "fdii");
            agroalimentaire_offres = addTotal(agroalimentaire_offres, "fdii", prime_retenue);
            agroalimentaire_offres = addPlafond(agroalimentaire_offres, "fdii", plafond);
        }
    }
    return agroalimentaire_offres;
}