import {
    addPlafond,
    addTotal,
    find_item_amount,
    makeItAppears,
     get_percent,
    to_million, get_min, getIsIr,getRbiByEco,getInputsOffshoring,contributionIr,emploies_salares
} from "../functions";
export function offshoring(secteur,sousSecteur,simulationForm,offres,ecosystems,metiers_selected) {
    const {
        montant_terain,
        montant_construction,
        mi,
        montant_equipements_neuf,
        nbre_demploi,
        formation_input,
        montant_divers,ca,ca1,ca2,ca3,
        ecosystem_items,
        ca_export_services_premier_deuxieme,
        offshore_taux_reduit,
        offshore_resultat_net_fiscal,
        offshore_montant_des_produits_imposable,
        nbre_operateurs,
        salaire_operateurs,
        nbre_administrateurs,
        salaire_administrateurs,
        offshoring_is_ir,
        nbre_techniciens,
        salaire_techniciens,
        nbre_ingenieurs,
        salaire_ingenieurs
    } = simulationForm
    const ecosystem_selected = parseInt(ecosystem_items);
    var offshoring_offres = offres.industrie.offshoring;
    const metiers_list = metiers_selected.items;
    const grades_rbi = {
        nbre_operateurs:parseFloat(nbre_operateurs),
        salaire_operateurs:parseFloat(salaire_operateurs),
        nbre_administrateurs:parseFloat(nbre_administrateurs),
        salaire_administrateurs:parseFloat(salaire_administrateurs),
        nbre_techniciens:parseFloat(nbre_techniciens),
        salaire_techniciens:parseFloat(salaire_techniciens),
        nbre_ingenieurs:parseFloat(nbre_ingenieurs),
        salaire_ingenieurs:parseFloat(salaire_ingenieurs)
    };


    //Maroc PME
    const pme_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) < 200 && parseInt(el) > 10);
    if(pme_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*20)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        offshoring_offres = find_item_amount('equipement','maroc_pme',offshoring_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*20/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        offshoring_offres = find_item_amount('terrain_construction','maroc_pme',offshoring_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        if(the_total > 10){
            the_total = 10;
        }
        offshoring_offres = find_item_amount('divers','maroc_pme',offshoring_offres,montant_assigned);
        offshoring_offres =  addTotal(offshoring_offres,"maroc_pme",the_total);
        //Plafond 30M
        var plafond = "Le plafond est de: 10M";
        offshoring_offres =  addPlafond(offshoring_offres,"maroc_pme",plafond);
        offshoring_offres = makeItAppears(offshoring_offres,"maroc_pme");
    }
    //Maroc TPE
    const tpe_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) <= 10);
    if(tpe_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*30)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        offshoring_offres = find_item_amount('equipement','maroc_tpe',offshoring_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*30/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        offshoring_offres = find_item_amount('terrain_construction','maroc_tpe',offshoring_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        offshoring_offres = find_item_amount('divers','maroc_tpe',offshoring_offres,montant_assigned);
        //Plafond 2M
        if(the_total > 2){
            the_total = 2;
        }
        offshoring_offres =  addTotal(offshoring_offres,"maroc_tpe",the_total);
        //Plafond 2M
        var plafond = "Le plafond est de: 2M";
        offshoring_offres =  addPlafond(offshoring_offres,"maroc_tpe",plafond);
        offshoring_offres = makeItAppears(offshoring_offres,"maroc_tpe");
    }
    // FDII ecosystem
    //ECOSYSTEME CRM
    /* offshore_ir,
        offshore_rbi,
        offshore_taux_reduit,
        offshore_is,
        offshore_resultat_net_fiscal,
        offshore_montant_des_produits_imposable
     */
    var ecosystem = ecosystems.items.find(el => el.id === ecosystem_selected);
    var plafondecosystem = 0;
    var formule_prime_formation = 0;
    const fdii_ecosys = ['crm','bpo','ito','eso','kpo'];
    /**Updates added by eco**/
    if(fdii_ecosys.indexOf(ecosystem.slug) !== -1) {


        switch (ecosystem.slug) {
            case "crm":
                plafondecosystem = 0.02;
                formule_prime_formation =  parseInt(nbre_demploi) * 0.012;
                break;
            case "bpo":
                plafondecosystem = 0.025;
                formule_prime_formation =  parseInt(nbre_demploi) * 0.04;

                break;
            case "ito":
                plafondecosystem = 0.03;
                formule_prime_formation =  parseInt(nbre_demploi) * 0.065;

                break;
            case "eso":
                plafondecosystem = 0.03;
                formule_prime_formation =  parseInt(nbre_demploi) * 0.065;
                break;
            case "kpo":
                plafondecosystem = 0.035;
                formule_prime_formation =  parseInt(nbre_demploi) * 0.065;

                break;

        }
    }
        var fdii_total = 0;
        // Contribution de l'Etat aux frais de la formation
            offshoring_offres = find_item_amount('contribution_aux_frais_de_la_formation','fdii',offshoring_offres,formule_prime_formation);

            fdii_total = fdii_total+formule_prime_formation;
        // end contribution formation
        //Prime à l'Investissement  matériel et immatériel
            var taux = 0.3;
            if(simulationForm.ville ==="Casablanca"){
                taux = 0.2;
            }
            var prime_materiel_immateriel = 0;
            var option_a = taux * parseFloat(mi);
            var option_b = parseInt(nbre_demploi) * plafondecosystem * taux;
            var option_c = 10;
                prime_materiel_immateriel = get_min(option_a,get_min(option_b,option_c));

                offshoring_offres = find_item_amount('prime_materiel_immateriel','fdii',offshoring_offres,prime_materiel_immateriel);
            console.log('prime_materiel_immateriel'+prime_materiel_immateriel);
                if(prime_materiel_immateriel > 0){
                    fdii_total = fdii_total+prime_materiel_immateriel;
                }


        // Total

        console.log("total fdii offshore");
        console.log(fdii_total);
        console.log("end total fdii offshore");
        if(fdii_total > 0){
            offshoring_offres =  addTotal(offshoring_offres,"fdii",fdii_total);
            var plafond = "Non plafonnée";
            offshoring_offres =  addPlafond(offshoring_offres,"fdii",plafond);
            offshoring_offres = makeItAppears(offshoring_offres,"fdii");
        }

    return offshoring_offres;
}