import React from 'react';
import i18n from "../../helpers/i18n";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from 'axios';

function LogoutBox(){
    const {isLogged} = useStoreState(state => state.user);
    const {setLogged} = useStoreActions(actions => actions);
    const handleLogout = () =>{
        axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'logout');
        setLogged(!isLogged);
        localStorage.removeItem('isLogged');
    }
    return  <button className="login login_btn"  onClick={handleLogout} >{ i18n.t('template.logout') } </button>;

}
export default LogoutBox;