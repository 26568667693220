import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import i18n from "../../helpers/i18n";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
    Row,
    Col
} from "reactstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik } from "formik";
import axios from 'axios';
import * as EmailValidator from "email-validator";
import * as Yup from "yup";
import {useStoreActions, useStoreState} from "easy-peasy";


const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function RegisterForm(props){
    const [loader, setLoader] = useState(false);
    const {role,setPassword,label,onHandleClose} = props
    const {isLogged} = useStoreState(state => state.user);
    const {user} = useStoreState(state => state.loggedInUser);
    const {setLogged,setLoggedInUser} = useStoreActions(actions => actions);
    const {setSnackbarState} = useStoreActions(actions => actions);
    const {setPopupLogin} = useStoreActions(actions => actions);
    return (<Formik
        initialValues={{ email: "", usrnom: "", usrprenom:"",password:"" }}
        onSubmit={(values, { setSubmitting }) => {
            setLoader(true);
            setTimeout(() => {
                const formData = new FormData();
                formData.set('email',values.email);
                formData.set('nom',values.usrnom);
                formData.set('prenom',values.usrprenom);
                if(setPassword !== undefined && setPassword === true){
                    formData.set('password',values.password);
                }else{
                    formData.set('password','');
                }
                if(role !== undefined && role === "admin"){
                    formData.set('role',role);
                }else{
                    formData.set('role','');
                }


                axios({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_HOSTING_URL+'register',
                    data: formData,
                    headers: {'Content-Type': 'multipart/form-data' }
                }).then(function (response) {

                    const returnedData = response.data;

                    if(returnedData.result === true){
                        setLoader(false);
                        if(role === undefined && role !== "admin"){
                            setLogged({isLogged: !isLogged,id:returnedData.user,role:returnedData.role});
                            //Save id on local storage as token id
                            localStorage.setItem('isLogged', returnedData.user);

                            axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'investisseur/profile/'+returnedData.user)
                                .then(res => {
                                    const loggedInUser = res.data;
                                    setLoggedInUser(loggedInUser);
                                    setPopupLogin(false);
                                    window.location.reload();
                                });
                        }else{
                            onHandleClose();
                        }


                        setSnackbarState({state:true,message:i18n.t('login.account_created')})
                    }
                }).catch(function (error) {
                    console.log(error);
                    setLoader(false);
                    setSnackbarState({state:true,message:i18n.t('login.error_account_created')})
                });
                setSubmitting(false);
            }, 500);

        }}
        validationSchema={Yup.object().shape({
            email: Yup.string()
                .email(i18n.t('login.valid_email'))
                .required(i18n.t('login.email_required')),
            usrnom: Yup.string().required(i18n.t('login.usrnom_required')),
            usrprenom: Yup.string().required(i18n.t('login.usrprenom_required'))
        })}
        >
        {props => {

            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
            } = props;
            return ( <form onSubmit={handleSubmit}>
                <Row>
                    <Col md={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={ i18n.t('login.email') }
                            name="email"
                            autoComplete="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.email && touched.email && "error"}
                        />
                        {errors.email && touched.email && (
                            <div className="input-feedback">{errors.email}</div>
                        )}
                    </Col>
                    <Col md={6}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="usrnom"
                            label={ i18n.t('login.usrnom') }
                            name="usrnom"
                            autoComplete="usrnom"
                            value={values.usrnom}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.usrnom && touched.usrnom && "error"}
                        />
                        {errors.usrnom && touched.email && (
                            <div className="input-feedback">{errors.usrnom}</div>
                        )}
                    </Col>
                    <Col md={6}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="usrprenom"
                            label={ i18n.t('login.usrprenom') }
                            name="usrprenom"
                            autoComplete="usrprenom"
                            value={values.usrprenom}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.usrprenom && touched.usrprenom && "error"}
                        />
                        {errors.usrprenom && touched.usrprenom && (
                            <div className="input-feedback">{errors.usrprenom}</div>
                        )}
                    </Col>
                </Row>
                    {setPassword !== undefined && role === "admin" && <Row>
                        <Col md={12}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={ i18n.t('login.password') }
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.password && touched.password && "error"}
                        />
                        </Col>
                    </Row>}
                <div className="submit_btn">
                    <Button
                        fullWidth
                        disabled={loader}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        {loader === false && (label!== undefined ? label : i18n.t('login.register')) }
                    </Button>
                    {loader && <div className="progress_loader"><CircularProgress  size={24} /></div>}
                </div>
            </form>)
        }}
        </Formik>
    )

}