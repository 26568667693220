import React from 'react';

const TwoLine = (props) => {
    return (
        <div className="twoline">
            <h5>{props.first}</h5>
            <h4>{props.second}</h4>
        </div>
    );
}
export default TwoLine;