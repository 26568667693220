import React, {useEffect,useState} from 'react';
import i18n from "../../../../helpers/i18n"
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Card, CardBody, CardTitle} from "reactstrap";
import Grid from "@material-ui/core/Grid/Grid";
import {useStoreActions, useStoreState} from "easy-peasy";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Fab from "@material-ui/core/Fab/Fab";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import RegisterForm from "../../../components/RegisterForm";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #5299C7',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function RemovePopUp(props){
    const classes = useStyles();
    const [showIt,setShowIt] = useState(true)
    const [removeCount, setRemoveCount] = useState(0);
    const {currentRole,user} = props
    const {popupLogin} = useStoreState(state => state);
    const {setPopupLogin} = useStoreActions(actions => actions);
    const {setSnackbarState} = useStoreActions(actions => actions);
    const handleRemoveUser = () => {
            const id = user;
       // props.onClickToDelete(id);
        if(id !== undefined){
            axios.post(process.env.REACT_APP_BACKEND_HOSTING_URL+id+'/delete')
                .then(res => {
                    if(res.data.result === true){
                        setSnackbarState({state:true,message:i18n.t('user.account_deleted')})
                        setShowIt(false);
                        props.onRemoveUser(id);
                    }else{
                        setSnackbarState({state:true,message:i18n.t('user.account_not_deleted')})
                        setShowIt(false);
                    }
                });
        }




      /*  axios
            .delete(process.env.REACT_APP_BACKEND_HOSTING_URL+id+'/delete')
            .then(res => {
                if(res.data.result === true){
                    setSnackbarState({state:true,message:i18n.t('user.account_deleted')})
                    setShowIt(false);
                }else{
                    setSnackbarState({state:true,message:i18n.t('user.account_not_deleted')})
                    setShowIt(false);
                }
            })
            .catch(err => {
                setSnackbarState({state:true,message:i18n.t('user.account_error_accured')})
                setShowIt(false);
            });

        */
        console.log(user);
    };

    const handleCancel = () => {
        console.log('remove');
        setShowIt(false);
    };
    return  (<React.Fragment>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showIt}
                onClose={handleCancel}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showIt}>
                    <div className="modal_container">
                        <Card body>
                            <CardTitle>
                                <div className="modal_title_holder">
                                    <h4 className="head_title">{ i18n.t('template.wouldremoveuser') }</h4>
                                    <h5 className="sub_headtitle">{ i18n.t('template.removetheuser') }</h5>
                                </div>

                            </CardTitle>
                            <CardBody className="pageHeadDescription">
                                <Grid className="btngrouppopup" container>
                                    <Button variant="contained" color="primary"  onClick={handleRemoveUser} className="removeBtn">
                                        Supprimer
                                    </Button>

                                    <Button variant="contained"  onClick={handleCancel} className="cancelBtn">Annuler</Button>

                                </Grid>
                            </CardBody>
                        </Card>
                    </div>


                </Fade>
            </Modal>
        </React.Fragment>
    );

}
