import React, {useState, useEffect } from 'react';
import { useStoreActions,useStoreState } from "easy-peasy";
import {Col, Table} from "reactstrap";
import {round} from "../../helpers/functions";


export default function Offre(props) {

    var i = 0;
    return (
        <Col md={props.columnMd}>
            <Table  className="table_result" striped>
                <thead>
                <tr>

                    <th colSpan={2}  className="table_title"><div dangerouslySetInnerHTML={{__html : props.offre_name }} /></th>
                </tr>
                </thead>
                <tbody>
                {

                    props.offre_items.map((item,key) => (
                        (item.item_label !== '' && (item.item_amount > 0 || item.item_amount < 0) &&
                            <tr key={key} >
                                <td>
                                {item.item_label !== '' && item.item_amount ?

                                    <span className="circle_icon">{i = i + 1}</span> : null
                                }

                                {item.item_amount > 0 || item.item_amount < 0 ?
                                    <>
                                        <label>{item.item_label}</label>
                                        <span
                                            className="offer_td_span_desc">{item.description !== undefined ? item.description : null}</span>
                                    </>

                                    : null}

                                </td>
                                <td className="td_value">   {item.item_amount > 0 || item.item_amount < 0 ? round(item.item_amount, 2) : null}
                                {item.item_label !== '' && item.item_amount > 0 || item.item_amount < 0 ?
                                    <span>{"M"}</span> : null
                                }
                                </td>
                            </tr>
                        )
                    ))
                }

                    <tr>
                        <td className="label_total">{props.total_label}{" "}</td>
                        <td className="td_total">{props.total_amount > 0 ? props.total_amount : 0 }M</td>
                    </tr>

                <tr>

                    <td colSpan={2} className="final_result">{props.finalResult}</td>
                </tr>
                </tbody>
            </Table>
        </Col>
    );
}
