import React, {useState,useEffect} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../../helpers/i18n";
import {useStoreState} from "easy-peasy";
import {FormGroup} from "reactstrap";
import {Link} from "react-router-dom";
import LoadingContent from "./LoadingContent";
import UsersTable from "./UsersTable";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PageSection from "../../../components/PageSection";
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import { Button } from 'reactstrap';
import axios from "axios";
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});
export default function UserList(props){
    const classes = useStyles();
    const [role, setRole] = useState('ROLE_SUBSCRIBER');
    const [users, setUsers] = useState([]);
    const [loading,setLoading] = useState(false);
    const handleChange = async (type_user) => {
        setLoading(true);
        setRole(type_user);
        await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'users/'+type_user).then((res)=>{
            setUsers(res.data.result);
            console.log(users);
            setLoading(false);
        });
    };
    //const {user} = useStoreState(state => state.loggedInUser);
    const {title} = props
    useEffect(()=>{
        setLoading(true);
        async function fetchUsers(){
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'users/ROLE_SUBSCRIBER');
        }
        fetchUsers().then((res)=>{
            setUsers(res.data.result);
            console.log(users);
            setLoading(false);
        });

    },[]);
    return <div className="content_wrapper">
            <PageSection
                key={3}
                sectionClass="innerBlocboxSection"
                sectionContainer="innerBlockboxContainer"
                bigTitle="Liste des"
                subTitle={title}/>
        <Card>
            {!loading &&
            <CardActions>
                <span className={role === 'ROLE_SUBSCRIBER' ? 'btn btn-primary' : 'btn'} color="primary"
                      onClick={() => handleChange("ROLE_SUBSCRIBER")}>
                    Investisseurs
                </span>
                <span className={role === 'ROLE_ADMIN' ? 'btn btn-primary' : 'btn'} color="primary"
                      onClick={() => handleChange("ROLE_ADMIN")}>
                    Administrateurs
                </span>
            </CardActions>
            }
            <CardContent>
                {loading === false ? <UsersTable currentRole={role} users={users}/> : <LoadingContent/>}
            </CardContent>
        </Card>
        </div>


}