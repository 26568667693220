import React, {useEffect} from 'react';
import i18n from "../../../../helpers/i18n"
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Card, CardBody, CardTitle} from "reactstrap";
import Grid from "@material-ui/core/Grid/Grid";
import {useStoreActions, useStoreState} from "easy-peasy";
import Fab from "@material-ui/core/Fab/Fab";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import RegisterForm from "../../../components/RegisterForm";
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #5299C7',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));
//New user
export default function NewUser(props){
    const classes = useStyles();
    const {currentRole} = props
    const {popupLogin} = useStoreState(state => state);
    const {setPopupLogin} = useStoreActions(actions => actions);
    const handleClickAdd = () => {
        console.log('clicked');

        setPopupLogin(true);
    };

    const handleClose = () => {
        setPopupLogin(false);
    };
    return  (<React.Fragment>

            {currentRole === "ROLE_ADMIN" && <div className="floating_btn">
                    <Fab color="primary" aria-label="add"  onClick={handleClickAdd}>
                        +
                    </Fab>
                </div> }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={popupLogin.state}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={popupLogin.state}>
                    <div className="modal_container">
                        <Card body>
                            <CardTitle>
                                <div className="modal_title_holder">
                                    <h4 className="head_title">{ i18n.t('template.add') }</h4>
                                    <h5 className="sub_headtitle">{ i18n.t('template.newsuser_title') }</h5>
                                </div>

                            </CardTitle>
                            <CardBody className="pageHeadDescription">
                                <Grid container>
                                    <RegisterForm role={"admin"}  setPassword={true} label={ i18n.t('template.add')} onHandleClose={handleClose}/>
                                </Grid>
                            </CardBody>
                        </Card>
                    </div>


                </Fade>
            </Modal>
    </React.Fragment>
    );

}
