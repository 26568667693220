import React, {useState, useEffect } from 'react';
import { useStoreActions,useStoreState } from "easy-peasy";
import {Col, Table} from "reactstrap";
import {round} from "../../helpers/functions";


export default function Offre2(props) {
    console.log("offre props");
    console.log(props.offre.items[0]);

    var i = 0;
    return (
        <Col md={props.columnMd}>
            <Table  className="table_result" striped>
                <thead>
                <tr>

                    <th colSpan={3}  className="table_title"><div dangerouslySetInnerHTML={{__html : props.offre_name }} /></th>
                </tr>
                <tr>
                    <th>
                        Projet
                    </th>
                    <th>
                        Prime retenue
                    </th>
                    <th>
                        Plafond
                    </th>
                </tr>
                </thead>
                <tbody>
                {

                    props.offre.items[0].map((item,key) => (

                        <tr key={key} className="centered_text">
                           <td>{item.label}</td>
                            <td>{item.value} MAD</td>
                            <td>{item.plafond} MAD</td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </Col>
    );
}
