import React, { Component } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import HomeSlider from '../HomeSlider';
import SimulationForm from '../SimulationForm';
import { Container, Row, Col } from 'reactstrap';
import PageSection from '../components/PageSection';
import GridItem from '../components/GridItem';
import ContactForm from '../components/ContactForm';
import TwoLine from '../components/TwoLine';
import Lesteppers from '../components/Lesteppers';
import i18n from '../../helpers/i18n';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from 'react-router-dom';
class Home extends Component {
  /*   constructor(props) {
         super(props);
         this.state = {
           lang: options[0],
         };
       }

   changeLang = (lang) => {
     const { i18n } = this.props;
     const { value } = lang;
     this.setState({
       lang,
     });
     i18n.changeLanguage(value);
   };*/
  constructor(props) {
    super(props);
    this.state = {
      gridItems: [
        {
          id: 1,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/automobile.png',
          gridAvatarHover: './imgs/secteurs/automobile_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: 'Automobile',
          gridTitle: 'Automobile',
          gridText: '',
          gridLink: '/industrie/automobile',
        },
        {
          id: 2,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/aeronautique.png',
          gridAvatarHover: './imgs/secteurs/aeronautique_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Aéronautique',
          gridText: '',
          gridLink: '/industrie/aeronautique',
        },
        {
          id: 3,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/textile.png',
          gridAvatarHover: './imgs/secteurs/textile_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Textile',
          gridText: '',
          gridLink: '/industrie/textile',
        },
        {
          id: 4,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/cuir.png',
          gridAvatarHover: './imgs/secteurs/cuir_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Cuir',
          gridText: '',
          gridLink: '/industrie/cuir',
        },
        {
          id: 5,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/electronique.png',
          gridAvatarHover: './imgs/secteurs/electronique_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Electronique',
          gridText: '',
          gridLink: '/industrie/electronique',
        },
        {
          id: 6,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/electrique.png',
          gridAvatarHover: './imgs/secteurs/electrique_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Electrique',
          gridText: '',
          gridLink: '/industrie/electrique',
        },
        {
          id: 7,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/chimie.png',
          gridAvatarHover: './imgs/secteurs/chimie_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Chimie et Parachimie',
          gridText: '',
          gridLink: '/industrie/chimie',
        },
        {
          id: 8,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/pharmacie.png',
          gridAvatarHover: './imgs/secteurs/pharmacie_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Pharmacie',
          gridText: '',
          gridLink: '/industrie/pharmacie',
        },
        {
          id: 9,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/construction.png',
          gridAvatarHover: './imgs/secteurs/construction_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Materiaux de construction',
          gridText: '',
          gridLink: '/industrie/materieux',
        },
        {
          id: 1,
          avatarClass: 'secteurImage',
          gridAvatar: './imgs/secteurs/offshore.png',
          gridAvatarHover: './imgs/secteurs/offshore_hover.png',
          hoverColor: '#5299C7',
          gridImgAlt: '',
          gridTitle: 'Offshoring',
          gridText: '',
          gridLink: '/industrie/offshoring',
        },
      ],
      gridOffres: [
        {
          id: 1,
          avatarClass: 'offresImage',
          gridAvatar: './imgs/offres/debase.png',
          gridAvatarHover: './imgs/offres/debase_hover.png',
          gridImgAlt: '',
          gridTitle: <TwoLine first='' second='FDII' />,
          gridLink: '/offres/fdii',
        },
        {
          id: 2,
          avatarClass: 'offresImage',
          gridAvatar: './imgs/offres/sectorielle.png',
          gridAvatarHover: './imgs/offres/sectorielle_hover.png',
          gridImgAlt: '',
          gridTitle: <TwoLine first='' second='FHII' />,
          gridLink: '/offres/fhii',
        },
        {
          id: 3,
          avatarClass: 'offresImage',
          gridAvatar: './imgs/offres/territoriale.png',
          gridAvatarHover: './imgs/offres/territoriale_hover.png',
          gridImgAlt: '',
          gridTitle: <TwoLine first='' second='FIT' />,
          gridLink: '/offres/fit',
        },
        {
          id: 4,
          avatarClass: 'offresImage',
          gridAvatar: './imgs/offres/bytype.png',
          gridAvatarHover: './imgs/offres/bytype_hover.png',
          gridImgAlt: '',
          gridTitle: <TwoLine first='' second='MAROC PME' />,
          gridLink: '/offres/pme',
        },
      ],
    };
  }

  render() {
    return (
      <div className='App'>
        <Header></Header>

        <section className='home_slider'>
          <div className='slider-title'>
            <h4>
              Portail de simulation de l'offre d'investissement dans le secteur
              industriel au Maroc
            </h4>
          </div>
          <img className='home_bg' src='./imgs/video_poster.jpg' />
        </section>
        <section className='simulation_section'>
          <Container className='simulation_form'>
            <Row>
              <Col>
                {/*  <SimulationForm/>*/}
                <Lesteppers />
              </Col>
            </Row>
          </Container>
        </section>
        <PageSection
          key={1}
          sectionClass='gridboxSection'
          sectionContainer='boxContainer'
          bigTitle={i18n.t('grid.bigtitle')}
          subTitle={i18n.t('grid.subtitle')}
          pageDescription={
            <p>
              Le Plan d’Accélération Industrielle capitalise sur les
              accomplissements du Pacte Emergence et vise à donner une impulsion
              nouvelle au secteur industriel et ainsi renforcer son
              positionnement en tant que levier majeur de croissance. Cette
              stratégie s’appuie sur la mise en place d’une nouvelle offre
              industrielle plus ciblée et compétitive et assure une mise en
              œuvre coordonnée pour ainsi garantir le succès du secteur. Dans ce
              cadre, que vous soyez investisseur Marocain ou étranger vous
              pouvez bénéficier des aides de l'Etat à travers le Fonds de
              Développement Industriel et des Investissements (voir la rubrique
              « Nos offres »).
            </p>
          }
        ></PageSection>
        <PageSection
          key={2}
          sectionClass='gridboxSection'
          sectionContainer='boxContainer'
          bigTitle='Les secteurs'
          subTitle='de l’industrie'
        >
          {this.state.gridItems.map((item) => (
            <GridItem
              key={item.id}
              avatarClass={item.avatarClass}
              gridAvatar={item.gridAvatar}
              gridAvatarHover={item.gridAvatarHover}
              gridImgAlt={item.gridImgAlt}
              gridTitle={item.gridTitle}
              gridLink={item.gridLink}
              gridText={item.gridText}
              lg='4'
              md='4'
              sm='6'
              xs='12'
              hoverColor={item.hoverColor}
            />
          ))}
        </PageSection>
        <PageSection
          key={3}
          sectionClass='secondgridboxSection'
          sectionContainer='secondboxContainer'
          bigTitle='NOS OFFRES'
        >
          {this.state.gridOffres.map((item) => (
            <GridItem
              key={item.id}
              avatarClass={item.avatarClass}
              gridAvatar={item.gridAvatar}
              gridAvatarHover={item.gridAvatarHover}
              gridTitle={item.gridTitle}
              gridLink={item.gridLink}
              bgColor='#fff'
              lg='3'
              md='3'
              sm='6'
              xs='12'
            />
          ))}
        </PageSection>
        <PageSection
          key={4}
          sectionClass='contactSection'
          sectionContainer='contactContainer'
          bigTitle='VOUS AVEZ'
          subTitle='DES QUESTIONS?'
          pageDescription='ENTREZ EN CONTACT AVEC L’UN DE NOS SPÉCIALISTES ET ENTAMEZ VOTRE PROCESSUS D’EXPANSION AUJOURD’HUI
                          '
        >
          <ContactForm></ContactForm>
        </PageSection>
        <Footer />
      </div>
    );
  }
}

export default Home;
