import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStoreActions } from "easy-peasy";

export default function NormalSelect(props){
    const {addValue} = useStoreActions(actions => actions);
    const [optionValue, setOptionValue] = useState(props.defaultValue);
    const  useStyles = makeStyles(theme => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,

        },
    }));
    const{list, title, selector,disabled,helperText,defaultValue} = props
    const [valideSelect, setValideSelect] = useState(false);
    const handleChange = async (event)  => {
        await addValue({name: event.target.name,value:event.target.value});
        setOptionValue(event.target.value);

        if(props.onThisChanged){
            props.onThisChanged(event.target.value);
        }


    };



    var checked_list = []
    if (typeof list !== 'undefined' && list.length > 0) {
        checked_list = list
    }
    useEffect(() => {
        setOptionValue(defaultValue)
        if(typeof disabled !== 'undefined'){
            setValideSelect(disabled)
        }
    });
    return (
        <FormControl className={useStyles.formControl}>
            <InputLabel htmlFor={selector}>{title}</InputLabel>
            <Select  onChange={handleChange}
                     value={optionValue}
                     disabled={valideSelect}
                     inputProps={{
                         name: selector,
                         id: selector,
                     }}>

                {checked_list.map((item) => (
                    <MenuItem  key={item.id} value={item.id} >{item.text}</MenuItem>
                ))}
                {props.children}
            </Select>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>

    );

}
