import React, {useState,useEffect} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../../helpers/i18n";
import {useStoreState} from "easy-peasy";
import {FormGroup,Row,Col} from "reactstrap";
import {Link} from "react-router-dom";
import PageSection from "../../../components/PageSection";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AccountForm from './AccountForm';
import PasswordForm from './PasswordForm';
import LoadingContent from './LoadingContent';
import ReactPaginate from 'react-paginate';
import axios from "axios";
export default function SimulationTable(props){
    const [simulations, setSimulations] = useState([]);
    const [secteurs, setSecteurs] = useState([]);
    const [secteursLoaded, setSecteursLoaded] = useState(false);
    const [ecosystemsLoaded, setEcosystemLoaded] = useState(false);
    const [ecosystems, setEcosystems] = useState([]);
    const [countPage, setCountPage] = useState(0);
    const perPage = 8;
    const returnRow =(arr,sect) => {
        const row = arr.find((elm)=> elm.id === parseInt(sect));
        return row;
    }
    const returnEco =(arr,eco) => {
        const row = arr.find((elm)=> elm.id === parseInt(eco));
        return row.text;
    }
    useEffect(()=>{
        async function fetchSimulation(){
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'estimation/api/allpaginated');
        }
        fetchSimulation().then(res => {
            //setSimulations(res.data.history);

            setSimulations(res.data.history);
            setCountPage(res.data.count);
        });
        async function fetchEcosystems(){
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'ecosystem/api/all')
        }
        async function fetchSecteurs(){
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'secteur/api/all')
        }
        fetchEcosystems().then(res => {
            //setSimulations(res.data.history);
            if(res.data.ecosystems !== []){
                setEcosystems(res.data.ecosystems);
                setEcosystemLoaded(true);
            }

        });
        fetchSecteurs().then(res => {
            //setSimulations(res.data.history);
            if(res.data.secteurs !== []){
                setSecteurs(res.data.secteurs);
                setSecteursLoaded(true);
            }

        });
    },[]);
    const handlePageClick = data => {
        console.log(data);
        const selected = parseInt(data.selected)+1;
        axios
            .get(
                process.env.REACT_APP_BACKEND_HOSTING_URL + 'estimation/api/allpaginated/' + selected
            )
            .then(res => {
                setSimulations(res.data.history);

            });
    };
    return ( <React.Fragment>
        {secteursLoaded !== false && ecosystemsLoaded !== false ?

            <table className="table table-striped margintop40">
                <thead>
                <tr>
                    <td scope="col">Date</td>
                    <th scope="col">Utilisateur</th>
                    <th scope="col">Secteur</th>
                    <th scope="col">Ecosystem</th>
                </tr>
                </thead>
                <tbody>
                {simulations.map((item) => (
                    <tr>
                        <td>
                            {item.dateEstimation}
                        </td>
                        <td>

                            <Link to={"/utilisateurs/profil/" + item.user}>{item.user}</Link>
                        </td>
                        <td>
                            {returnRow(secteurs, item.secteur).text !== undefined ? returnRow(secteurs, item.secteur).text : ''}
                        </td>
                        <td>
                            {returnEco(ecosystems, item.ecosystem)}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table> :
            <LoadingContent/>
        }
        {secteursLoaded !== false && ecosystemsLoaded !== false &&
        <div className={'pagination_container'}>
           {Math.ceil(countPage / perPage) > 1 && <ReactPaginate
                previousLabel={'<<'}
                nextLabel={'>>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(countPage / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination_holder'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            /> }
        </div>
        }
    </React.Fragment>);


}