import React, {useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Step1 from './../Step1';
import Step0 from './../Step0';
import  Step3  from './../Step3';
import GetResultBtn from "./GetResultBtn";
import i18n from "../../helpers/i18n";
import {useStoreActions, useStoreState} from "easy-peasy";
import secteursData from "../../helpers/secteursData";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: '#fff'
    },
    backButton: {
        marginRight: theme.spacing(1),

    },
    nextButton: {
        backgroundColor: '#8d2538'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    activeDot:{
        backgroundColor: '#8d2538'
    }
}));

function getSteps() {
    return ["A","B", "C"];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <Step0/>;
        case 1:
            return <Step1/>;
        case 2:
            return <Step3/>;
        default:
            return '404';
    }
}

export default function Lesteppers() {
    const classes = useStyles();
    const {btnActive,isLoaded} = useStoreState(state => state.futurInvestor.simulationForm);
    const {simulationForm} = useStoreState(state => state.futurInvestor);
    const {addValue} = useStoreActions(actions => actions);
    const [errors,setErrors] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const {isLogged} = useStoreState(state => state.user);
    const {user} = useStoreState(state => state.loggedInUser);
    const loggedInUser = localStorage.getItem('isLogged');
    const steps = getSteps();

    const handleNext = (e) => {
        if(activeStep === steps.length-1){
            console.log(activeStep);

        }else{
                if(activeStep === 0){
                    if(simulationForm.secteur_items === undefined ||
                    simulationForm.secteur_children === undefined ||
                    simulationForm.ecosystem_items === undefined ||
                    simulationForm.nbre_demploi === undefined ||
                        simulationForm.mi === undefined
                    ){
                        setErrors(i18n.t('steps.required_fields'));

                        console.log('please full required fields');
                    }else{
                        setErrors('');

                        if(simulationForm.secteur_children === secteursData.offshoring && isLogged !== false ){
                            setActiveStep(prevActiveStep => prevActiveStep + 2);
                        }else{
                            console.log("sec data"+secteursData.offshoring);
                            console.log("picked  one"+simulationForm.secteur_items );
                            setActiveStep(prevActiveStep => prevActiveStep + 1);
                        }

                    }
                }
                if(activeStep === 1){

                    if(isLogged === false){
                        if(simulationForm.nom === undefined ||
                        simulationForm.prenom === undefined ||
                        simulationForm.email === undefined){
                            setErrors(i18n.t('steps.required_fields'));
                        }else{
                            setErrors('');
                            setActiveStep(prevActiveStep => prevActiveStep + 1);
                        }
                    }else{
                        setActiveStep(prevActiveStep => prevActiveStep + 1);
                    }
                }
                if(activeStep === 2){
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                }



        }

    };

    const handleBack = () => {
        if(activeStep === 2 && simulationForm.secteur_children === secteursData.offshoring){
            setActiveStep(prevActiveStep => prevActiveStep - 2);
        }else{
            setActiveStep(prevActiveStep => prevActiveStep - 1);
        }

    };

    const handleReset = () => {
        setActiveStep(0);
    };
    useEffect(
        () => {
           /* if(parents.items !== undefined && parents.items.length > 0){
                setButtonActive(false);
            }*/

            addValue({name:"btnActive",value:false});
        },
        []
    );
    return (
        <div className={classes.root}>
            <div className="steppers_title">
                <h4 className="head_title">{ i18n.t('steps.title') }</h4>
                <h5 className="sub_headtitle">{ i18n.t('steps.subtitle') }</h5>
            </div>
            {isLoaded === true ?
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label,key) => (
                        <Step key={label}>
                            <StepLabel></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            :null}

            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div className="steps_container">
                        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                        <div className="errorsection">
                            {errors}
                        </div>
                        <div className="btns_de_navigation">
                            {activeStep !== 0 ?
                                    <Button
                                        onClick={handleBack}
                                        className="btn_navigation margin_btn_right"
                                    >
                                        Précédent
                                    </Button> : null
                            }

                            {activeStep === steps.length - 1  ?
                                    <GetResultBtn
                                        linksTo="/result"
                                        btnLabel="Lancer la simulation"
                                    />
                                    : (isLoaded === true ?
                                        <Button
                                            variant="contained"
                                            disabled = {btnActive}
                                            classes={{ disabled: classes.disabledButton }}
                                            className="btn_navigation"
                                            onClick={handleNext}>
                                            Suivant
                                        </Button> :null)
                                    }

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
