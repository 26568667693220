import React, {useState} from 'react';
import {Link} from "react-router-dom";
import NewUser from "./NewUser";
import RemovePopUp from "./RemovePopUp";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons';

export default function UsersTable(props){
    const {users,currentRole} = props
    const [showPopUp,setShowPopUp] = useState(false);
    const [tableUsers, setTableUsers] = useState(users);
    const [toRemove,setToRemove]= useState();
    const handleRemove = (id) => e => {
        e.preventDefault();
        setToRemove(id);
        setShowPopUp(true);
        console.log(id);

    }
    const handleUpdateList = removedId => {
        console.log('removed id'+removedId);
        //const rowIndex = tableUsers.findIndex((item)=> item.id === removedId);
        /*console.log('removed user index '+rowIndex);
        const tmpList = tableUsers.splice(rowIndex, 1);*/
        setTableUsers(tableUsers.filter((item) => item.id !== removedId));
    }
    return ( <React.Fragment>
       <NewUser currentRole={currentRole}/>
        {showPopUp && <RemovePopUp onRemoveUser={handleUpdateList} user={toRemove}/>}
        <table className="table table-striped margintop40">
            <thead>
            <tr>
                <th scope="col">Email</th>
                <th scope="col">Profil</th>
                <th scope="col">Enlever</th>
            </tr>
            </thead>
            <tbody>
            {tableUsers.map((user)=>(
                <tr>
                    <td>
                        {user.email}
                    </td>
                    <td>
                        <Link to={"/utilisateurs/profil/"+user.id}><FontAwesomeIcon icon={faEye} /></Link>
                    </td>
                     <td>
                        <Link to={"#"} onClick={handleRemove(user.id)}><FontAwesomeIcon icon={faTrash} /></Link>

                    </td>

                </tr>
            ))}
            </tbody>
        </table>

            </React.Fragment>);


}