import React, { useEffect,useState } from 'react';
import {  Row, Col,FormGroup, Container } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import i18n from "../../helpers/i18n";
import axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {useStoreActions} from "easy-peasy";

export default function ContactForm(props) {
        const {setSnackbarState} = useStoreActions(actions => actions);
        const [contactname,setContactname] = useState('');
        const [contactemail, setContactemail] = useState('');
        const [contacttel, setContacttel]  = useState('');
        const [contactmsg, setContactmsg]   = useState('');
        const [contactsubject, setContactsubject]   = useState('');
        const [contactnameError,setContactnameError] = useState(true);
        const [contactemailError, setContactemailError] = useState(true);
        const [contacttelError, setContacttelError]  = useState(true);
        const [contactmsgError, setContactmsgError]   = useState(true);
        const [contactsubjectError, setContactsubjectError]   = useState(true);
        const [enableBtn,setEnableBtn] = useState(true);
        const [loadingBtn,setLoadingBtn] = useState(true);

        const handleClick = () => {
            setLoadingBtn(false);
            const message = {
                email:contactemail,
                fullname:contactname,
                tel:contacttel,
                subject:contactsubject,
                message: contactmsg
            }
            axios.post( process.env.REACT_APP_BACKEND_HOSTING_URL+'api/messages',  message )
                .then(res => {
                    setContactname('');
                    setContactemail('');
                    setContacttel('');
                    setContactmsg('');
                    setContactsubject('');
                    setLoadingBtn(true);
                    setSnackbarState({state:true,message:i18n.t('contactform.msgsent')})
                });
        }


        const handleChange = e => {
            console.log(e.target.value);
            switch(e.target.name){
                case 'contactname':
                    setContactname(e.target.value);

                    break;
                case 'contactemail':
                    setContactemail(e.target.value);
                    break;
                case 'contacttel':
                    setContacttel(e.target.value);
                    break;
                case 'contactmsg':
                    setContactmsg(e.target.value);
                    break;
                case 'contactsubject':
                    setContactsubject(e.target.value);
                    break;
            }

            if (/^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(contactemail)){
                setContactemailError(false);
                console.log('email correxct-');
            }
            if (/^[a-zA-Z ]+$/.test(contactname)){
                setContactnameError(false);
                console.log('name correxct-');
            }
            if (/^[+]*[0-9][\s\./0-9]*$/.test(contacttel)){
                setContacttelError(false);
                console.log('tel correxct-');
            }
            if(contactsubject.length > 6){
                setContactsubjectError(false);
            }
            if(contactmsg.length > 10){
                setContactmsgError(false);
            }
            console.log(contactnameError);
            console.log(contactemailError);
            console.log(contacttelError);
            if(contactnameError === false &&
                contactemailError === false &&
                contacttelError === false &&
                contactmsgError === false &&
                contactsubjectError === false){

                setEnableBtn(false);
            }

        }

        return(


                        <Container>
                        <Row>
                            <Col lg={4} md={4} sm={6} xs={12}>
                                <FormGroup>
                                    <TextField
                                        required
                                        name="contactname"
                                        id="contactname"
                                        margin="normal"
                                        label={i18n.t('contactform.contactname')}
                                        value={contactname}
                                        onChange={handleChange}
                                        variant="outlined"
                                        inputProps={
                                            {
                                                form: {
                                                    autocomplete: "off",
                                                }
                                            }
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={12}>
                                <FormGroup>
                                    <TextField
                                        required
                                        id="contactemail"
                                        name="contactemail"
                                        type="email"
                                        margin="normal"
                                        label={i18n.t('contactform.contactemail')}
                                        value={contactemail}
                                        onChange={handleChange}
                                        variant="outlined"
                                        autoComplete='off'
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={12}>
                                <FormGroup>
                                    <TextField
                                        required
                                        id="contacttel"
                                        name="contacttel"
                                        type="tel"
                                        margin="normal"
                                        label={i18n.t('contactform.contacttel')}
                                        value={contacttel}
                                        onChange={handleChange}
                                        variant="outlined"
                                        autoComplete='off'
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <TextField
                                        required
                                        id="contactsubject"
                                        name="contactsubject"
                                        type="text"
                                        margin="normal"
                                        label={i18n.t('contactform.contactsubject')}
                                        value={contactsubject}
                                        onChange={handleChange}
                                        variant="outlined"
                                        autoComplete='off'
                                    />
                                </FormGroup>
                             </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <TextField
                                        required
                                        id="contactmsg"
                                        name="contactmsg"
                                        multiline={true}
                                        rows={4}
                                        label={i18n.t('contactform.contactmsg')}
                                        value={contactmsg}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Button variant="contained" className="btn_navigation btn" onClick={handleClick}  disabled={enableBtn}>
                                        {loadingBtn === false ? <CircularProgress  size={24} /> : i18n.t('contactform.send') }
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        </Container>


        );

}