import React, {useEffect, useState} from "react";
import { useStoreActions,useStoreState } from "easy-peasy";
import { Card, Button, CardTitle, CardBody, Row, Col,FormGroup, Label, Input } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import Step2 from './Step2';
import i18n from "../helpers/i18n";
import axios from "axios";
import {get_rbi} from "../helpers/functions";

export default function Step0() {
    const [isLoaded,setIsLoaded] = useState(false);
    const {addValue,addParents} = useStoreActions(actions => actions);
    //test function offshore rbi
    const {offshoring_rbi} = useStoreState(state => state.futurInvestor.simulationForm);
    console.log(get_rbi('technicien',3050,offshoring_rbi));
    //end test
    useEffect(
        () => {
            // Start it off by assuming the component is still mounted
            let mounted = true;

            const loadData = async () => {
                const response = await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL + 'secteur/api');
                // We have a response, but let's first check if component is still mounted
                if (mounted) {
                    addParents(response.data);
                    setIsLoaded(true);
                   addValue({name: 'isLoaded' ,value:true});
                }
            };
            loadData();

            return () => {

                // When cleanup is called, toggle the mounted variable to false
                mounted = false;
            };
        },
        [process.env.REACT_APP_BACKEND_HOSTING_URL + 'secteur/api']
    );
    return (
        <>
        {isLoaded === true ?
            <Step2/>
        :
            <div>
                <CircularProgress color="secondary" />
                <h4>{ i18n.t('template.loading') }</h4>
            </div>
        }

        </>

    );
}