import {addPlafond, addTotal, find_item_amount, get_min, get_percent, makeItAppears, to_million} from "../functions";
export function aeronautique(secteur,sousSecteur,simulationForm,offres,ecosystems){
    const {extra_field_value,
        montant_terain,
        montant_construction,
        mi,
        montant_equipements_neuf,
        montant_equipements_ocassion,
        ecosystem_items,
        nbre_demploi,
        eco_system_metier,
        transfert_technologique,
        ville,
        zones_select,
        transfert_mobilisation,
        environnement,
        infrastructure_externe,
        formation_input,
        montant_divers,ca,ca1,ca2,ca3,
        boeing_metiers,
        villes,
        frais_notaire,
        frais_enregistrement,
        frais_conservation,
    } = simulationForm

    const ecosystem_selected = parseInt(ecosystem_items);
    var aeronautique_offres = offres.industrie.aeronautique;
    var terrain_construction_new_val = 0;
    var mipercent = 0;
    var equipements = 0;
    // vars boeing
    var fddi_boeing_equipements = 0;
    var fddi_boeing_construction = 0;
    var fddi_boeing_transfert_mobilisation = 0;
    // ecosys
    var fddi_ecosys_terrain_construction = 0;
    var fddi_ecosys_equipement = 0;
    // #FDII offre
    if(sousSecteur.fields.length > 0 || ecosystem_selected !== undefined){

        const field = sousSecteur.fields.find(el => el.selector_id === "extra_field_value");


        //Being condition
        if(field.true_value === extra_field_value.toLowerCase()){
            const montant_invisted_being = parseInt(montant_terain)+
                parseInt(montant_construction) +
                parseInt(montant_equipements_neuf) +
                parseInt(montant_equipements_ocassion)+
                parseInt(transfert_mobilisation);
            terrain_construction_new_val = parseInt(montant_terain)+parseInt(montant_construction);
            mipercent = parseInt(montant_invisted_being)*20/100;
            //terrain et construction 20%
            var terrain_construction_amount = 0
            if(parseInt(nbre_demploi) < 800 || boeing_metiers === "Autre") {
                //Terrain_construction 20%

                if (terrain_construction_new_val < mipercent) {
                    terrain_construction_amount = (terrain_construction_new_val * 20) / 100;
                    fddi_boeing_construction = terrain_construction_amount;
                    aeronautique_offres = find_item_amount('terrain_construction', 'fdii', aeronautique_offres, terrain_construction_amount)
                } else {
                    terrain_construction_amount = (mipercent * 20) / 100;
                    fddi_boeing_construction = terrain_construction_amount;
                    aeronautique_offres = find_item_amount('terrain_construction', 'fdii', aeronautique_offres, terrain_construction_amount)
                }
            }
            if(boeing_metiers !== "Autre" || parseInt(nbre_demploi) >= 800){
                //Terrain_construction 25%

                if (terrain_construction_new_val < mipercent) {
                    terrain_construction_amount = (terrain_construction_new_val * 25) / 100;
                    fddi_boeing_construction = terrain_construction_amount;
                    aeronautique_offres = find_item_amount('terrain_construction', 'fdii', aeronautique_offres, terrain_construction_amount)
                } else {
                    terrain_construction_amount = (mipercent * 25) / 100;
                    fddi_boeing_construction = terrain_construction_amount;
                    aeronautique_offres = find_item_amount('terrain_construction', 'fdii', aeronautique_offres, terrain_construction_amount)
                }
            }
            if(parseInt(nbre_demploi) < 800 || boeing_metiers === "Autre"){
                //Equippement 20%
                equipements = ((parseInt(montant_equipements_neuf)+parseInt(montant_equipements_ocassion))*20)/100;
                fddi_boeing_equipements = equipements;
                aeronautique_offres =  find_item_amount('equipement','fdii',aeronautique_offres,equipements);

            }
            if(boeing_metiers !== "Autre" || parseInt(nbre_demploi) >= 800){
                //Equippement 25%
                equipements = ((parseInt(montant_equipements_neuf)+parseInt(montant_equipements_ocassion))*25)/100;
                fddi_boeing_equipements = equipements;
                aeronautique_offres =  find_item_amount('equipement','fdii',aeronautique_offres,equipements);

            }
            // transfer savoir faire
            const mipercentTransfer = parseInt(montant_invisted_being)*5/100;
            var montant_transfer = 0;
            //transfert_mobilisation = transfert_mobilisation;
            if(parseInt(nbre_demploi) < 800 || boeing_metiers === "Autre"){

                if(transfert_mobilisation < mipercentTransfer){
                    montant_transfer = transfert_mobilisation;
                }else{
                    montant_transfer = mipercentTransfer;
                }
                montant_transfer = montant_transfer*20/100;
            }if(boeing_metiers !== "Autre" || parseInt(nbre_demploi) >= 800){
                montant_transfer = transfert_mobilisation;
                if(transfert_mobilisation < mipercentTransfer){
                    montant_transfer = transfert_mobilisation;
                }else{
                    montant_transfer = mipercentTransfer;
                }
                montant_transfer = montant_transfer*25/100;
            }
            fddi_boeing_transfert_mobilisation = montant_transfer;
            aeronautique_offres =  find_item_amount('transfert_mobilisation','fdii',aeronautique_offres,montant_transfer);
            // total montant transfer mobili

            //Total
            var total_boeing = equipements + terrain_construction_amount+montant_transfer;
            console.log('totbeing '+total_boeing);
            aeronautique_offres =  addTotal(aeronautique_offres,"fdii",total_boeing);
            //La subvention est non plafonnée
            var plafond = "La subvention est non plafonnée";
            aeronautique_offres =  addPlafond(aeronautique_offres,"fdii",plafond);
            aeronautique_offres = makeItAppears(aeronautique_offres,"fdii");
        }
        if(ecosystem_selected !== undefined && ecosystem_selected > 0){
            if((parseInt(mi) > 50 || parseInt(nbre_demploi) >= 200)
                || (((parseInt(mi) >= 20) || parseInt(nbre_demploi) >= 50 )
                    && eco_system_metier !== undefined) ) {
                //ecosystem condition
                const eco = ecosystems.items.find(el => el.id === ecosystem_selected);
                const montant_invisted_ecosystem = parseInt(montant_terain) +
                    parseInt(montant_construction) +
                    parseInt(montant_equipements_neuf);
                if (eco.ecosystem.toLowerCase() === "assemblage" ||
                    eco.ecosystem.toLowerCase() === "ewis" ||
                    eco.ecosystem.toLowerCase() === "mro"||
                    eco.ecosystem.toLowerCase() !== "ingénierie") {
                    terrain_construction_new_val = parseInt(montant_terain) + parseInt(montant_construction);
                    mipercent = parseInt(montant_invisted_ecosystem) * 20 / 100;
                    var terrain_construction_amount = 0;
                    if (terrain_construction_new_val < mipercent) {
                        terrain_construction_amount = (terrain_construction_new_val* 30) / 100;
                        aeronautique_offres = find_item_amount('terrain_construction', 'fdii', aeronautique_offres, terrain_construction_amount );
                        fddi_ecosys_terrain_construction = terrain_construction_amount;

                    } else {
                        terrain_construction_amount = (mipercent* 30) / 100;
                        aeronautique_offres = find_item_amount('terrain_construction', 'fdii', aeronautique_offres, terrain_construction_amount )
                        fddi_ecosys_terrain_construction = terrain_construction_amount;
                    }
                    //Equippement 30%
                    equipements = (parseInt(montant_equipements_neuf) * 30) / 100;
                    aeronautique_offres = find_item_amount('equipement', 'fdii', aeronautique_offres, equipements);
                    fddi_ecosys_equipement = equipements;
                    //Total
                    var total_ecosystem = equipements + terrain_construction_amount;
                    console.log('total eco '+total_ecosystem);
                    if(total_ecosystem > 30){
                        total_ecosystem = 30;
                    }
                    aeronautique_offres = addTotal(aeronautique_offres, "fdii", total_ecosystem);
                    //Plafond 30M
                    var plafond = "Le plafond est de 30M";
                    aeronautique_offres = addPlafond(aeronautique_offres, "fdii", plafond);
                    aeronautique_offres = makeItAppears(aeronautique_offres, "fdii");
                }
                //end ecosystem
            }
        }
        //compare two totals

        if(total_ecosystem !== undefined && total_boeing !== undefined){
            if(total_boeing > total_ecosystem){
                aeronautique_offres =  find_item_amount('transfert_mobilisation','fdii',aeronautique_offres,fddi_boeing_transfert_mobilisation);
                aeronautique_offres =  find_item_amount('equipement','fdii',aeronautique_offres,fddi_boeing_equipements);
                aeronautique_offres =  find_item_amount('terrain_construction','fdii',aeronautique_offres,fddi_boeing_construction);
                aeronautique_offres = addTotal(aeronautique_offres, "fdii", total_boeing);
                var plafond = "La subvention est non plafonnée";
                aeronautique_offres =  addPlafond(aeronautique_offres,"fdii",plafond);
            }else{
                aeronautique_offres =  find_item_amount('transfert_mobilisation','fdii',aeronautique_offres,0);
                aeronautique_offres =  find_item_amount('terrain_construction','fdii',aeronautique_offres,fddi_ecosys_terrain_construction);
                aeronautique_offres =  find_item_amount('equipement','fdii',aeronautique_offres,fddi_ecosys_equipement);
                aeronautique_offres = addTotal(aeronautique_offres, "fdii", total_ecosystem);
                var plafond = "Le plafond est de 30M";
                aeronautique_offres = addPlafond(aeronautique_offres, "fdii", plafond);
            }
        }


    }
    //# end FDII
    //#fpi start
    if(zones_select !== "Franche" && nbre_demploi !== undefined && mi !== undefined && transfert_technologique !== undefined && environnement !== undefined && ville !== undefined ){
        var getOffre = false;
        if(parseInt(nbre_demploi) >= 250 ||
            parseInt(mi) >= 100 ||
            transfert_technologique.toLowerCase() === "oui" ||
            environnement.toLowerCase() === "oui" ||
            villes.indexOf(ville) > -1
        ){
            //# montant terrain
            const montant_terrain = parseFloat(montant_terain)+to_million(parseFloat(frais_notaire))+to_million(parseFloat(frais_conservation))+to_million(parseFloat(frais_enregistrement));

            var ter = montant_terrain*20/100;

            aeronautique_offres = find_item_amount('terrain_construction','fpi',aeronautique_offres,(montant_terrain*20/100))


            //# montant infra
            var infra = 0;
            if(parseFloat(infrastructure_externe) > 0){
                infra = get_min(parseFloat(infrastructure_externe),get_percent(parseFloat(mi),5));
                aeronautique_offres = find_item_amount('infrastructure_externe','fpi',aeronautique_offres,infra);
            }
            //# montant formationformation_input
            const montant_formation = parseInt(formation_input);
            var form = montant_formation*20/100;

            aeronautique_offres = find_item_amount('formation','fpi',aeronautique_offres,(montant_formation*20/100))


            //Total
            var total = ter + infra + form;


            //Plafond 5%
            var plafond = "Le plafond est de 5% MI";
            if(zones_select === "Rurale"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Suburbaine"){

                if(total > parseInt(mi)*10/100){
                    total = parseInt(mi)*10/100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if(zones_select === "Urbaine"){

                if(total > parseInt(mi)*5/100){
                    total = parseInt(mi)*5/100;
                }
                plafond = "Le plafond est de 5% MI";
            }
            aeronautique_offres =  addTotal(aeronautique_offres,"fpi",total);
            aeronautique_offres =  addPlafond(aeronautique_offres,"fpi",plafond);
            if(total>0){
                aeronautique_offres = makeItAppears(aeronautique_offres,"fpi");
            }


        }
    }
    //#fpi end
    //#maroc pme
    const pme_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) < 200 && parseInt(el) > 10);
    if(pme_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*20)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        aeronautique_offres = find_item_amount('equipement','maroc_pme',aeronautique_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*20/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        aeronautique_offres = find_item_amount('terrain_construction','maroc_pme',aeronautique_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*20)/100;
        the_total = the_total+montant_assigned;
        aeronautique_offres = find_item_amount('divers','maroc_pme',aeronautique_offres,montant_assigned);
        // plafond 10
        if(the_total > 10){
            the_total = 10;
        }
        aeronautique_offres =  addTotal(aeronautique_offres,"maroc_pme",the_total);

        var plafond = "Le plafond est de: 10M";
        aeronautique_offres =  addPlafond(aeronautique_offres,"maroc_pme",plafond);
        aeronautique_offres = makeItAppears(aeronautique_offres,"maroc_pme");
    }
    //#ejnd maroc tpe
    const tpe_valid = [ca,ca1,ca2,ca3].every(el => parseInt(el) < 11);
    if(tpe_valid){
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi)*75/100;
        var montant_assigned = montant_equipements_pme;
        if(montant_equipements_pme > mi75){
            montant_assigned = mi75;
        }else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned*30)/100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total+montant_assigned;
        aeronautique_offres = find_item_amount('equipement','maroc_tpe',aeronautique_offres,montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi)*30/100;
        const terrain_construction = parseInt(montant_terain)+parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if(terrain_construction > mi20){
            montant_assigned = mi20;
        }else{
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        aeronautique_offres = find_item_amount('terrain_construction','maroc_tpe',aeronautique_offres,montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi)*5/100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if(montant_divers_added > mi5){
            montant_assigned = mi5;
        }else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned*30)/100;
        the_total = the_total+montant_assigned;
        aeronautique_offres = find_item_amount('divers','maroc_tpe',aeronautique_offres,montant_assigned);
        //Plafond 2M
        if(the_total > 2){
            the_total = 2;
        }
        aeronautique_offres =  addTotal(aeronautique_offres,"maroc_tpe",the_total);


        var plafond = "Le plafond est de: 2M";
        aeronautique_offres =  addPlafond(aeronautique_offres,"maroc_tpe",plafond);
        aeronautique_offres = makeItAppears(aeronautique_offres,"maroc_tpe");
    }
    //#fh2 start
    var current_ecosys;
    var test_ingenier=false;
    if(ecosystem_selected !== undefined){
         current_ecosys = ecosystems.items.find(el => el.id === ecosystem_selected);
         if (current_ecosys.ecosystem.toLowerCase() ===  "ingénierie")
         {
             test_ingenier=true
         }
    }

        if(parseInt(mi)>10 && parseInt(montant_equipements_neuf) > 5 && test_ingenier ===false){

                var the_total = 0;
            //# acquisation_du_foncier
            const foncier = ((parseInt(montant_terain)+parseInt(montant_construction))*10)/100;

            the_total = the_total + foncier;
            aeronautique_offres = find_item_amount('terrain_construction','fh2',aeronautique_offres,foncier);
            //# equipemnt
            const fh2_equipement = (parseInt(montant_equipements_neuf)*20)/100;

            the_total = the_total + fh2_equipement;
            aeronautique_offres = find_item_amount('equipement','fh2',aeronautique_offres,fh2_equipement);
            console.log('total fh2'+the_total);
            // plafond 15% ou 30μM
            if(the_total > (parseInt(mi)*15/100)){
                the_total = (parseInt(mi)*15/100);
                if(the_total > 30){
                    the_total = 30;
                }
            }else if (the_total > 30){
                    the_total = 30;
            }


            aeronautique_offres =  addTotal(aeronautique_offres,"fh2",the_total);
            //Plafond 30M
            // var plafond_value = (parseInt(mi)*15/100) >= 30 ? 30 : (parseInt(mi)*15/100);
            // var plafond = "Le plafond est "+plafond_value+" MAD";
            var plafond = "Le plafond est le minimum entre 15% et 30M";
            aeronautique_offres =  addPlafond(aeronautique_offres,"fh2",plafond);
            aeronautique_offres = makeItAppears(aeronautique_offres,"fh2");

        }


    //#fh2 end
    return aeronautique_offres;
}