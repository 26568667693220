import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useStoreActions, useStoreState} from "easy-peasy";

export default function MySnackbar() {
    const {state,message} = useStoreState(state => state.snackbar);
    const {setSnackbarState} = useStoreActions(actions => actions);
    const [open, setOpen] = React.useState(state);
    const snackbar_state = state;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState({state:false,message:""})
        setOpen(snackbar_state);
    };

    return (
        <div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackbar_state}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
}