import React, { useState } from "react";
import {  Row, Col,FormGroup } from 'reactstrap';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import i18n from "../helpers/i18n";
import secteursData from "../helpers/secteursData";
import NormalSelect from './components/NormalSelect';
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {useStoreActions, useStoreState} from "easy-peasy";
import GetResultBtn from "./components/GetResultBtn";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function Step3() {
    const {addValue} = useStoreActions(actions => actions);
    const {secteur_children,
        montant_location,
        montant_terain,
        montant_construction,
        montant_equipements_neuf,
        montant_equipements_ocassion,
        infrastructure_externe,
        transfert_mobilisation,
        formation_input,
        montant_divers,
        zones_select,
        ville,
        environnement,
        transfert_technologique,
        frais_innovation_agregateurs_produit_finis,
        frais_dinnovation_pour_operateurs,
        isrent,
        frais_notaire,
        frais_enregistrement,
        frais_conservation,
        villes,
        type_foncier,
        frais_assistance_technique,
        frais_d_innovation_immateriel,
        nature_activite,
        superficier_foncier,
        superficier_construction,
        superficier_total_batiment,
        cout_acquisition_batiment,
        cout_construction_batiment,
        cout_loyer_foncier,
        superficier_total_foncier,
        superficier_total_construction,
        cout_loyer_batiment,
        frais_amenagements,
        frais_reseau_distribution,
    } = useStoreState(state => state.futurInvestor.simulationForm);

    const handleFocus = e => {

        if(parseInt(e.target.value) === 0){
            addValue({name: e.target.name,value:''});
        }
    }
    const handleBlur = e => {

        if(e.target.value.length === 0){
            console.log('onblur');
            addValue({name: e.target.name,value:0});
        }
    }
    const handleChange = async (e) => await addValue({name: e.target.name,value:e.target.value});
    //secteur automoble
    //const { isrent} = state;
    const handleCkChange =  (e) => {
        addValue({name: e.target.name,value:e.target.checked});
        addValue({name: 'montant_location',value:''});
        addValue({name: 'montant_terain',value:''});
        addValue({name: 'montant_construction',value:''});
        //  setState({ ...state, [e.target.name]: e.target.checked });
        //setState({ ...state, isconstruct: !e.target.checked });
    }

    return (
        <div className="step step3">
            {secteur_children ===  secteursData.automobile ?
                <Row form>
                    <Col md={6}>

                        <FormGroup>
                            <NormalSelect

                                selector = "type_foncier"
                                title = { i18n.t('form.type_foncier') }
                                defaultValue = {type_foncier}
                            >
                                <MenuItem  key="2" value="Cas d'acquisition ou construction des bâtiments">Acquisition du foncier et construction/acquisition des bâtiments</MenuItem>
                                <MenuItem  key="4" value="Cas de location du bâtiment">Cas de location du bâtiment</MenuItem>



                            </NormalSelect>
                        </FormGroup>
                    </Col>
                </Row>: null }
            {type_foncier === "Cas d'acquisition ou construction des bâtiments" && secteur_children ===  secteursData.automobile ?
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="cout_acquisition_batiment"
                                name="cout_acquisition_batiment"
                                helperText="Montant en million dirham Marocain"
                                onChange={handleChange}
                                label={i18n.t('form.cout_acquisition_batiment')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                value={cout_acquisition_batiment}
                                defaultValue={cout_acquisition_batiment}

                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="superficier_total_batiment"
                                name="superficier_total_batiment"
                                onChange={handleChange}
                                label={i18n.t('form.superficier_total_batiment')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">(m²)</InputAdornment>,
                                }}
                                type="number"
                                value={superficier_total_batiment}
                                defaultValue={superficier_total_batiment}

                            />
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="cout_construction_batiment"
                                name="cout_construction_batiment"
                                helperText="Montant en million dirham Marocain"
                                onChange={handleChange}
                                label={i18n.t('form.cout_construction_batiment')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                value={cout_construction_batiment}
                                defaultValue={cout_construction_batiment}

                            />
                        </FormGroup>
                    </Col>
                </Row>
                :null}


            {type_foncier === "Cas de location du bâtiment" && secteur_children ===  secteursData.automobile?
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="cout_loyer_foncier"
                                name="cout_loyer_foncier"
                                onChange={handleChange}
                                label={i18n.t('form.cout_loyer_foncier')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">(DH/m²/mois)</InputAdornment>,
                                }}
                                type="number"
                                value={cout_loyer_foncier}
                                defaultValue={cout_loyer_foncier}

                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="superficier_total_foncier"
                                name="superficier_total_foncier"
                                onChange={handleChange}
                                label={i18n.t('form.superficier_total_foncier')}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">(m²)</InputAdornment>,
                                }}
                                type="number"
                                value={superficier_total_foncier}
                                defaultValue={superficier_total_foncier}

                            />
                        </FormGroup>
                    </Col>
                </Row>:null}

            {secteur_children !==  secteursData.automobile && secteur_children !== secteursData.offshoring ?
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="montant_terain"
                                name="montant_terain"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.montant_terain') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                value={montant_terain}
                                defaultValue={montant_terain}

                            />
                        </FormGroup>
                    </Col>
                    {secteur_children !== secteursData.offshoring &&
                        <Col md={6}>
                            <FormGroup>
                                <TextField
                                    id="montant_construction"
                                    label={i18n.t('form.montant_construction')}
                                    helperText="Montant en million dirham Marocain"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                    }}
                                    type="number"
                                    defaultValue={montant_construction}
                                    value={montant_construction}
                                    name="montant_construction"
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}

                                />

                            </FormGroup>
                        </Col>
                    }
                </Row> : null
            }

            <Row form>
                {secteur_children !== secteursData.offshoring &&
                <React.Fragment>
                        <Col md={6}>
                            <FormGroup>
                                <TextField
                                    id="frais_notaire"
                                    label={ i18n.t('form.frais_notaire') }
                                    helperText="Montant en dirham Marocain"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">DH</InputAdornment>,
                                    }}
                                    type="number"
                                    defaultValue={frais_notaire}
                                    value={frais_notaire}
                                    name="frais_notaire"
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <TextField
                                    id="frais_enregistrement"
                                    label={ i18n.t('form.frais_enregistrement') }
                                    helperText="Montant en dirham Marocain"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">DH</InputAdornment>,
                                    }}
                                    type="number"
                                    defaultValue={frais_enregistrement}
                                    value={frais_enregistrement}
                                    name="frais_enregistrement"
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <TextField
                                    id="frais_conservation"
                                    label={ i18n.t('form.frais_conservation') }
                                    helperText="Montant en dirham Marocain"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">DH</InputAdornment>,
                                    }}
                                    type="number"
                                    defaultValue={frais_conservation}
                                    value={frais_conservation}
                                    name="frais_conservation"
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                />
                            </FormGroup>
                        </Col>

                <Col md={6}>
                    <FormGroup>
                        <TextField
                            id="montant_equipements_neuf"
                            label={ secteur_children ===  secteursData.offshoring ? i18n.t('form.montant_equipements') : i18n.t('form.montant_equipements_neuf') }
                            helperText="Montant en million dirham Marocain"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                            }}
                            type="number"
                            defaultValue={montant_equipements_neuf}
                            value={montant_equipements_neuf}
                            name="montant_equipements_neuf"
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </FormGroup>
                </Col>
                    </React.Fragment>
                    }
                {secteur_children !== secteursData.agroalimentaire && secteur_children !== secteursData.offshoring ?
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="montant_equipements_ocassion"
                                label={ i18n.t('form.montant_equipements_occasion') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={montant_equipements_ocassion}
                                value={montant_equipements_ocassion}
                                name="montant_equipements_ocassion"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                    </Col>
                :null}


                { secteur_children !== secteursData.offshoring &&
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="infrastructure_externe"
                                label= { i18n.t('form.infrastructure_externe') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={infrastructure_externe}
                                value={infrastructure_externe}
                                name="infrastructure_externe"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}

                            />
                        </FormGroup>
                    </Col>
                }
                {secteur_children !== secteursData.offshoring && <Col md={6}>
                    <FormGroup>
                        <TextField
                            id="formation_input"
                            label= { i18n.t('form.formation_input') }
                            helperText="Montant en million dirham Marocain"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                            }}
                            type="number"
                            defaultValue={formation_input}
                            value={formation_input}
                            name="formation_input"
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </FormGroup>
                </Col>}
            </Row>
            {secteursData.textile_cuir === secteur_children ?
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="frais_innovation_agregateurs_produit_finis"
                                label= { i18n.t('form.frais_innovation_agregateurs_produit_finis') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={frais_innovation_agregateurs_produit_finis}
                                value={frais_innovation_agregateurs_produit_finis}
                                name="frais_innovation_agregateurs_produit_finis"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="frais_dinnovation_pour_operateurs"
                                name="frais_dinnovation_pour_operateurs"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.frais_dinnovation_pour_operateurs') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={frais_dinnovation_pour_operateurs}
                                value={frais_dinnovation_pour_operateurs}
                            />
                        </FormGroup>
                    </Col>
                </Row>:null
            }
            {secteursData.textile_cuir === secteur_children ?
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="frais_d_innovation_immateriel"
                                label= { i18n.t('form.frais_d_innovation_immateriel') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={frais_d_innovation_immateriel}
                                value={frais_d_innovation_immateriel}
                                name="frais_d_innovation_immateriel"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="frais_amenagements"
                                name="frais_amenagements"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.frais_amenagements') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={frais_amenagements}
                                value={frais_amenagements}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="frais_reseau_distribution"
                                name="frais_reseau_distribution"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.frais_reseau_distribution') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={frais_reseau_distribution}
                                value={frais_reseau_distribution}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="frais_assistance_technique"
                                name="frais_assistance_technique"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.frais_assistance_technique') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={frais_assistance_technique}
                                value={frais_assistance_technique}
                            />
                        </FormGroup>
                    </Col>
                </Row>:null
            }
            <Row form>
                {secteur_children !== secteursData.offshoring &&
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="transfert_mobilisation"
                                label= { i18n.t('form.transfert_mobilisation') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={transfert_mobilisation}
                                value={transfert_mobilisation}
                                name="transfert_mobilisation"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                        </FormGroup>
                    </Col>
                }

                {secteursData.aeronautique === secteur_children || secteursData.automobile === secteur_children ?
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="montant_divers"
                                name="montant_divers"
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.montant_divers') }
                                helperText="Montant en million dirham Marocain"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={montant_divers}
                                value={montant_divers}
                            />
                        </FormGroup>
                    </Col>
                    :null}
                {secteursData.textile_cuir === secteur_children ?

                    <Col md={6}>
                        <FormGroup>
                            <NormalSelect

                                selector = "nature_activite"
                                title = { i18n.t('form.nature_activite') }
                                defaultValue={nature_activite}
                            >
                                <MenuItem  key={4} value="En amont">
                                    En amont
                                </MenuItem>
                                <MenuItem  key={3} value="En aval">
                                    En aval
                                </MenuItem>
                            </NormalSelect>
                        </FormGroup>
                    </Col>
                    :null}
            </Row>
            <Row form>
                {secteur_children !== secteursData.offshoring &&
                    <Col md={6}>
                        <FormGroup>
                            <NormalSelect

                                selector = "zones_select"
                                title = { i18n.t('form.zone') }
                                defaultValue={zones_select}
                            >
                                <MenuItem  key={1} value="Urbaine">
                                    Urbaine
                                </MenuItem>

                                <MenuItem  key={2} value="Suburbaine">
                                    Suburbaine
                                </MenuItem>
                                <MenuItem  key={4} value="Franche">
                                    Franche
                                </MenuItem>
                                <MenuItem  key={3} value="Rurale">
                                    Rurale
                                </MenuItem>
                            </NormalSelect>
                        </FormGroup>
                    </Col>
                }

                <Col md={6}>
                    <FormGroup>
                        <NormalSelect

                            selector = "ville"
                            title = { i18n.t('form.ville') }
                            defaultValue={ville}
                        >
                            <MenuItem  key={1} value="Rabat">
                                Rabat
                            </MenuItem>
                            <MenuItem  key={2} value="Casablanca">
                                Casablanca
                            </MenuItem>
                            <MenuItem  key={3} value="Kenitra">
                                Kenitra
                            </MenuItem>
                            <MenuItem  key={4} value="Tanger">
                                Tanger
                            </MenuItem>
                            <MenuItem  key={5} value="Fes">
                                Fes
                            </MenuItem>
                            <MenuItem  key={6} value="Agadir">
                                Agadir
                            </MenuItem>
                            <MenuItem  key={7} value="Oujda">
                                Oujda
                            </MenuItem>
                            <MenuItem  key={8} value="Al Hoceima">
                                Al Hoceima
                            </MenuItem>
                            <MenuItem  key={9} value="Berkane">
                                Berkane
                            </MenuItem>
                            <MenuItem  key={10} value="Boujdour">
                                Boujdour
                            </MenuItem>
                            <MenuItem  key={11} value="Chefchaouen">
                                Chefchaouen
                            </MenuItem>
                            <MenuItem  key={12} value="Es-Samara">
                                Es-samara
                            </MenuItem>
                            <MenuItem  key={13} value="Guelmim">
                                Guelmim
                            </MenuItem>
                            <MenuItem  key={14} value="Laâyoune">
                                Laâyoune
                            </MenuItem>
                            <MenuItem  key={15} value="Larache">
                                Larache
                            </MenuItem>
                            <MenuItem  key={16} value="Nador">
                                Nador
                            </MenuItem>
                            <MenuItem  key={16} value="Oued-Eddahab">
                                Oued-Eddahab
                            </MenuItem>
                            <MenuItem  key={16} value="Oujda-Angad">
                                Oujda-Angad
                            </MenuItem>
                            <MenuItem  key={16} value="Tanger-Assilah">
                                Tanger-Assilah
                            </MenuItem>
                            <MenuItem  key={16} value="Fahs-Bni-Makada">
                                Fahs-Bni-Makada
                            </MenuItem>
                            <MenuItem  key={16} value="Tan-Tan">
                                Tan-Tan
                            </MenuItem>
                            <MenuItem  key={16} value="Taounate">
                                Taounate
                            </MenuItem>
                            <MenuItem  key={16} value="Taourirt">
                                Taourirt
                            </MenuItem>
                            <MenuItem  key={16} value="Tata">
                                Tata
                            </MenuItem>
                            <MenuItem  key={16} value="Taza">
                                Taza
                            </MenuItem>
                            <MenuItem  key={16} value="Tétouan">
                                Tétouan
                            </MenuItem>
                        </NormalSelect>
                    </FormGroup>
                </Col>



            </Row>
            <Row form>
                {secteur_children !== secteursData.offshoring &&
                <Col md={6}>
                    <FormGroup>
                        <NormalSelect

                            selector="environnement"
                            title={i18n.t('form.environnement')}
                            helperText="Toute opération d’amélioration des conditions de protection de l’environnement indépendamment des actions de suppression ou de réduction des nuisances liées à la nature de l’activité."
                            defaultValue={environnement}
                        >
                            <MenuItem key={1} value="Oui">
                                Oui
                            </MenuItem>
                            <MenuItem key={2} value="Non">
                                Non
                            </MenuItem>
                        </NormalSelect>
                    </FormGroup>
                </Col>
                }
                {secteur_children !== secteursData.offshoring &&
                    <Col md={6}>
                        <FormGroup>
                            <NormalSelect
                                helperText="Toute opération d’acquisition ou de location de brevets d’invention, de licence ou de procédés techniques permettant de participer au renforcement de la compétitivité et de la recherche scientifique."
                                selector = "transfert_technologique"
                                title = { i18n.t('form.transfert_technologique') }
                                defaultValue={transfert_technologique}
                            >
                                <MenuItem  key={1} value="Oui">
                                    Oui
                                </MenuItem>
                                <MenuItem  key={2} value="Non">
                                    Non
                                </MenuItem>
                            </NormalSelect>
                        </FormGroup>
                    </Col>
                }

                {
                    /*
                    <Col>
                    <GetResultBtn
                    linksTo="/result"
                    btnLabel="Lancer la simulation"
                    />
                </Col>
                     */
                }
            </Row>

        </div>
    );

}

