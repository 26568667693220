import {
    addPlafond,
    addTotal,
    find_item_amount,
    makeItAppears,
    textile_ecosys_amount,
    add_item_label,
    get_max,
    add_items,
    get_min,
    offer_title_rename, get_percent, to_million
} from "../functions";
import secteursData from "../secteursData";
export function industries_diverses(secteur,sousSecteur,simulationForm,offres,ecosystems) {
    const {
        montant_terain,
        montant_construction,
        mi,
        frais_assistance_technique,
        montant_equipements_neuf,
        montant_equipements_ocassion,
        nbre_demploi,
        transfert_technologique,
        zones_select,
        environnement,
        infrastructure_externe,
        transfert_mobilisation,
        formation_input,
        montant_divers, ca, ca1, ca2, ca3,
        ecosystem_items,
        frais_notaire,
        frais_enregistrement,
        frais_conservation,
        nature_du_project_choisi,
        eco_system_metier
    } = simulationForm
    const ecosystem_selected = parseInt(ecosystem_items);
    const metier =  parseInt(eco_system_metier);
    var current_secteur_offres = offres.industrie.industries_diverses;
    //Offre FPi
    if (zones_select !== "Franche" && nbre_demploi !== undefined && mi !== undefined && transfert_technologique !== undefined && environnement !== undefined) {
        var getOffre = false;
        var total = 0;
        if (parseInt(nbre_demploi) >= 250 ||
            parseInt(mi) >= 100 ||
            transfert_technologique.toLowerCase() === "oui" ||
            environnement.toLowerCase() === "oui"
        ) {
            //# montant terrain
            var ter = 0;
            if(parseInt(montant_terain) > 0){
                const montant_terrain = parseFloat(montant_terain)+to_million(parseFloat(frais_notaire))+to_million(parseFloat(frais_conservation))+to_million(parseFloat(frais_enregistrement));

                ter = montant_terrain * 20 / 100;

                current_secteur_offres = find_item_amount('terrain_construction', 'fpi', current_secteur_offres, (montant_terrain * 20 / 100))
            }



            //# montant infra
            const montant_infra_externe = parseInt(infrastructure_externe);
            var infra = 0;
            if(parseInt(infrastructure_externe) > 0){
                var mi5 = parseInt(mi) * 5 / 100;
                if (montant_infra_externe > mi5) {
                    infra = mi5;
                    current_secteur_offres = find_item_amount('infrastructure_externe', 'fpi', current_secteur_offres, infra)
                } else {
                    infra = montant_infra_externe;
                    current_secteur_offres = find_item_amount('infrastructure_externe', 'fpi', current_secteur_offres, infra)
                }
            }


            //# montant formation

            var form = 0;
            if(parseInt(formation_input) > 0){
                const montant_formation = parseInt(formation_input);
                form = montant_formation * 20 / 100;

                current_secteur_offres = find_item_amount('formation', 'fpi', current_secteur_offres, (montant_formation * 20 / 100));
            }



            //Total
            var total = ter + infra + form;
            //Plafond 5%
            var plafond = "Le plafond est de 5% MI";
            if (zones_select === "Rurale") {

                if (total > parseInt(mi) * 10 / 100) {
                    total = parseInt(mi) * 10 / 100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if (zones_select === "Suburbaine") {

                if (total > parseInt(mi) * 10 / 100) {
                    total = parseInt(mi) * 10 / 100;
                }
                plafond = "Le plafond est de 10% MI";
            }
            if (zones_select === "Urbaine") {

                if (total > parseInt(mi) * 5 / 100) {
                    total = parseInt(mi) * 5 / 100;
                }
                plafond = "Le plafond est de 5% MI";
            }

            if(total > 0){
                current_secteur_offres = addTotal(current_secteur_offres, "fpi", total);
                current_secteur_offres = addPlafond(current_secteur_offres, "fpi", plafond);

                current_secteur_offres = makeItAppears(current_secteur_offres, "fpi");
            }

        }
    }
    //Maroc PME
    const pme_valid = [ca, ca1, ca2, ca3].every(el => parseInt(el) < 200 && parseInt(el) > 10);
    if (pme_valid) {
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi) * 75 / 100;
        var montant_assigned = montant_equipements_pme;
        if (montant_equipements_pme > mi75) {
            montant_assigned = mi75;
        } else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned * 20) / 100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total + montant_assigned;
        current_secteur_offres = find_item_amount('equipement', 'maroc_pme', current_secteur_offres, montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi) * 20 / 100;
        const terrain_construction = parseInt(montant_terain) + parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if (terrain_construction > mi20) {
            montant_assigned = mi20;
        } else {
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned * 20) / 100;
        the_total = the_total + montant_assigned;
        current_secteur_offres = find_item_amount('terrain_construction', 'maroc_pme', current_secteur_offres, montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi) * 5 / 100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if (montant_divers_added > mi5) {
            montant_assigned = mi5;
        } else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned * 20) / 100;
        the_total = the_total + montant_assigned;
        if (the_total > 10) {
            the_total = 10;
        }
        current_secteur_offres = find_item_amount('divers', 'maroc_pme', current_secteur_offres, montant_assigned);
        current_secteur_offres = addTotal(current_secteur_offres, "maroc_pme", the_total);
        //Plafond 30M
        var plafond = "Le plafond est de: 10M";
        current_secteur_offres = addPlafond(current_secteur_offres, "maroc_pme", plafond);
        current_secteur_offres = makeItAppears(current_secteur_offres, "maroc_pme");
    }

    const tpe_valid = [ca, ca1, ca2, ca3].every(el => parseInt(el) <= 10);
    if (tpe_valid) {
        //# Equipement
        var the_total = 0
        const montant_equipements_pme = parseInt(montant_equipements_neuf);
        const mi75 = parseInt(mi) * 75 / 100;
        var montant_assigned = montant_equipements_pme;
        if (montant_equipements_pme > mi75) {
            montant_assigned = mi75;
        } else {
            montant_assigned = montant_equipements_pme;
        }
        montant_assigned = (montant_assigned * 30) / 100;
        console.log(montant_assigned);
        console.log(montant_equipements_neuf);
        console.log(mi75);
        the_total = the_total + montant_assigned;
        current_secteur_offres = find_item_amount('equipement', 'maroc_tpe', current_secteur_offres, montant_assigned);
        //# terrain_construction
        const mi20 = parseInt(mi) * 30 / 100;
        const terrain_construction = parseInt(montant_terain) + parseInt(montant_construction);
        montant_assigned = terrain_construction;
        if (terrain_construction > mi20) {
            montant_assigned = mi20;
        } else {
            montant_assigned = terrain_construction;
        }
        montant_assigned = (montant_assigned * 30) / 100;
        the_total = the_total + montant_assigned;
        current_secteur_offres = find_item_amount('terrain_construction', 'maroc_tpe', current_secteur_offres, montant_assigned);
        //# Divers montant_divers
        const mi5 = parseInt(mi) * 5 / 100;
        const montant_divers_added = parseInt(montant_divers);
        montant_assigned = montant_divers_added;
        if (montant_divers_added > mi5) {
            montant_assigned = mi5;
        } else {
            montant_assigned = montant_divers_added;
        }
        montant_assigned = (montant_assigned * 30) / 100;
        the_total = the_total + montant_assigned;
        current_secteur_offres = find_item_amount('divers', 'maroc_tpe', current_secteur_offres, montant_assigned);
        //Plafond 2M
        if (the_total > 2) {
            the_total = 2;
        }
        current_secteur_offres = addTotal(current_secteur_offres, "maroc_tpe", the_total);
        //Plafond 2M
        var plafond = "Le plafond est de: 2M";
        current_secteur_offres = addPlafond(current_secteur_offres, "maroc_tpe", plafond);
        current_secteur_offres = makeItAppears(current_secteur_offres, "maroc_tpe");
    }
    /*
    FDII OFFRE
     */
    const primes = [];
    const mi_input = parseFloat(mi);
    console.log("befores primes");
    console.log(primes);
    if(nature_du_project_choisi !== undefined && ecosystem_selected !== undefined){
        //Ecosystem Chimie verte
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_verte &&
                    nature_du_project_choisi === "valorisation_des_ressources" &&
                    metier === secteursData.metiers_industries_diverses.extraits_plantes_aromatiques &&
                    (parseFloat(mi) >= 80 || parseInt(nbre_demploi) >= 300)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),24),plafond:24});
                    primes.push({label:"Projet suivant",value:get_min(get_percent(mi_input,20),16),plafond:16});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }

                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_verte &&
                    nature_du_project_choisi === "valorisation_des_ressources" &&
                    metier === secteursData.metiers_industries_diverses.valorisation_du_caroube &&
                    (parseFloat(mi) >= 45 || parseInt(nbre_demploi) >= 120)){

                    primes.push({label:"Deux premiers projets structurants",value:get_min(get_percent(mi_input,20),10),plafond:10});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }

                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_verte &&
                    nature_du_project_choisi === "valorisation_des_ressources" &&
                    metier === secteursData.metiers_industries_diverses.colorants_naturels &&
                    (parseFloat(mi) >= 35 || parseInt(nbre_demploi) >= 80)){

                    primes.push({label:"Deux projets",value:get_min(get_percent(mi_input,30),10.5),plafond:10.5});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }

                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_verte &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.colorants_naturels &&
                    (parseFloat(mi) >= 25 )){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),10),plafond:10});
                    primes.push({label:"Les deux projets suivants",value:get_min(get_percent(mi_input,20),10),plafond:10});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }

                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_verte &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.aromes &&
                    (parseFloat(mi) >= 65 || parseInt(nbre_demploi) >= 130)){

                    primes.push({label:"2 premièrs projets",value:get_min(get_percent(mi_input,20),10),plafond:10});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                // Chimie organique
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_organique &&
                    nature_du_project_choisi === "sourcing" &&
                    metier === secteursData.metiers_industries_diverses.resine &&
                    (parseFloat(mi) >= 40 || parseInt(nbre_demploi) >= 96)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),12),plafond:12});
                    primes.push({label:"Deux projets suivants",value:get_min(get_percent(mi_input,20),10),plafond:10});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_organique &&
                    nature_du_project_choisi === "sourcing" &&
                    metier === secteursData.metiers_industries_diverses.peinture_technique &&
                    (parseFloat(mi) >= 100 || parseInt(nbre_demploi) >= 200)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),30),plafond:30});
                    primes.push({label:"Deux projets suivants",value:get_min(get_percent(mi_input,20),20),plafond:20});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_organique &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.detergence_liquide &&
                    (parseFloat(mi) >= 66 || parseInt(nbre_demploi) >= 160)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,20),13),plafond:13});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_organique &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.cosmetique_low_cost &&
                    (parseFloat(mi) >= 90 || parseInt(nbre_demploi) >= 490)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),27),plafond:27});
                    primes.push({label:"3 projets suivants",value:get_min(get_percent(mi_input,20),18),plafond:18});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_organique &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.produit_efficacite_energitique &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),10),plafond:10});
                    primes.push({label:"4 projets suivants",value:get_min(get_percent(mi_input,20),10),plafond:10});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.chimie_organique &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.phytosanitaire &&
                    (parseFloat(mi) >= 90 || parseInt(nbre_demploi) >= 270)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),27),plafond:27});
                    primes.push({label:"Projet suivant",value:get_min(get_percent(mi_input,20),18),plafond:18});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                //Médicaments
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.medicaments &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.principes_actif_packagine &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"Projet de fabrication de sérums et vaccins",value:get_min(get_percent(mi_input,30),30),plafond:30});
                    primes.push({label:"Projet de fabrication de flacons en verre",value:get_min(get_percent(mi_input,30),14),plafond:14});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.medicaments &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.fabrication_local &&
                    (parseFloat(mi) >= 50 || parseInt(nbre_demploi) >= 200)){

                    primes.push({label:"Deux projets de fabrication locale de médicaments anticancéreux",value:get_min(get_percent(mi_input,10),24),plafond:24});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.medicaments &&
                    nature_du_project_choisi === "strategiques_structurants" &&
                    metier === secteursData.metiers_industries_diverses.fabrication_local &&
                    (parseFloat(mi) >= 50 || parseInt(nbre_demploi) >= 200)){

                    primes.push({label:"Deux projets de production de médicaments innovants",value:get_min(get_percent(mi_input,30),30),plafond:30});
                    primes.push({label:"Deux projets de production de premiers génériques",value:get_min(get_percent(mi_input,30),30),plafond:30});
                    primes.push({label:"Deux projets de production de médicaments destinés à l’export",value:get_min(get_percent(mi_input,30),30),plafond:30});
                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.medicaments &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.biosimilaire &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"Deux projets de fill & finish (formulation) de biosimilaires",value:get_min(get_percent(mi_input,30),20),plafond:20});
                    primes.push({label:"Deux projets de production commerciale de biosimilaires avec transfert de technologie",value:get_min(get_percent(mi_input,30),30),plafond:30});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                //Dispositifs médicaux
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.dispositifs_medicaux &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.fabrication_appariels_autodiagnostique &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),10),plafond:10});
                    primes.push({label:"2 ème projet",value:get_min(get_percent(mi_input,25),10),plafond:10});
                    primes.push({label:"3 ème projet",value:get_min(get_percent(mi_input,20),10),plafond:10});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }

                if(ecosystem_selected === secteursData.ecosys_industries_diverses.dispositifs_medicaux &&
                    (nature_du_project_choisi === "pionnier" || nature_du_project_choisi === "locomotive") &&
                    metier === secteursData.metiers_industries_diverses.fabrication_equipements_electrique &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),30),plafond:30});
                    primes.push({label:"2 ème projet",value:get_min(get_percent(mi_input,25),30),plafond:30});
                    primes.push({label:"3 ème projet",value:get_min(get_percent(mi_input,20),30),plafond:30});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                // plastergie
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.plastergie &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.btp &&
                    (parseFloat(mi) >= 50 || parseInt(nbre_demploi) >= 200)){

                    primes.push({label:"1 èr projet tubes Polychlorure de vinyle/Polyéthylène (PVC/PE)",value:get_min(get_percent(mi_input,25),15),plafond:15});
                    primes.push({label:"1 èr projet tubes innovants Polypropylène réticulé (PPR, double paroi)",value:get_min(get_percent(mi_input,25),15),plafond:15});
                    primes.push({label:"1 èr projet profiles spéciaux",value:get_min(get_percent(mi_input,25),15),plafond:15});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.plastergie &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.emballage &&
                    (parseFloat(mi) >= 50 || parseInt(nbre_demploi) >= 200)){

                    primes.push({label:"1 èr unité de transformation du Polypropilène Bi orienté BOPP",value:get_min(get_percent(mi_input,25),15),plafond:15});
                    primes.push({label:"1 èr unité complexes tri couches/ imprimés",value:get_min(get_percent(mi_input,25),15),plafond:15});
                    primes.push({label:"1 èr unité d'injection IML",value:get_min(get_percent(mi_input,25),15),plafond:15});
                    primes.push({label:"1 èr unité d'injection paroi fine et caisse",value:get_min(get_percent(mi_input,25),15),plafond:15});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.plastergie &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.autres_projets &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"1 èr projet",value:get_min(get_percent(mi_input,30),15),plafond:15});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                //Matériaux de construction
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.materieux_construction &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.ceramique &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"Production de colorants/émaux",value:get_min(get_percent(mi_input,20),15),plafond:15});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.materieux_construction &&
                    nature_du_project_choisi === "valorisation_des_ressources" &&
                    metier === secteursData.metiers_industries_diverses.ceramique &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"Utilisation de combustibles de substitution au fuel et gaz propane",value:get_min(get_percent(mi_input,20),10),plafond:10});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.materieux_construction &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.acier &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"production de profilés reconstitués soudés (PRS)",value:get_min(get_percent(mi_input,30),30),plafond:30});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.materieux_construction &&
                    nature_du_project_choisi === "valorisation_des_ressources" &&
                    metier === secteursData.metiers_industries_diverses.acier &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){

                    primes.push({label:"Prime à l’investissement matériel et immatériel",value:get_min(get_percent(mi_input,70),2),plafond:2});


                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.materieux_construction &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.marbre &&
                    (parseFloat(mi) >= 50 || parseInt(nbre_demploi) >= 200)){

                    primes.push({label:"Trois premiers projets",value:get_min(get_percent(mi_input,25),10),plafond:10});
                    primes.push({label:"Sept projets restants",value:get_min(get_percent(mi_input,20),10),plafond:10});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.materieux_construction &&
                    nature_du_project_choisi === "locomotive" &&
                    metier === secteursData.metiers_industries_diverses.prefabrique &&
                    (parseFloat(mi) >= 50 || parseInt(nbre_demploi) >= 200)){


                    primes.push({label:"RAS",value:get_min(get_percent(mi_input,20),11),plafond:11});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.materieux_construction &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.ciment &&
                    (parseFloat(mi) >= 20 || parseInt(nbre_demploi) >= 50)){


                    primes.push({label:"RAS",value:get_min(get_percent(mi_input,10),10),plafond:10});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                // Valorisation des metaux
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.valorisation_des_metaux &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.valorisation_des_metaux){
                    primes.push({label:"2 projets de la 1ère métallurgie du cuivre",value:get_min(get_percent(mi_input,30),30),plafond:30});

                    primes.push({label:"2 projets de la 1ère métallurgie de l’aluminium",value:get_min(get_percent(mi_input,30),30),plafond:30});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.valorisation_des_metaux &&
                    nature_du_project_choisi === "pionnier" &&
                    metier === secteursData.metiers_industries_diverses.nouveaux_metiers){

                    primes.push({label:"2 projets intégrant la production de cadres et l’assemblage de bicyclettes",value:get_min(get_percent(mi_input,30),30),plafond:30});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                //OCP
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.ocp &&
                    nature_du_project_choisi === "strategiques_structurants" &&
                    metier === secteursData.metiers_industries_diverses.equipements_mines_chimie){

                    primes.push({label:"RAS",value:get_min(get_percent(mi_input,30),30),plafond:30});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.ocp &&
                    nature_du_project_choisi === "strategiques_structurants" &&
                    metier === secteursData.metiers_industries_diverses.chimie){

                    primes.push({label:"RAS",value:get_min(get_percent(mi_input,30),30),plafond:30});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
                if(ecosystem_selected === secteursData.ecosys_industries_diverses.ocp &&
                    nature_du_project_choisi === "strategiques_structurants" &&
                    metier === secteursData.metiers_industries_diverses.construction_industriels){

                    primes.push({label:"RAS",value:get_min(get_percent(mi_input,30),30),plafond:30});

                    current_secteur_offres = add_items(current_secteur_offres,'fdii_industries',primes);
                    current_secteur_offres = makeItAppears(current_secteur_offres, "fdii_industries");
                }
    }
    /*
    End FDII
     */
    return current_secteur_offres;
}