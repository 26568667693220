import React, {useState,useEffect,useRef} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import LoadingContent from "./LoadingContent";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import i18n from "../../../../helpers/i18n";

import JoditEditor from "jodit-react";
import {useStoreActions} from "easy-peasy";
import {Button} from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

export default function PagesList(props){
    const {setSnackbarState} = useStoreActions(actions => actions);
    const {slug} = props
    const editor = useRef(null)
    const [content, setContent] = useState('')
    const [title,setTitle] = useState('')
    const [disabled,setDisabled] = useState(false);
    const [loader,setLoading] = useState(false);
    const [page,setPage] = useState({});

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }
    const handleClick = async (e) => {
            e.preventDefault();
        setLoading(true);
        const formData = new FormData();

        formData.set('title',title);
        formData.set('description',content);


         await axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_HOSTING_URL + 'page/'+slug+'/update',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        }).then(res =>{
            setTitle(res.data.page.title);
            setContent(res.data.page.description);
             setLoading(false);
            setSnackbarState({state:true,message:i18n.t('form.updated')})
         });
    }
    const handleChange = (e) => {
            console.log(e.target.name);
            switch (e.target.name) {
                case 'title':
                    setTitle(e.target.value);
            }
    }
    useEffect(()  => {
        async function fetchPage(slug) {
            return await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL + 'page/single/'+slug)
        }
        fetchPage(slug).then(res => {
           if(res.data.result === true){
               setPage(res.data.page);
               setTitle(res.data.page.title);
               setContent(res.data.page.description);


           }
        });

    },[]);
    return (<React.Fragment>
        <Typography variant="h6" gutterBottom>
            Page
        </Typography>


                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="title"
                            name="title"
                            value={title || ''}
                            onChange={handleChange}
                            label={ i18n.t('form.title') }
                            fullWidth
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            disabled
                            id="slug"
                            name="slug"
                            value={slug || ''}
                            onChange={handleChange}
                            label={ i18n.t('form.slug') }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            config={config}
                            tabIndex={1}
                            onBlur={newContent => setContent(newContent)}
                            onChange={newContent => {}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>

                        <Button className="updatebutton" onClick={handleClick} disabled={disabled}>
                            {loader ? <div className="progress_loader"><CircularProgress  size={24} /></div>:i18n.t('form.update') }
                        </Button>
                    </Grid>
                </Grid>


    </React.Fragment>)
}