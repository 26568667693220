import React, {useState} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../helpers/i18n";
import {useStoreState} from "easy-peasy";
import {FormGroup} from "reactstrap";
import {Link} from "react-router-dom";

export default function SidebarSubscriber(props){
    const {user} = useStoreState(state => state.loggedInUser);
    const {current} = props;
    return (
        <ul className="list-unstyled components">
            <li className={current === "compte" ? "active":""}>
                <Link to="/investisseur/compte">Mon Compte</Link>
            </li>
            <li className={current === "simulations" ? "active":""}>
                <Link to="/investisseur/simulations">Mes etimations</Link>
            </li>
        </ul>
 )


}