import { action,  useActions } from 'easy-peasy';
export default {
    result:'ok',
    futurInvestor: {
        simulationForm : {
            nbre_administrateurs1:0,
            salaire_administrateurs1:0,
            nbre_administrateurs2:0,
            salaire_administrateurs2:0,
            isrent:false,
            montant_location:0,
            montant_terain:0,
            montant_construction:0,
            montant_equipements_neuf:0,
            montant_equipements_ocassion:0,
            infrastructure_externe:0,
            formation_input:0,
            transfert_mobilisation:0,
            montant_divers:0,
            federation_membre:'pas un membre',
            zones_select:"Urbaine",
            ville:"Rabat",
            environnement:"Non",
            transfert_technologique:"Non" ,
            extra_field_value: "Non",
            boeing_metiers: "Autre",
            frais_dinnovation_pour_operateurs:0,
            frais_innovation_agregateurs_produit_finis:0,
            frais_assistance_technique:0,
            superficier_foncier:0,
            superficier_construction:0,
            frais_d_innovation_immateriel:0,
            frais_amenagements:0,
            frais_reseau_distribution:0,
            type_foncier: "Cas d'acquisition ou construction des bâtiments",
            frais_notaire:0,
            frais_enregistrement:0,
            frais_conservation:0,
            ca_export_services_premier_deuxieme:0,
            ca_export_services_troisieme:0,
            ca_export_services_quatrieme:0,
            offshore_ir:0,
            offshore_rbi:0,
            offshore_taux_reduit:0,
            offshore_is:0,
            offshore_resultat_net_fiscal:0,
            offshore_montant_des_produits_imposable:0,
            nbre_operateurs:0,
            salaire_operateurs:0,
            nbre_administrateurs:0,
            salaire_administrateurs:0,
            offshoring_rbi:{
                types:[{
                    id:1,

                    inputs:[{
                        label:"Nombre d'emploi des personels d'atelier",
                        name:"nbre_personels_atelier",
                        unit:""
                    },{
                        label:"Salaire moyen des personels d'ateliers",
                        name:"salaire_personels_atelier",
                        unit:"DH"
                    }],
                    salaire:{de:3000,a:4000},
                    niveau:"personel_atelier",
                    percent:20,
                    percent_bigger_than_a:25,
                    percent_smaller_than_de:16,
                },{
                    id:2,
                    inputs:[{
                        label:"Nombre d'emploi des techniciens",
                        name:"nbre_techniciens",
                        unit:""
                    },{
                        label:"Salaire moyen des techniciens",
                        name:"salaire_techniciens",
                        unit:"DH"
                    }],
                    salaire:{de:4000,a:5000},
                    niveau:"technicien",
                    percent:23,
                    percent_bigger_than_a:25,
                    percent_smaller_than_de:18,
                },{
                    id:3,
                    inputs:[{
                        label:"Nombre d'emploi des ingénieurs",
                        name:"nbre_ingenieurs",
                        unit:""
                    },{
                        label:"Salaire moyen des ingénieurs",
                        name:"salaire_ingenieurs",
                        unit:"DH"
                    }],
                    salaire:{de:5000,a:7000},
                    niveau:"ingenieurs",
                    percent:30,
                    percent_bigger_than_a:25,
                    percent_smaller_than_de:30,
                }]
            },
            offshoring_is_ir:{
                ranges:[{
                    ca_from:0,
                    ca_to:10,
                    is:10000,
                    ir:20000
                },{
                    ca_from:10,
                    ca_to:20,
                    is:40009,
                    ir:60000
                },{
                    ca_from:20,
                    ca_to:30,
                    is:80000,
                    ir:90000
                }]
            },
        villes:["Al Hoceima",

            "Berkane",

            "Boujdour",

            "Chefchaouen",

            "Es-Semara",

            "Guelmim",

            "Laâyoune",

            "Larache",

            " Nador",

            " Oued-Eddahab ",

            " Oujda-Angad ",

            " Tanger-Assilah ",

            " Fahs-Bni-Makada ",

            " Tan-Tan ",

            " Taounate",

            " Taourirt ",

            " Tata",

            " Taza ",

            " Tétouan"]


        }
    },
    ecosystems:{},
    formationAnapec:{
        aeronautique:{
            tableHead:[{title:'Niveau'},{title:'Formation à l’Embauche (en DH)'},{title:'Formation Continue (en DH)'}],
            baremes:[{
                niveau:'Opérateur',
                embauche:16500,
                continue:5500
            },{
                niveau:'Technicien',
                embauche:33000,
                continue:16500
            },{
                niveau:'Middle management',
                embauche:10000,
                continue:33000
            },{
                niveau: 'Ingénieur/Cadre',
                embauche:33000,
                continue:33000
            }]

        },
        automobile:{
            tableHead:[{title:'Niveau'},{title:'Formation à l’Embauche (en DH)'},{title:'Formation Continue (en DH)'}],
            baremes:[{
                niveau:'Opérateur',
                embauche:16500,
                continue:5500
            },{
                niveau:'Technicien',
                embauche:33000,
                continue:16500
            },{
                niveau: 'Ingénieur/Cadre',
                embauche:33000,
                continue:33000
            }]

        }},

    lesoffres:{
        industrie : {
            aeronautique:[
                {
                    offre_allowed: false,
                    order : 1,
                    offre:"FDII",
                    abr:"FDII",
                    slug:"fdii",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "transfert_mobilisation",
                        item_label: "Transfert du savoir faire",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                     finalResult:"le plafond est de 5%: 5M"
                },
                {  offre_allowed: false,
                    order : 4,
                    offre:'<label class="offre_title">FDII Promotion de l’investissement</label><label class="offre_title_nd">Se fait par le secrétariat de la Commission des Investissements (AMDIE)</label>',
                    abr:"FPI",
                    slug:"fpi",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain",
                        item_amount: 0
                    },{
                        item_name: "formation",
                        item_label: "Formation",
                        item_amount: 0
                    },{
                        item_name: "infrastructure_externe",
                        item_label: "Infrastructure Externe",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {
                    offre_allowed: false,
                    order : 2,
                    offre:"FH2",
                    abr:"FH2",
                    slug:"fh2",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Acquisation du foncier",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "vide",
                        item_label: "",
                        item_amount: null
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {

                    offre_allowed: false,
                    order : 3,
                    offre:"Maroc PME(IMTIAZ CROISSANCE)",
                    abr:"MAROC PME",
                    slug:"maroc_pme",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"Maroc TPE (ISTITMAR CROISSANCE)",
                    abr:"MAROC TPE",
                    slug:"maroc_tpe",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                }],
            automobile:[
                {

                    offre_allowed: false,
                    order : 5,
                    offre:"FDII",
                    abr:"FDII Automobile",
                    slug:"fdii",
                    items:[{
                        item_name: "prime_retenue",
                        item_label: "Prime à l’investissement retenue",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"Non plafonné"
                },
                {  offre_allowed: false,
                    order : 4,
                    offre:'<label class="offre_title">FDII Promotion de l’investissement</label><label class="offre_title_nd">Se fait par le secrétariat de la Commission des Investissements (AMDIE)</label>',
                    abr:"FPI",
                    slug:"fpi",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain",
                        item_amount: 0
                    },{
                        item_name: "formation",
                        item_label: "Formation",
                        item_amount: 0
                    },{
                        item_name: "infrastructure_externe",
                        item_label: "Infrastructure Externe",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {
                    offre_allowed: false,
                    order : 2,
                    offre:"FH2",
                    abr:"FH2",
                    slug:"fh2",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Acquisation du foncier",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "vide",
                        item_label: "",
                        item_amount: null
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 3,
                    offre:"Maroc PME(IMTIAZ CROISSANCE)",
                    abr:"MAROC PME",
                    slug:"maroc_pme",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"Maroc TPE (ISTITMAR CROISSANCE)",
                    abr:"MAROC TPE",
                    slug:"maroc_tpe",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                }

            ],
            naval:[
                {
                    offre_allowed: false,
                    order : 1,
                    offre:"FDII",
                    abr:"FDII",
                    slug:"fdii",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "transfert_mobilisation",
                        item_label: "Transfert du savoir faire",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention FDII Textile",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%(: 5M"
                },
                {  offre_allowed: false,
                    order : 4,
                    offre:'<label class="offre_title">FDII Promotion de l’investissement</label><label class="offre_title_nd">Se fait par le secrétariat de la Commission des Investissements (AMDIE)</label>',
                    abr:"FPI",
                    slug:"fpi",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain",
                        item_amount: 0
                    },{
                        item_name: "formation",
                        item_label: "Formation",
                        item_amount: 0
                    },{
                        item_name: "infrastructure_externe",
                        item_label: "Infrastructure Externe",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {
                    offre_allowed: false,
                    order : 2,
                    offre:"FH2",
                    abr:"FH2",
                    slug:"fh2",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Acquisation du foncier",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "vide",
                        item_label: "",
                        item_amount: null
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 3,
                    offre:"Maroc PME(IMTIAZ CROISSANCE)",
                    abr:"MAROC PME",
                    slug:"maroc_pme",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"Maroc TPE (ISTITMAR CROISSANCE)",
                    abr:"MAROC TPE",
                    slug:"maroc_tpe",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                }

            ],
            industries_diverses:[
                {
                    offre_allowed:false,
                    offre:"FDII Industries diverses",
                    abr:"FDII",
                    slug:"fdii_industries",
                    items:[],
                total:{
                    total_label: "Montant global de la subvention",
                        total_amount: 0
                },
                    finalResult:"plafond"
                },
                {  offre_allowed: false,
                    order : 4,
                    offre:'<label class="offre_title">FDII Promotion de l’investissement</label><label class="offre_title_nd">Se fait par le secrétariat de la Commission des Investissements (AMDIE)</label>',
                    abr:"FPI",
                    slug:"fpi",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain",
                        item_amount: 0
                    },{
                        item_name: "formation",
                        item_label: "Formation",
                        item_amount: 0
                    },{
                        item_name: "infrastructure_externe",
                        item_label: "Infrastructure Externe",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {
                    offre_allowed: false,
                    order : 2,
                    offre:"FH2",
                    abr:"FH2",
                    slug:"fh2",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Acquisation du foncier",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "vide",
                        item_label: "",
                        item_amount: null
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 3,
                    offre:"Maroc PME(IMTIAZ CROISSANCE)",
                    abr:"MAROC PME",
                    slug:"maroc_pme",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"Maroc TPE (ISTITMAR CROISSANCE)",
                    abr:"MAROC TPE",
                    slug:"maroc_tpe",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                }

            ],
            textile:[
                {

                    offre_allowed: false,
                    order : 5,
                    offre:"FDII",
                    abr:"FDII Textile",
                    slug:"fdii_textile",
                    items:[{
                        item_name: "prime_retenue",
                        item_label: "Prime à l’investissement retenue",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"Non plafonné"
                },
                {

                    offre_allowed: false,
                    order : 5,
                    offre:"Contribution FIT",
                    abr:"Contribution FIT",
                    slug:"fit",
                    items:[{
                        item_name: "prime_retenue",
                        item_label: "Prime à l’investissement retenue",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"Non plafonné"
                },
                {  offre_allowed: false,
                    order : 4,
                    offre:'<label class="offre_title">FDII Promotion de l’investissement</label><label class="offre_title_nd">Se fait par le secrétariat de la Commission des Investissements (AMDIE)</label>',
                    abr:"FPI",
                    slug:"fpi",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain",
                        item_amount: 0
                    },{
                        item_name: "formation",
                        item_label: "Formation",
                        item_amount: 0
                    },{
                        item_name: "infrastructure_externe",
                        item_label: "Infrastructure Externe",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {
                    offre_allowed: false,
                    order : 2,
                    offre:"FH2",
                    abr:"FH2",
                    slug:"fh2",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Acquisation du foncier",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "vide",
                        item_label: "",
                        item_amount: null
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 3,
                    offre:"Maroc PME(IMTIAZ CROISSANCE)",
                    abr:"MAROC PME",
                    slug:"maroc_pme",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"Maroc TPE (ISTITMAR CROISSANCE)",
                    abr:"MAROC TPE",
                    slug:"maroc_tpe",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"FDII",
                    abr:" Ecosystèmes textile",
                    slug:"fdii_ecosystemes_textile",
                    items:[{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "immaterial",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "reseau_distribution",
                        item_label: "Réseau distribution",
                        item_amount: 0
                    },{
                        item_name: "transfert_mobilisation",
                        item_label: "Transfert du savoir faire",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention ",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                }

            ],
            agroalimentaire:[
                {

                    offre_allowed: false,
                    order : 5,
                    offre:"FDIA",
                    abr:"FDII",
                    slug:"fdii",
                    items:[{
                        item_name: "prime_retenue",
                        item_label: "Prime à l’investissement retenue",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"Non plafonné"
                },
                {  offre_allowed: false,
                    order : 4,
                    offre:'<label class="offre_title">FDII Promotion de l’investissement</label><label class="offre_title_nd">Se fait par le secrétariat de la Commission des Investissements (AMDIE)</label>',
                    abr:"FPI",
                    slug:"fpi",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain",
                        item_amount: 0
                    },{
                        item_name: "formation",
                        item_label: "Formation",
                        item_amount: 0
                    },{
                        item_name: "infrastructure_externe",
                        item_label: "Infrastructure Externe",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {
                    offre_allowed: false,
                    order : 2,
                    offre:"FH2",
                    abr:"FH2",
                    slug:"fh2",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Acquisation du foncier",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "vide",
                        item_label: "",
                        item_amount: null
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 3,
                    offre:"Maroc PME(IMTIAZ CROISSANCE)",
                    abr:"MAROC PME",
                    slug:"maroc_pme",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"Maroc TPE (ISTITMAR CROISSANCE)",
                    abr:"MAROC TPE",
                    slug:"maroc_tpe",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                }
            ],
            offshoring:[
                {

                    offre_allowed: false,
                    order : 5,
                    offre:"FDII",
                    abr:"FDII",
                    slug:"fdii",
                    items:[{
                        item_name: "contribution_liee_ir",
                        item_label: "Contribution de l'Etat liée à l'IR",
                        item_amount: 0
                    },{
                        item_name: "contribution_aux_frais_de_la_formation",
                        item_label: "Contribution de l'Etat aux frais de la formation",
                        item_amount: 0
                    },{
                        item_name: "contribution_liee_is",
                        item_label: "Contribution de l'Etat liée à l'IS",
                        item_amount: 0
                    },{
                        item_name: "contribution_ir_amelioree",
                        item_label: "Contribution IR améliorée",
                        item_amount: 0
                    },{
                        item_name: "prime_materiel_immateriel",
                        item_label: "Prime à l'Investissement  matériel et immatériel ",
                        item_amount: 0
                    },{
                        item_name: "prime_a_lacroissance",
                        item_label: "Prime à la croissance",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"Non plafonné"
                },
                {  offre_allowed: false,
                    order : 4,
                    offre:'<label class="offre_title">FDII Promotion de l’investissement</label><label class="offre_title_nd">Se fait par le secrétariat de la Commission des Investissements (AMDIE)</label>',
                    abr:"FPI",
                    slug:"fpi",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain",
                        item_amount: 0
                    },{
                        item_name: "formation",
                        item_label: "Formation",
                        item_amount: 0
                    },{
                        item_name: "infrastructure_externe",
                        item_label: "Infrastructure Externe",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },
                {
                    offre_allowed: false,
                    order : 2,
                    offre:"FH2",
                    abr:"FH2",
                    slug:"fh2",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Acquisation du foncier",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "vide",
                        item_label: "",
                        item_amount: null
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 3,
                    offre:"Maroc PME(IMTIAZ CROISSANCE)",
                    abr:"MAROC PME",
                    slug:"maroc_pme",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                },{

                    offre_allowed: false,
                    order : 6,
                    offre:"Maroc TPE (ISTITMAR CROISSANCE)",
                    abr:"MAROC TPE",
                    slug:"maroc_tpe",
                    items:[{
                        item_name: "terrain_construction",
                        item_label: "Terrain / Construction",
                        item_amount: 0
                    },{
                        item_name: "equipement",
                        item_label: "Equipement",
                        item_amount: 0
                    },{
                        item_name: "divers",
                        item_label: "Divers",
                        item_amount: 0
                    }],
                    total:{
                        total_label: "Montant global de la subvention",
                        total_amount: 0
                    },
                    finalResult:"le plafond est de 5%MI: 5M"
                }
            ]

        }
    },
    step0: {
        entrepriseTypes :
            {
                title: "Type d'entreprise",
                id_field:"type_entreprise",
                items :[
                    {
                        id: 'marocaine',
                        text: 'Entreprise Marocaine'

                    },
                    {
                        id: 'etrangere',
                        text: 'Entreprise Etrangère'
                    }
                ]},
        filiales :
            {
                title: "Vous avez une filiale au Maroc?",
                id_field:"filiale",
                items :[
                    {
                        id: 'oui_filiale',
                        text: 'Oui',
                        value: 'oui'

                    },
                    {
                        id: 'non_filiale',
                        text: 'Non',
                        value:'non'
                    }
                ]},
        extensions :
            {
                title: "Extension",
                id_field:"extension",
                items :[
                    {
                        id: 'oui_extension',
                        text: 'Oui'

                    },
                    {
                        id: 'non_extension',
                        text: 'Non'
                    }
                ]},
    },
    step2 :{
        parents: [{}],
        children:[{}],
        metiers_selected:{
            id_field:"metiers_field",
            items:[{}]
        }

    },
    user:{
        isLogged: false,
        id:''
    },
    loggedInUser:{
        user:{}
    },
    snackbar:{
        state:false,
        message:""
    },
    emailError:{
        state:false
    },
    popupLogin:{
        state:false
    },
    stepsErrors:{},
    // Actions
    setStepsError: action((state,data) => {
        state.stepsErrors = data
    }),
    setEmailError: action((state,data) => {
        state.emailError.state = data
    }),
    setPopupLogin: action((state,data) => {
        state.popupLogin.state = data
    }),
    setSnackbarState: action((state,data) => {
        state.snackbar = data
    }),
    setLoggedInUser: action((state,data) => {
        state.loggedInUser = data
    }),
    setLoggedInUserUpdate: action((state,data) => {
        state.loggedInUser.user[data.name] = data.value;
    }),
    setLogged: action((state,data) =>{
        state.user = data
    }),
    addValue : action((state,item) => {
        state.futurInvestor.simulationForm[item.name] = item.value
    }),
    addEcoSystem : action((state,data) =>{
        state.ecosystems = data
    }),
    addParents: action((state,item) => {
        state.step2.parents = item
    }),
    addChildren: action((state,item) => {
        state.step2.children = item
    }),
    addMetiers: action((state,item) => {
        state.step2.metiers_selected = item
    }),
    addOffresAero: action((state,item) => {
        state.lesoffres.industrie.aeronautique = item
    }),
    addOffresAuto: action((state,item) => {
        state.lesoffres.industrie.automobile = item
    }),
    addOffresNaval: action((state,item) => {
        state.lesoffres.industrie.naval = item
    }),
    addOffresTextile: action((state,item) => {
        state.lesoffres.industrie.textile = item
    }),
    addOffresAgro: action((state,item) => {
        state.lesoffres.industrie.agroalimentaire = item
    }),
    addOffshoring: action((state,item) => {
        state.lesoffres.industrie.offshoring = item
    }),
    addIndustriesDiverses: action((state,item) => {
        state.lesoffres.industrie.industries_diverses = item
    }),


}