import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import Header from '../Header';
import Footer from '../Footer';
import { Table, Container, Row, Col } from 'reactstrap';
import PageSection from '../components/PageSection';
import InnerPageSection from '../components/InnerPageSection';
import ContactForm from '../components/ContactForm';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { autoMobile } from '../../helpers/secteursFunctions/autoMobile';
import {
  bestCombinaison,
  sousSecteurOffre,
  round,
  idustriesDiveresesOffres,
} from '../../helpers/functions';
import secteursData from '../../helpers/secteursData';
import Offre from '../components/Offre';
import AnapecForm from '../AnapecForm';
import GetResultBtn from '../components/GetResultBtn';
import Offre2 from '../components/Offre2';
export default function Result(props) {
  const parents_list = useStoreState((state) => state.step2.parents);
  const children_list = useStoreState((state) => state.step2.children);
  const {
    secteur_items,
    secteur_children,
    federation_membre,
    mi,
    ecosystem_items,
  } = useStoreState((state) => state.futurInvestor.simulationForm);
  const { simulationForm } = useStoreState((state) => state.futurInvestor);
  const { lesoffres, ecosystems, formationAnapec } = useStoreState(
    (state) => state
  );
  const { metiers_selected } = useStoreState((state) => state.step2);
  /*if(parents_list || parents_list !== undefined){
            return (<Redirect to = "" />);
        }*/
  var secteur = [];
  if (parents_list.items !== undefined && parents_list.items.length > 0) {
    secteur = parents_list.items.find(
      (el) => el.id === parseInt(secteur_items)
    );
  } else {
    props.history.push('/');
    //RELOAD PAGE
    window.location.reload();
  }

  const sousSecteur = children_list.subs.find(
    (el) => el.id === parseInt(secteur_children)
  );

  const [combinaison, setCombinaison] = useState([]);
  const [offres, setOffres] = useState([]);

  useEffect(() => {
    const loadData = () => {
      console.log('ecosystem');
      console.log(ecosystems);
      setOffres(lesoffres[secteur.slug][sousSecteur.slug]);
      var offres_retenue = [];
      offres_retenue = sousSecteurOffre(
        secteur,
        sousSecteur,
        simulationForm,
        lesoffres,
        ecosystems,
        metiers_selected
      );
      setOffres(offres_retenue);
      if (secteur_children !== secteursData.industries_diverses) {
        setCombinaison(
          bestCombinaison(
            lesoffres[secteur.slug][sousSecteur.slug],
            sousSecteur,
            ecosystems,
            simulationForm
          )
        );
      } else {
        idustriesDiveresesOffres(
          offres,
          sousSecteur,
          ecosystems,
          simulationForm
        );
      }
    };
    loadData();
  }, []);
  const getTotal = () => {
    var total = 0;
    combinaison.map((item, key) => (total = total + item.item_amount));
    return total;
  };
  const countOffres = (offres) => {
    var counter = 0;
    console.log('count offres');
    console.log(offres);
    if (offres !== undefined) {
      offres.map((offre, key) => {
        counter = offre.offre_allowed ? counter + 1 : counter;
      });
    }

    return counter;
  };

  return (
    <div className='App inner_page'>
      <Header></Header>
      <InnerPageSection
        sectionClass='PageResultSection header_page'
        sectionContainer='resultContainer '
        pageTitle={'Offres Valable (' + sousSecteur.text + ')'}
        bottomTitle='Redémarrer la simulation'
        offres={offres}
        secteurChildren={secteur_children}
      ></InnerPageSection>
      <div className='container-fluid'>
        <div className='row'>
          <Col md={12}>
            {countOffres(offres) > 0 ? (
              <h4 className='page_heading'>
                Ce résultat est estimatif, le ministère ne s'engage pas à
                fournir une contribution sur ce niveau, la subvention définitive
                sera arrêtée suite à l'examen de votre projet d'investissement.
              </h4>
            ) : null}
            {secteur_children === secteursData.automobile &&
            (ecosystem_items === secteursData.ecosys_auto.psa ||
              ecosystem_items === secteursData.ecosys_auto.renault) ? (
              <h4 className='page_heading'>
                Sous Réserve de la validation du projet par la commission et le
                constructeur.
              </h4>
            ) : null}
          </Col>
        </div>
      </div>
      <Container>
        {countOffres(offres) > 0 ? (
          <Row>
            {offres.map((offre, key) =>
              offre.offre_allowed &&
              offre.slug === 'fdii_industries' &&
              secteur_children === secteursData.industries_diverses ? (
                <Offre2
                  key={key + offre.slug}
                  offre_name={offre.offre}
                  abr={offre.abr}
                  offre={offre}
                  finalResult={offre.finalResult}
                  columnMd={6}
                />
              ) : (
                offre.total.total_amount > 0 && (
                  <Offre
                    key={key + offre.slug}
                    offre_name={offre.offre}
                    abr={offre.abr}
                    offre_items={offre.items}
                    total_label={offre.total.total_label}
                    total_amount={offre.total.total_amount}
                    finalResult={offre.finalResult}
                    columnMd={6}
                  />
                )
              )
            )}
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <h5 className='page_heading notify_msg'>
                Merci pour votre consultation, malheureusement, vous n'êtes
                admissible à aucune offre. Nous vous invitons à consulter nos
                règles et critères d'éligibilité via les liens situés sous la
                page pour refaire une simulation positive.
              </h5>
            </Col>
          </Row>
        )}
        {countOffres(offres) > 0 &&
        secteur_children !== secteursData.industries_diverses ? (
          <Row className='margintop40'>
            <Col md={12}>
              <Table className='table_result' striped>
                <thead>
                  <tr>
                    <th colSpan={3} className='bigtable_title'>
                      Meilleure combinaison de subvention
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {combinaison.map((item, key) =>
                    item.item_amount !== 0 ? (
                      <tr key={key + 'cmb'}>
                        <td>
                          <span className='circle_icon'>{key + 1}</span>{' '}
                          {item.item_name}
                        </td>
                        <td className='td_value'>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.offreTitle,
                            }}
                          />
                        </td>
                        <td className='td_value'>
                          {round(item.item_amount, 2)}M
                        </td>
                      </tr>
                    ) : null
                  )}
                  <tr key='total_de_combinaison'>
                    <td colSpan={2}>
                      Montant total du combinaison de subvention
                    </td>
                    <td className='td_value'>{round(getTotal(), 2)}M</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : null}
        {federation_membre.toLowerCase() !== 'pas un membre' ||
        (parseInt(mi) >= 100 && countOffres(offres) > 0) ? (
          <Row>
            <Col md={12}>
              <h4 className='left_align_it'>A cela s'ajoute:</h4>
            </Col>
          </Row>
        ) : null}
        <Row>
          {parseInt(mi) >= 100 && countOffres(offres) > 0 ? (
            <Col md={12}>
              <p className='exon_paragraphe'>
                Convention d’investissement avec le gouvernement
                <ul>
                  <li>
                    <span>Exonérations douanières</span>: Exonération des droits
                    d’importation sur l’acquisition des biens d’équipement
                    matériels et outillages.
                  </li>
                  <li>
                    <span>Exonérations fiscales</span> : Exonération de la TVA à
                    l'importation des biens d’équipement, matériels et
                    outillages, parties , pièces détachées et accessoires
                    pendants 36 mois, ainsi qu’une exonération de la TVA sur les
                    biens d’investissement, acquis à l’intérieur pendant une
                    durée 36 mois.
                  </li>
                </ul>
              </p>
            </Col>
          ) : null}
        </Row>
        <div className='row'>
          {federation_membre.toLowerCase() !== 'pas un membre' &&
          countOffres(offres) > 0 ? (
            <AnapecForm
              sousSecteur={secteur_children}
              formationAnapec={formationAnapec}
            />
          ) : null}
        </div>
        <div className='row'>
          <Col>
            <div className='gotolink'>
              <p>Vous pouvez déposer votre dossier sur le lien suivant:</p>
              <p>
                <a
                  href='http://fondsinvest.mcinet.gov.ma/moovapps/easysite/miicen/extranet/menu/depot-et-suivi-de-la-demande/soumettez-ma-demande'
                  target='_blank'
                >
                  Dépôt du dossier
                </a>
              </p>
            </div>
            <div>
              <GetResultBtn
                linksTo='/'
                btnLabel='Redémarrer la simulation'
                offres={offres}
                secteurChildren={secteur_children}
              />
            </div>
          </Col>
        </div>
      </Container>
      <PageSection
        sectionClass='contactSection'
        sectionContainer='contactContainer'
        bigTitle='VOUS AVEZ'
        subTitle='DES QUESTIONS?'
        pageDescription='ENTREZ EN CONTACT AVEC L’UN DE NOS SPÉCIALISTES ET ENTAMEZ VOTRE PROCESSUS D’EXPANSION AUJOURD’HUI
                          '
      >
        <ContactForm></ContactForm>
      </PageSection>

      <Footer />
    </div>
  );
}
