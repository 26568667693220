import React, {useEffect, useState} from 'react';
import PageSection from "../../../components/PageSection";
import axios from 'axios';
export default function ContactArchive(props){
    const [inbox, setInbox] = useState([]);
    return <div className="content_wrapper">
        <PageSection
            key={3}
            sectionClass="innerBlocboxSection"
            sectionContainer="innerBlockboxContainer"
            bigTitle="Boite"
            subTitle="de réception"/>
    </div>
}