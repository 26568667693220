import React, {Component} from 'react';
import {Card, CardBody, CardTitle, Col, CardText,CardImg} from "reactstrap";
import {Link} from "react-router-dom";
import styled from 'styled-components';
class GridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            hoverSrc:''
        }
    }
    toggleHover = e =>  {
        this.setState({
            hover: !this.state.hover
        })
    }

    render(){
        const {avatarClass} = this.props
        const {gridAvatar} = this.props
        const {bgColor} = this.props
        const {gridAvatarHover} = this.props
        const {hoverColor} = this.props
        const {gridImgAlt} = this.props
        const {gridTitle} = this.props
        const {gridText} = this.props
        const {gridLink} = this.props
        const {md} = this.props
        const {lg} = this.props
        const {sm} = this.props
        const {xs} = this.props
        var linkStyle;
        var backgroundColor = {
            backgroundColor:bgColor
        }
        if (this.state.hover) {
            this.hoverSrc = gridAvatarHover;
            linkStyle = {color: hoverColor,cursor: 'pointer'}
        }else{
            this.hoverSrc = gridAvatar;
        }

        return (

            <Col lg={lg} md={md} xs={xs} sm={sm}>
                <div className="gridItemHolder" style={backgroundColor}>
                    <Card onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                        <div className="imageGridHolder">
                            <Link to={gridLink}>
                                <CardImg  className={avatarClass} src={this.hoverSrc} alt={gridImgAlt}  />
                            </Link>
                        </div>



                        <CardBody>
                            <Link to={gridLink}>
                             <CardTitle style={linkStyle}>{gridTitle}</CardTitle>
                            </Link>
                            <CardText  style={linkStyle}>{gridText}</CardText>
                        </CardBody>
                    </Card>
                </div>
            </Col>

        );
    }

}
export default GridItem;