import React, {useState} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../helpers/i18n";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from 'axios';
import LogoutBox from '../../components/LogoutBox';
export default function Navbar(){
    const {user} = useStoreState(state => state.loggedInUser);
    return ( <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav navbar-nav ml-auto">
                        <li className="nav-item active logoutbox">
                            <LogoutBox/>
                        </li>
                    </ul>
                </div>

            </div>
        </nav>)


}