import React, {useEffect} from 'react';
import i18n from "../../helpers/i18n";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PageSection from "./PageSection";
import {Card, CardBody, CardTitle} from "reactstrap";
import SignInForm from './SignInForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import RegisterForm from './RegisterForm';
import Grid from "@material-ui/core/Grid/Grid";
import Link from "@material-ui/core/Link/Link";
import axios from "axios";
import {useStoreActions, useStoreState} from "easy-peasy";
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #5299C7',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function LoginBox(){
    const classes = useStyles();
    const [showLogin, setShowLogin] = React.useState(false);
    const {popupLogin} = useStoreState(state => state);
    const {setPopupLogin} = useStoreActions(actions => actions);
    const [showRegister, setShowRegister] = React.useState(false);
    const [showForgotPassword, setShowForgotPassword] = React.useState(false);
    const handleOpen = () => {
        console.log('clicked');

        setPopupLogin(true);
    };

    const handleClose = () => {
        setPopupLogin(false);
    };
    const handleForgotPassword = (e) => {
        e.preventDefault();
        setShowLogin(true);
        setShowRegister(true);
        setShowForgotPassword(false);
    }
    const handleRegister = (e) => {
        e.preventDefault();
        setShowForgotPassword(true);
        setShowLogin(true);
        setShowRegister(false);
    }
    const handleShowLogin = (e) => {
        e.preventDefault();
        setShowRegister(true);
        setShowForgotPassword(true);
        setShowLogin(false);
    }
    useEffect(() => {
        setShowRegister(true);
        setShowForgotPassword(true);
        setShowLogin(false);
    },[]);
    return  (
            <>
                <button className="login login_btn"  onClick={handleOpen} >{ i18n.t('template.login') } </button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={popupLogin.state}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={popupLogin.state}>
                        <div className="modal_container">
                            <Card body>
                                <CardTitle>
                                    <div className="modal_title_holder">
                                        <h4 className="head_title">{ i18n.t('template.login_title_up') }</h4>
                                        <h5 className="sub_headtitle">{ i18n.t('template.login_title_down') }</h5>
                                    </div>

                                </CardTitle>
                                <CardBody className="pageHeadDescription">
                                        {showLogin === false && <SignInForm/>}
                                        {showRegister === false && <RegisterForm/>}
                                        {showForgotPassword === false  && <ForgotPasswordForm/>}

                                    <Grid container>
                                        {showForgotPassword === true && <Grid item xs>
                                            <Link href="#" className="centering_item" onClick={handleForgotPassword} variant="body2">
                                                { i18n.t('login.forgotpassword') }
                                            </Link>
                                        </Grid>}
                                        {showRegister === true && <Grid item xs>
                                            <Link href="#" className="centering_item" onClick={handleRegister} variant="body2">
                                                { i18n.t('login.signup') }
                                            </Link>
                                        </Grid>}
                                        {showLogin === true && <Grid item xs>
                                            <Link href="#" className="centering_item" onClick={handleShowLogin} variant="body2">
                                                { i18n.t('login.login') }
                                            </Link>
                                        </Grid>}
                                    </Grid>
                                </CardBody>
                            </Card>
                        </div>


                    </Fade>
                </Modal>
            </>
    );

}
export default LoginBox;