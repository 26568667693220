import React, { useState, useEffect  } from "react";
import { Route, Redirect } from "react-router";
import {  Row, Col,FormGroup } from 'reactstrap';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CdropDown from './components/CdropDown';
import i18n from "../helpers/i18n";
import ChildDropDown from "./components/ChildDropDown";
import NormalSelect from "./components/NormalSelect";
import ExtraFields from "./components/ExtraFields";
import secteursData from "../helpers/secteursData";
import {getInputsOffshoring,getNatureDeProjet,contributionIr,validateEmail} from "../helpers/functions";

import axios from 'axios';
import {useStoreActions, useStoreState} from "easy-peasy";

export default function Step2() {
    //pour offshore rbi
    const {simulationForm} = useStoreState(state => state.futurInvestor);
    //end offshore
    const {addValue,addEcoSystem,addChildren,addMetiers,setStepsError} = useStoreActions(actions => actions);
    // get secteurs parents from redux store
    const {parents,children,metiers_selected} = useStoreState(state => state.step2);
    const {ecosystems} = useStoreState(state => state);
    const {secteur_children,
        secteur_items,
        ecosystem_items,
        eco_system_metier,
        extra_field_value,
        federation_membre,
        nbre_demploi,
        mi,
        taux_integration,
        ca_export_services_premier_deuxieme,
        offshore_taux_reduit,
        offshore_resultat_net_fiscal,
        offshore_montant_des_produits_imposable,
        autre_emploi_auto,
        nature_du_project_choisi,
        boeing_metiers} = useStoreState(state => state.futurInvestor.simulationForm);
    //error detection empty inputs


    const handleChangeValue = async (e) => await addValue({name: e.target.name,value:e.target.value});
    const [offshoringInputs,setOffshoringInputs] = useState({});
    const [offshore,setOffshore]= useState(false);
   const [secteurSelectedSubs,setSecteurSelectedSubs] = useState({
           "id_field": "secteur_children",
            "federation":"",
           "subs": [
               {
                   "text": "",
                   "id": ""
               }
           ]
       }
   );
    const [boeingMetiers,setBoeingMetiers] = useState({
       items:[{
           text:"Pièces usinées \"grandes dimensions\"",
           value:"Pièces usinées \"grandes dimensions\""
       },{
           text:"Connecteurs",
           value:"Connecteurs"
       },{
           text:"Panneaux roulés (Dim 6m à 1m - aluminium -traitement chimique/chemimeling)",
           value:"Panneaux roulés (Dim 6m à 1m - aluminium -traitement chimique/chemimeling)"
       },{
           text:"Vérins et Equipements hydrauliques",
           value:"Vérins et Equipements hydrauliques"
       },{
           text:"Usinage sur blocs hydrauliques",
           value:"Usinage sur blocs hydrauliques    "
       },{
           text:"Pignonnerie",
           value:"Pignonnerie"
       },{
           text:"Rectification (de pièces usinées- rectification plane)",
           value:"Rectification (de pièces usinées- rectification plane)"
       },{
           text:"Extrusion aluminium",
           value:"Extrusion aluminium"
       },{
           text:"Fabricant d'outillages (bâtis/plaques/pour chaudronnerie grande dimension/ moules /titane /base nickel/chaine de production...)",
           value:"Fabricant d'outillages (bâtis/plaques/pour chaudronnerie grande dimension/ moules /titane /base nickel/chaine de production...)"
       },{
           text:"Quincaillerie/fixations/fasteners (rivets/fixation/visserie/rotules/bagues/écrous flottants…)",
           value:"Quincaillerie/fixations/fasteners (rivets/fixation/visserie/rotules/bagues/écrous flottants…)"
       },{
           text:"Abrasifs/outils coupants",
           value:"Abrasifs/outils coupants"
       },{
           text:"Peinture aéronautique",
           value:"Peinture aéronautique"
       },{
           text:"Récupération de déchets/recyclage spécifiques (composite/récupération des boues résultant du traitement des bains TS et reste peintures.",
           value:"Récupération de déchets/recyclage spécifiques (composite/récupération des boues résultant du traitement des bains TS et reste peintures."
       },{
           text:"Maintenance et étalonnage de machines d'essai / de test CND",
           value:"Maintenance et étalonnage de machines d'essai / de test CND"
       },{
           text:" Fabricant de fours (pyrométrie)",
           value:" Fabricant de fours (pyrométrie)"
       },{
           text:"Roulements / Bearings",
           value:"Roulements / Bearings"
       },{
           text:"Fabricant de joints",
           value:"Fabricant de joints."
       },{
           text:"Moussage",
           value:"Moussage"
       },{
           text:"Fonderie",
           value:"Fonderie"
       },{
           text:"Affinerie aluminium",
           value:"Affinerie aluminium"
       },{
           text:"Fabricant de pièces structurales en composite",
           value:"Fabricant de pièces structurales en composite"
       },{
           text:"Tissus pré-imprégnés et nid d'abeille",
           value:"Tissus pré-imprégnés et nid d'abeille"
       },{
           text:"Résines de densification/colles",
           value:"Résines de densification/colles"
       },{
           text:"Mousses d'isolation",
           value:"Mousses d'isolation"
       },{
           text:"Contrôle Ultra son avec CSCANN",
           value:"Contrôle Ultra son avec CSCANN"
       },{
           text:"Tissus carbone",
           value:"Tissus carbone"
       },{
           text:"Réparateur de pièces composites",
           value:"Réparateur de pièces composites"
       },{
           text:"Thermoformage",
           value:"Thermoformage"
       },{
           text:"Fabrication de matelas d’isolation thermo-acoustiques",
           value:"Fabrication de matelas d’isolation thermo-acoustiques"
       },{
           text:"Traitement de surface métaux durs ou techniques (nickel/argenture/chromage dur/cadmiage sur inox/phosphatation…)",
           value:"Traitement de surface métaux durs ou techniques (nickel/argenture/chromage dur/cadmiage sur inox/phosphatation…)"
       },{
           text:"Grenaillage/shotpening",
           value:"Grenaillage/shotpening"
       },{
           text:"Traitements pour revêtements + traitement électrochimiques ",
           value:"Traitements pour revêtements + traitement électrochimiques"
       },{
           text:"Traitement de surface OAD/PTFE – polyamide",
           value:"Traitement de surface OAD/PTFE – polyamide"
       },{
           text:"Gaines Plastiques ou rétractables",
           value:"Gaines Plastiques ou rétractables"
       },{
           text:"Capteurs/potentiomètres(sensors)",
           value:"Capteurs/potentiomètres(sensors)"
       },{
           text:"Fils de cuivre émaillés",
           value:"Fils de cuivre émaillés"
       },{
           text:"Mèches cuivre Aero",
           value:"Mèches cuivre Aero"
       },{
           text:"Rubans Polyamide / Ruban PTFE  KAPTON ZDPF",
           value:"Rubans Polyamide / Ruban PTFE  KAPTON ZDPF"
       },{
           text:"Découpe fine (alliage cuivreux)",
           value:"Découpe fine (alliage cuivreux)"
       },{
           text:"Fabricant de PCB électroniques (circuit imprimés)\n",
           value:"Fabricant de PCB électroniques (circuit imprimés)\n"
       },{
           text:"Injection plastique aéronautique",
           value:"Injection plastique aéronautique"
       },{
           text:"Tôlerie emboutie",
           value:"Tôlerie emboutie"
       },{
           text:"Refurbishing/réparation éléments intérieurs (sièges/galleys/….)",
           value:"Refurbishing/réparation éléments intérieurs (sièges/galleys/….)"
       },{
           text:"Test hydrostatiques (pour bouteilles oxygène/extincteurs)",
           value:"Test hydrostatiques (pour bouteilles oxygène/extincteurs)"
       },{
           text:"Développement nouveaux matériaux",
           value:" Développement nouveaux matériaux"
       },{
           text:"Moteurs avions(Aubes de moteur)",
           value:"Moteurs avions(Aubes de moteur)"
       },{
           text:"Moteurs avions(Disques de turbine)",
           value:"Moteurs avions(Disques de turbine)"
       },{
           text:"Moteurs avions(Tubes/canalisations hydrauliques)",
           value:"Moteurs avions(Tubes/canalisations hydrauliques)"
       },{
           text:"Moteurs avions(Pièces forgées)",
           value:"Moteurs avions(Pièces forgées)"
       },{
           text:"Moteurs avions(Autres (entrées d'air, carters, pièces de turbines, de chambre de compression, rouets, diffuseurs, rotors, ailettes, stators, vannes, arbres, roulements, attaches, pompes, régulateurs, injecteurs, etc…))",
           value:"Moteurs avions(Autres (entrées d'air, carters, pièces de turbines, de chambre de compression, rouets, diffuseurs, rotors, ailettes, stators, vannes, arbres, roulements, attaches, pompes, régulateurs, injecteurs, etc…))"
       },{
           text:"Intérieurs cabines(Fabricant de siège d'avions)",
           value:" Intérieurs cabines(Fabricant de siège d'avions)"
       },{
           text:"Intérieurs cabines(Fabricants de galleys (meubles et cuisines avions))",
           value:"Intérieurs cabines(Fabricants de galleys (meubles et cuisines avions))"
       },{
           text:"Intérieurs cabines(Coffres bagage)",
           value:"Intérieurs cabines(Coffres bagage)"
       },{
           text:"Intérieurs cabines(Hublots)",
           value:"Intérieurs cabines(Hublots)"
       },{
           text:"Trains d'atterrissage(Fabrications de pièces spécifiques et sous-ensembles de trains d'atterrissages (vérins, bielles, servocommandes, détecteurs de position, caissons, roues et freins, etc…))",
           value:"Trains d'atterrissage(Fabrications de pièces spécifiques et sous-ensembles de trains d'atterrissages (vérins, bielles, servocommandes, détecteurs de position, caissons, roues et freins, etc…))"
       },{
           text:"Trains d'atterrissage(Haut de la chaine de valeur)",
           value:"Trains d'atterrissage(Haut de la chaine de valeur)"
       },{
           text:"Trains d'atterrissage(Recherche et développement /expertise support / Nouvelles technologies pour préparer l'avenir (ex robotique/intelligence artificielle/3D/avion du futur électrique/thermoplastie/titane…))",
           value:"Trains d'atterrissage(Recherche et développement /expertise support / Nouvelles technologies pour préparer l'avenir (ex robotique/intelligence artificielle/3D/avion du futur électrique/thermoplastie/titane…))"
       },,{
           text:"Autre",
           value:"Autre"
       }]
    });
   const [ecosystem,setEcosystem] = useState({
        "id_field":"",
            "items":[{
            "ecosystem":"",
            "id":""
        }]
    }
);
   const [metiers,setMetiers] = useState({
           "id_field":"",
           "items":[]
       }
   );
    const [natureDuProjet,setNatureDuProjet] = useState({
            "id_field":"nature_du_projet",
            "items":[]
        }
    );
   const [extraFields,setExtraFields] = useState([]);
   const [secteurCols,setSecteurCols] = useState(6);
   const [ecosystemChoisi,setEcosystemChoisi] = useState(ecosystem_items);
   const [sousSecteurChoisi,setSousSecteurChoisi] = useState(secteur_children);

    const [normalDropState,setNormalDropState] = useState(false);
    const [federation_membres,setFederation_membres] = useState( {
        id_field:"federation_membre",
        items :[
            {
                id: 'pas un membre',
                text: 'Pas un membre'
            }
        ]});
    //Dropdownlist to be initialized true
    const [childSelect,setChildSelect] = useState(true);
    const [parentSelect,setParentSelect]  = useState(true);
    const [ecoSystemSelect,setEcosystemSelect]  = useState(true);
    //Dropdownlist end
    const handleFocus = e => {

        if(parseInt(e.target.value) === 0){
            addValue({name: e.target.name,value:''});
        }
    }
    const handleBlur = e => {

        if(e.target.value.length === 0){
            console.log('onblur');
            addValue({name: e.target.name,value:0});
        }
    }
    const handleChange = (id) => {
        //console.log(children.subs);
        if(children.subs){
            setSecteurSelectedSubs(children);
            setChildSelect(false);
            addChildren(children);
        }else{
            if(id){
                axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'secteur/children/api/'+id)
                    .then(res => {

                        setSecteurSelectedSubs(res.data);
                        setChildSelect(false);
                        addChildren(res.data);

                    });
            }
        }


    }
    // industrie devirses ecosys/metier/nature projet
    const handleMetierChange = (metier) => {
        if(secteursData.industries_diverses === sousSecteurChoisi){
            setNatureDuProjet({
                "id_field":"nature_du_projet",
                "items":getNatureDeProjet(ecosystemChoisi,metier)});
        }
    }

    const handleNatureChange = (nature_du_projet) => {
        console.log(nature_du_projet);
        addValue({name: "nature_du_project_choisi",value:nature_du_projet});
    }
    const handleBoeingMetiers = (metiers_boeing) => {
        addValue({name: "boeing_metiers",value:metiers_boeing});
    }
    const childhandleChange = (sousSecteur) => {
        setSousSecteurChoisi(sousSecteur);
        axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'ecosystem/items/api/'+sousSecteur)
            .then(res => {
                if(res.data){
                    setEcosystem(res.data);
                    addEcoSystem(res.data);
                    setEcosystemSelect(false);
                    //check if enabled in industrie divers
                    if(sousSecteur === secteursData.industries_diverses){
                        setNormalDropState(true)

                    }
                }

            });

            const sSecteur = secteurSelectedSubs.subs.find(el => el.id === sousSecteur);
            //Get fields of soussecteur
            setExtraFields(sSecteur.fields);
            //Get Federation of souSecteur
            if(sSecteur.federation !== "empty" && sSecteur.federation !== ""){
                setFederation_membres({
                    id_field:"federation_membre",
                    items :[
                        {
                            id:sSecteur.federation,
                            text:sSecteur.federation
                        },
                        {
                            id: 'pas un membre',
                            text: 'Pas un membre'
                        }
                    ]});
            }else{
                setFederation_membres({
                    id_field:"federation_membre",
                    items :[
                        {
                            id: 'pas un membre',
                            text: 'Pas un membre'
                        }
                    ]});
            }
            //Get Metiers of sousSeteur
        setMetiers({
            id_field:"metiers_field",
            items:[]
        });
            if(sSecteur.metiers.length > 0){
                setMetiers({
                    id_field:"metiers_field",
                    items:sSecteur.metiers
                });
                addMetiers({
                    id_field:"metiers_field",
                    items:sSecteur.metiers
                });
            }

    }


    const handleEcoSystemChange = (ecosystem) => {
        setEcosystemChoisi(ecosystem);
        // return des metiers by ecvosystem
        if(secteur_children === secteursData.industries_diverses){
            setNormalDropState(false);
            console.log('normal dropdown on changed');
            console.log(normalDropState);
        }

        if(ecosystem === secteursData.offshoring_ecosys.crm){
            //Opérateurs
             setOffshoringInputs(getInputsOffshoring("crm"));
             console.log(offshoringInputs);


        }
        if(ecosystem === secteursData.offshoring_ecosys.bpo){
            //Opérateurs
            //Administrateurs
            setOffshoringInputs(getInputsOffshoring("bpo"));

        }
        if(ecosystem === secteursData.offshoring_ecosys.ito){
            //Opérateurs
            // Techniciens
            // Ingénieurs
            setOffshoringInputs(getInputsOffshoring("ito"));

        }
        if(ecosystem === secteursData.offshoring_ecosys.eso){
            //Opérateurs
            //Opérateurs
            // Techniciens
            // Ingénieurs
            setOffshoringInputs(getInputsOffshoring("eso"));

        }
        if(ecosystem === secteursData.offshoring_ecosys.kpo){
            //Opérateurs
            //Opérateurs
            // Techniciens
            // Ingénieurs
            setOffshoringInputs(getInputsOffshoring("kpo"));

        }
        axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'metier/api/'+ecosystem)
            .then(res => {
                if(res.data.items.length > 0){
                    setMetiers(res.data);
                    addMetiers(res.data);
                }

        });





    }
    const getEmplyees_data = (inputName,ReduxStore) =>{
        switch (inputName) {
            case 'nbre_administrateurs1':
                return ReduxStore.nbre_administrateurs1;
            case 'nbre_administrateurs2':
                return ReduxStore.nbre_administrateurs2;
            case 'salaire_administrateurs1':
                return ReduxStore.salaire_administrateurs1;
            case 'salaire_administrateurs2':
                return ReduxStore.salaire_administrateurs2;
            case 'nbre_managers1':
                return ReduxStore.nbre_managers1;
            case 'nbre_managers2':
                return ReduxStore.nbre_managers2;
            case 'salaire_managers1':
                return ReduxStore.salaire_managers1;
            case 'nbre_techniciens':
                return ReduxStore.nbre_techniciens;
            case 'salaire_techniciens':
                return ReduxStore.salaire_techniciens;
            case 'nbre_ingenieurs':
                return ReduxStore.nbre_ingenieurs;
            case 'salaire_ingenieurs':
                return ReduxStore.salaire_ingenieurs;
        }
    }
    useEffect(
         () => {
            // Start it off by assuming the component is still mounted
            let mounted = true;
            let exploit_data = false;
            const loadData = async () => {
                if (mounted) {
                    //test getting parent secteur ID
                    console.log("start getting data");
                    if(secteur_items !== undefined){
                        exploit_data = true;
                        await axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'secteur/children/api/'+secteur_items)
                            .then(res => {

                                setSecteurSelectedSubs(res.data);
                                setChildSelect(false);
                                addChildren(res.data);

                            });
                    }
                    //check getting sous secteur id
                    if(secteur_children !== undefined){
                      await  axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'ecosystem/items/api/'+secteur_children)
                            .then(res => {
                                if(res.data){
                                    setEcosystem(res.data);
                                    addEcoSystem(res.data);
                                    setEcosystemSelect(false);
                                    //initialization si le cas secteur offshoring
                                    if(parseInt(secteur_children) === secteursData.offshoring){
                                        setOffshore(true);
                                        if(ecosystem_items !== undefined){
                                            console.log("items step 2");
                                            console.log(res.data.items);
                                            const row = res.data.items.find((item) => item.id === parseInt(ecosystem_items));
                                           console.log('row slug');


                                                    console.log(getInputsOffshoring(row.slug));
                                                   setOffshoringInputs(getInputsOffshoring(row.slug));




                                        }


                                    }
                                    //check if enabled in industrie divers
                                    if(secteur_children === secteursData.industries_diverses){
                                        setNormalDropState(true)

                                    }
                                }

                            });
                    }
                    if(ecosystem_items !== undefined){
                        axios.get(process.env.REACT_APP_BACKEND_HOSTING_URL+'metier/api/'+ecosystem_items)
                            .then(res => {
                                if(res.data.items.length > 0){
                                    setMetiers(res.data);
                                    addMetiers(res.data);
                                }

                            });
                    }


                    //end testing getting data
                    if(secteursData.industries_diverses === sousSecteurChoisi){
                        setNatureDuProjet({
                            "id_field":"nature_du_projet",
                            "items":getNatureDeProjet(ecosystemChoisi,eco_system_metier)});
                    }

                        // end checking
                        setParentSelect(false);



                    //Loading previous data
                    if(secteur_children && exploit_data === false){
                        const sSecteurSelected = children.subs.find(el => el.id === secteur_children);
                        //Get fields of soussecteur
                        setExtraFields(sSecteurSelected.fields);
                        //Get Federation of souSecteur
                        if(sSecteurSelected.federation !== "empty" && sSecteurSelected.federation !== ""){
                            setFederation_membres({
                                id_field:"federation_membre",
                                items :[
                                    {
                                        id:sSecteurSelected.federation,
                                        text:sSecteurSelected.federation
                                    },
                                    {
                                        id: 'pas un membre',
                                        text: 'Pas un membre'
                                    }
                                ]});
                        }else{
                            setFederation_membres({
                                id_field:"federation_membre",
                                items :[
                                    {
                                        id: 'pas un membre',
                                        text: 'Pas un membre'
                                    }
                                ]});
                        }

                        //Get Metiers of sousSeteur
                        if(metiers_selected.items.length > 0){
                            setMetiers(metiers_selected);

                        }
                        //get saved ecosystems
                        setEcosystem(ecosystems);
                        setEcosystemSelect(false);

                    }
                }
            };
            loadData();

            return () => {
                // When cleanup is called, toggle the mounted variable to false
                mounted = false;
            };
        },
        []
    );

    return (
        <div className="step step1">
            <Row form>

                <Col md={6}>

                    <FormGroup>

                        <CdropDown
                            onSelectSecteur={handleChange}
                            selector = {parents.id_field}
                            title = { i18n.t('form.secteur') }
                            parentSelect={parentSelect}
                            defaultValue = {secteur_items}
                        >
                            {
                                parents.items.map((item) => (
                                    <MenuItem  key={item.id} value={item.id}>{item.text}</MenuItem>
                                ))

                            }
                        </CdropDown>

                    </FormGroup>
                </Col>
                <Col md={secteurCols}>
                    <FormGroup>
                        <ChildDropDown
                            onSelectSousSecteur={childhandleChange}
                            selector = {secteurSelectedSubs.id_field}
                            title = { i18n.t('form.sous_secteur') }
                            defaultValue = {secteur_children}
                            childSelect={childSelect}

                        >

                            {secteurSelectedSubs.subs.map((item) => (
                                <MenuItem  key={item.id} value={item.id}>{item.text}</MenuItem>
                            ))}
                        </ChildDropDown>
                    </FormGroup>
                </Col>
                {federation_membres.items.length > 1 ?
                    <Col md={6}>

                        <FormGroup>
                            <NormalSelect
                                list = {federation_membres.items}
                                selector = {federation_membres.id_field}
                                title = { i18n.t('form.federation_membre') }
                                defaultValue = {federation_membre}


                            />
                        </FormGroup>
                    </Col> : null
                }

                {extraFields.length > 0 &&

                extraFields.map((field) => (

                    <ExtraFields defaultValue={extra_field_value} key={field.id} extraField={field}/>
                ))

                }
                {extra_field_value === "Oui" && secteur_children === 2 ?

                    <Col  md={6}>
                        <NormalSelect
                            onThisChanged ={handleBoeingMetiers}
                            selector = "boeing_metiers"
                            title = { i18n.t('form.boeing_metier') }
                            defaultValue = {boeing_metiers}
                            normalSelect= ""
                        >
                            {boeingMetiers.items.map((item, key) => (
                                <MenuItem  key={key} value={item.text}>{item.value}</MenuItem>
                            ))}
                        </NormalSelect>
                    </Col>:null
                }
                <Col md={6}>
                    <FormGroup>
                        <NormalSelect
                            onThisChanged ={handleEcoSystemChange}
                            selector = {ecosystem.id_field}
                            title = { i18n.t('form.eco_system') }
                            defaultValue = {ecosystem_items}
                            normalSelect={ecoSystemSelect}
                        >
                            {ecosystem.items.map((item) => (
                                <MenuItem  key={item.id} value={item.id}>{item.ecosystem}</MenuItem>
                            ))}
                        </NormalSelect>
                    </FormGroup>
                </Col>

                {metiers.items.length > 0 && parseInt(ecosystem_items) !== parseInt(secteursData.ecosys_auto.autre) ?

                    <Col md={6}>
                        <FormGroup>
                            <NormalSelect
                                onThisChanged ={handleMetierChange}
                                selector = "eco_system_metier"
                                title = { i18n.t('form.eco_system_metier') }
                                defaultValue = {eco_system_metier}
                            >
                                {metiers.items.map((item) => (
                                    <MenuItem  key={item.id} value={item.id}>{item.metier}</MenuItem>
                                ))}


                            </NormalSelect>


                        </FormGroup>
                    </Col>: null
                }
                {parseInt(ecosystem_items) === parseInt(secteursData.ecosys_auto.autre) &&
                <Col md={6}>
                    <FormGroup>
                        <TextField
                            id="autre_emploi_auto"
                            label={i18n.t('form.autre_emploi_auto')}
                            type="text"
                            defaultValue={autre_emploi_auto}
                            value={autre_emploi_auto}
                            name="autre_emploi_auto"
                            onChange={handleChangeValue}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </FormGroup>
                </Col>
                }

                <Col md={6}>
                    <FormGroup>
                        <TextField
                            id="nbre_demploi"
                            label= {secteur_children !== secteursData.offshoring ? i18n.t('form.nbre_demploi') : i18n.t('form.nbre_demploi4ans') }
                            type="number"
                            defaultValue={nbre_demploi}
                            value={nbre_demploi}
                            name="nbre_demploi"
                            onChange={handleChangeValue}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <TextField
                            id="input_mi"
                            label={ i18n.t('form.mi') }
                            helperText="Montant investi en million dirham Marocain"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">MAD</InputAdornment>,
                            }}
                            type="number"
                            defaultValue={mi}
                            value={mi}
                            name="mi"
                            onChange={handleChangeValue}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </FormGroup>
                </Col>
                {secteur_children === secteursData.industries_diverses &&
                <Col md={6}>
                    <FormGroup>
                        <NormalSelect
                            onThisChanged = {handleNatureChange}
                            selector = {natureDuProjet.id_field}
                            title = { i18n.t('form.natureDuProjet') }
                            defaultValue = {nature_du_project_choisi}
                            disabled = {normalDropState}

                        >
                            {natureDuProjet.items.map((item) => (
                                <MenuItem  key={item.slug} value={item.slug}>{item.title}</MenuItem>
                            ))}
                        </NormalSelect>
                    </FormGroup>
                </Col>
                }
                {secteur_children === secteursData.automobile ?
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="taux_integration"
                                name="taux_integration"
                                onChange={handleChangeValue}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.taux_integration') }
                                helperText=""
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={taux_integration}
                                value={taux_integration}
                            />
                        </FormGroup>
                    </Col>  : null
                }
                {/*secteur_children === secteursData.offshoring || offshore === true ?
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="ca_export_services_premier_deuxieme"
                                name="ca_export_services_premier_deuxieme"
                                onChange={handleChangeValue}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.ca_export_services_premier_deuxieme') }
                                helperText={ i18n.t('form.ca_export_services_premier_deuxieme_description') }
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={ca_export_services_premier_deuxieme}
                                value={ca_export_services_premier_deuxieme}
                            />
                        </FormGroup>
                    </Col>
                    :null */}

                {/*ecosystem_items !== undefined && offshoringInputs.length > 0 ?
                    offshoringInputs.map((item) => (
                    (item.inputs.map((input) => (
                        <Col md={6} key={"input"+input.id}>
                            <FormGroup>
                                <TextField
                                    id={input.name}
                                    name={input.name}
                                    onChange={handleChangeValue}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    label= { i18n.t('form.'+input.name) }
                                    helperText=""
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{input.unit}</InputAdornment>,
                                    }}
                                    type="number"
                                    defaultValue={getEmplyees_data(input.name,simulationForm)}
                                    value={getEmplyees_data(input.name,simulationForm)}
                                />
                            </FormGroup>
                        </Col>
                    )))
                )):null */}
                {/*secteur_children === secteursData.offshoring || offshore === true  ?
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="offshore_resultat_net_fiscal"
                                name="offshore_resultat_net_fiscal"
                                onChange={handleChangeValue}
                                onFocus={handleFocus}
                                 onBlur={handleBlur}
                                label= { i18n.t('form.offshore_resultat_net_fiscal') }

                                InputProps={{
                                    endAdornment: <InputAdornment position="end">DH</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={offshore_resultat_net_fiscal}
                                value={offshore_resultat_net_fiscal}
                            />
                        </FormGroup>
                    </Col>
                    :null */}
                {/*secteur_children === secteursData.offshoring || offshore === true  ?
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="offshore_montant_des_produits_imposable"
                                name="offshore_montant_des_produits_imposable"
                                onChange={handleChangeValue}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.offshore_montant_des_produits_imposable') }

                                InputProps={{
                                    endAdornment: <InputAdornment position="end">DH</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={offshore_montant_des_produits_imposable}
                                value={offshore_montant_des_produits_imposable}
                            />
                        </FormGroup>
                    </Col>
                    :null */}
                { /*secteur_children === secteursData.offshoring || offshore === true  ?
                    <Col md={6}>
                        <FormGroup>
                            <TextField
                                id="offshore_taux_reduit"
                                name="offshore_taux_reduit"
                                onChange={handleChangeValue}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                label= { i18n.t('form.offshore_taux_reduit') }
                                helperText=""
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                type="number"
                                defaultValue={offshore_taux_reduit}
                                value={offshore_taux_reduit}

                            />
                        </FormGroup>
                    </Col>
                    :null */}


            </Row>

        </div>
    );
}
