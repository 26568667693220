import React, {useState} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../helpers/i18n";
import {useStoreState} from "easy-peasy";
import {FormGroup} from "reactstrap";
import {Link} from "react-router-dom";

export default function Sidebar(props){
    const {user} = useStoreState(state => state.loggedInUser);
    const {current} = props;
    return (
        <ul className="list-unstyled components">
            <li className={current === "compte" ? "active":""}>
                <Link to="/administrateur/compte">Mon Compte</Link>
            </li>
            <li  className={current === "utilisateurs" ? "active":""}>
                <Link to="/administrateur/utilisateurs">Gestion utilisateurs</Link>
            </li>
            <li  className={current === "pages" ? "active":""}>
                <Link to="/administrateur/pages">Gestion des pages</Link>
            </li>
            <li className={current === "edition" ? "active":""}>
                <Link to="/administrateur/edition">Edition des Simulations</Link>
            </li>
            {
                /*  <li  className={current === "contactarchive" ? "active":""}>
                <Link to="/administrateur/contactarchive">boîte de réception</Link>
            </li> */
            }
        </ul>
   )


}