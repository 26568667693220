import React, {useState} from 'react';
import Icon from "@material-ui/core/Icon/Icon";
import i18n from "../../../../helpers/i18n";
import {useStoreState} from "easy-peasy";
import LinearProgress from '@material-ui/core/LinearProgress';
import {FormGroup} from "reactstrap";
import {Link} from "react-router-dom";

export default function LoadingContent(props){
    const {user} = useStoreState(state => state.loggedInUser);
    return <div className="content_wrapper">
                <h4 className="loading_text">
                    { i18n.t('site.loadingContent') }
                </h4>
                <LinearProgress />
            </div>


}