import React from 'react';
import {Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
const PageSection = (props) => {
    return (
        <section  className={props.sectionClass}>

            <Container  className={props.sectionContainer}>
                <Row>
                    <Col>

                        <Card body>
                            <CardTitle>
                                <div className="titleHolder">
                                    <h4 className="head_title">{props.bigTitle}</h4>
                                    <h5 className="sub_headtitle">{props.subTitle}</h5>
                                </div>

                            </CardTitle>
                            <CardBody className="pageHeadDescription">
                                {props.pageDescription}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {props.children}
                </Row>

            </Container>
        </section>
    );
}
export default PageSection;