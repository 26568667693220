import React from 'react';
import Icon from '@material-ui/core/Icon';
export default class VerticalMenu extends React.Component{
    constructor(props) {
        super(props);
        console.log(this.props)
    }
    render(){
        const{list} = this.props
        const{title} = this.props
        return(
            <div className="verticalMenuItems">
                <h4>{title}</h4>
                <ul>
                    {list.map((item) => (
                        <li  key={item.id} ><a href={item.link} ><Icon>play_arrow</Icon>{item.text}</a></li>
                    ))}
                </ul>
            </div>
        );
    }
}
