import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import CdatePicker from './components/CdatePicker';
import i18n from '../helpers/i18n';
import NormalSelect from './components/NormalSelect';
import Notificator from './components/Notificator';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import RootRef from '@material-ui/core/RootRef';
import secteursData from '../helpers/secteursData';
import { add_user, validateEmail } from '../helpers/functions';
import axios from 'axios';
export default function Step1() {
  const { addValue, addParents } = useStoreActions(actions => actions);
  const { filiales, extensions } = useStoreState(state => state.step0);
  const { isLogged } = useStoreState(state => state.user);
  const { user } = useStoreState(state => state.loggedInUser);
  const { emailError } = useStoreState(state => state);
  const { setEmailError } = useStoreActions(actions => actions);
  const { setPopupLogin } = useStoreActions(actions => actions);
  const { parents } = useStoreState(state => state.step2);
  const { formationAnapec } = useStoreState(state => state);
  const {
    nom,
    prenom,
    raison_sociale,
    activite_actuelle,
    email,
    filiale,
    extension,
    ca1,
    ca2,
    ca3,
    ca,
    date_decreation,
    btnActive,
    secteur_children
  } = useStoreState(state => state.futurInvestor.simulationForm);
  //CreateRefs
  let emailRef = React.createRef();
  //End refs creation
  //const handleChange = async (e) => await addValue({name: e.target.name,value:e.target.value});
  const [errors, setErrors] = useState({});
  const handleChange = e => {
    console.log('email ref');
    console.log(emailRef);

    addValue({ name: e.target.name, value: e.target.value });
    if (
      e.target.name === 'email' &&
      validateEmail(e.target.value) === true &&
      isLogged === false
    ) {
      axios
        .get(
          process.env.REACT_APP_BACKEND_HOSTING_URL +
            'investisseur/isRegistred?email=' +
            e.target.value
        )
        .then(function(response) {
          const result = response.data.result;
          if (result === true) {
            setErrors({ email: true });
            setEmailError(true);
          } else {
            setErrors({ email: false });
            setEmailError(false);
          }
        })
        .catch(function(error) {});
    }
  };
  const handleLoginClick = e => {
    console.log('cliked');
    setPopupLogin(true);
  };
  useEffect(() => {

    if (isLogged === true) {
      addValue({ name: 'nom', value: user.nom });
      addValue({ name: 'prenom', value: user.prenom });
      setErrors({ email: false });
    }
  }, []);

  return (
    <div className='step step1'>
      {isLogged !== true && (
        <Row form>
          <Col md={6}>
            <FormGroup>
              <TextField
                id='usr_nom'
                name='nom'
                label={i18n.t('form.nom')}
                defaultValue={nom}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <TextField
                id='usr_prenom'
                name='prenom'
                label={i18n.t('form.prenom')}
                defaultValue={prenom}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      {isLogged !== true && (
        <Row form>
          <Col md={6}>
            <FormGroup>
              <TextField
                id='usr_raison_sociale'
                name='raison_sociale'
                label={i18n.t('form.raisonsociale')}
                defaultValue={raison_sociale}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <RootRef rootRef={emailRef}>
                <TextField
                  id='usr_email'
                  name='email'
                  label={i18n.t('form.email')}
                  type='email'
                  defaultValue={email}
                  onChange={handleChange}
                />
              </RootRef>
            </FormGroup>
            {errors.email && isLogged !== true && (
              <Notificator className='input-feedback login_notification'>
                {i18n.t('login.already_have_account')}
                <Button className='getconnected' onClick={handleLoginClick}>
                  {' '}
                  {i18n.t('login.getconnected')}{' '}
                </Button>
              </Notificator>
            )}
          </Col>
        </Row>
      )}
      <Row form>
          {secteur_children !== secteursData.offshoring &&
            <Col md={6}>
                <FormGroup>
                    <TextField
                        id='activite_actuelle'
                        name='activite_actuelle'
                        label={i18n.t('form.activite_actuelle')}
                        defaultValue={activite_actuelle}
                        onChange={handleChange}
                    />
                </FormGroup>
            </Col>}
          {secteur_children !== secteursData.offshoring &&
              <Col md={6}>
              <FormGroup>
                <NormalSelect
                  list={filiales.items}
                  selector={filiales.id_field}
                  title={filiales.title}
                  defaultValue={filiale}
                />
              </FormGroup>
            </Col>}
      </Row>
      {filiale === 'oui_filiale' ? (
        <Row form>
          <Col md={6}>
            <FormGroup>
              <CdatePicker
                id='date_decreation'
                label_title={i18n.t('form.date_creation')}
                id_selector='date_decreation'
                defaultValue={date_decreation}
              />
            </FormGroup>
          </Col>

            {secteur_children !== secteursData.offshoring && <Col md={6}>
            <FormGroup>
              <NormalSelect
                list={extensions.items}
                selector={extensions.id_field}
                title={extensions.title}
                defaultValue={extension}
              />
            </FormGroup>
          </Col>}
        </Row>
      ) : null}
      <Row form>
          {secteur_children !== secteursData.offshoring &&
              <Col md={6}>
              <FormGroup>
                <TextField
                  id='input_ca'
                  label={i18n.t('form.ca')}
                  helperText="Chiffre d'affaire en million dirham Marocain"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>MAD</InputAdornment>
                    )
                  }}
                  type='number'
                  defaultValue={ca}
                  name='ca'
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>}

          {secteur_children !== secteursData.offshoring &&
              <Col md={6}>
              <FormGroup>
                <TextField
                  id='input_ca1'
                  label={
                    filiale === 'oui_filiale'
                      ? i18n.t('form.ca1m')
                      : i18n.t('form.ca1p')
                  }
                  helperText="Chiffre d'affaire en million dirham Marocain"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>MAD</InputAdornment>
                    )
                  }}
                  type='number'
                  defaultValue={ca1}
                  name='ca1'
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>}
      </Row>
      <Row form>
        {secteur_children !== secteursData.offshoring && (
          <Col md={6}>
            <FormGroup>
              <TextField
                id='input_ca2'
                label={
                  filiale === 'oui_filiale'
                    ? i18n.t('form.ca2m')
                    : i18n.t('form.ca2p')
                }
                helperText="Chiffre d'affaire en million dirham Marocain"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>MAD</InputAdornment>
                  )
                }}
                type='number'
                defaultValue={ca2}
                name='ca2'
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        )}
        {secteur_children !== secteursData.offshoring && (
          <Col md={6}>
            <FormGroup>
              <TextField
                id='input_ca3'
                label={
                  filiale === 'oui_filiale'
                    ? i18n.t('form.ca3m')
                    : i18n.t('form.ca3p')
                }
                helperText="Chiffre d'affaire en million dirham Marocain"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>MAD</InputAdornment>
                  )
                }}
                type='number'
                defaultValue={ca3}
                name='ca3'
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
    </div>
  );
}
